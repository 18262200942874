import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";


const Features = () => {
  return (
    <section className="col-12 wow" id="features">
      <div className="features-container">
        <Row className="justify-content-center align-items-center">
          <Col md="6" className="mt-3 main-card">
            <div className="feature-card wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              <div className="feature-header align-items-center">
              </div>
              <div className="feature-description">Our user friendly website and dashboard makes it easy for you to purchase plans, manage plans, and access your proxies.</div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="feature-card wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              <div className="feature-header align-items-center">
                <img
                  alt="..."
                  className="feature-img"
                  src={require("assets/img/Solutions.png")}
                />
                <h5 className="feature-title d-inline-block">Multiple Proxy Solutions</h5>
              </div>
              <div className="feature-description">We provide high quality residential and datacenter solutions that work effectively on all sites, helping you secure the latest releases with ease.</div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="feature-card wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              <div className="feature-header align-items-center">
                <img
                  alt="..."
                  className="feature-img"
                  src={require("assets/img/Reliability.png")}
                />
                <h5 className="feature-title d-inline-block">Reliability</h5>
              </div>
              <div className="feature-description">99.99% uptime. You can always rely on our proxies on any drop no matter what.</div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="feature-card wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              <div className="feature-header align-items-center">
                <img
                  alt="..."
                  className="feature-img"
                  src={require("assets/img/Dedicated.png")}
                />
                <h5 className="feature-title d-inline-block">Dedicated Pools</h5>
              </div>
              <div className="feature-description">We provide large proxy pools that are fully exclusive to our customers only.</div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="feature-card wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              <div className="feature-header align-items-center">
                <img
                  alt="..."
                  className="feature-img"
                  src={require("assets/img/Quality.png")}
                />
                <h5 className="feature-title d-inline-block">Quality</h5>
              </div>
              <div className="feature-description">Our IPs are the best on the market, allowing you to avoid bans on any site to secure your checkouts.</div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="feature-card wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              <div className="feature-header align-items-center">
                <img
                  alt="..."
                  className="feature-img"
                  src={require("assets/img/Customer.png")}
                />
                <h5 className="feature-title d-inline-block">Customer Service</h5>
              </div>
              <div className="feature-description">Our support team is online 24/7, allowing us to meet all your needs and expectations to ensure your experience with us is positive.</div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Features;