import React from 'react';
import { Container, Row } from 'reactstrap';

// core components
import Main from "components/Home/Main";
import Features from "components/Home/Features";
import Pricing from "components/Home/Pricing";
import FAQ from "components/Home/FAQ";
import Socials from "components/Home/Socials";


const Home = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Main />
        <Features />
        <Pricing />
        <FAQ />
        <Socials />
      </Row>
    </Container>
  )
}

export default Home;