import React from "react";
// reactstrap components
import { Row, Col, Button } from "reactstrap";


const Socials = () => {
  return (
    <section className="col-12 wow" id="socials">
      <div className="socials-container">
        <Row className="align-items-center">
          <Col md="6" className="mt-3">
            <h5 className="message wow animate__animated animate__fadeInDown" data-wow-duration="2s">Follow us on social media to stay informed or join our server.</h5>
          </Col>
          <Col md="6" className="mt-3 text-center">
            <Button
              data-wow-duration="2s"
              color="primary"
              className="btn-round btn-socials m-2 wow animate__animated animate__fadeInDown"
              size="md"
              href="https://twitter.com/hollowproxies"
              target="_blank"
            >
              <i className="fab fa-twitter mr-2"></i> Twitter
          </Button>
            <Button
              data-wow-duration="2s"
              color="primary"
              className="btn-round btn-socials m-2 wow animate__animated animate__fadeInDown"
              size="md"
              href="https://twitter.com/hollowproxies"
              target="_blank"
            >
              <i className="fab fa-discord mr-2"></i> Discord
          </Button>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Socials;