import React from 'react';
import { Container, Col } from 'reactstrap';

const AboutUs = () => {
  return (
    <Container>
      <Col>
        <h1>About us Page</h1>
      </Col>
    </Container>
  )
}

export default AboutUs;