const resiPremiumCountries = [
  {
    "country_name": "USA",
    "country_code": "us",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "us"
  }, {
    "country_name": "Canada",
    "country_code": "ca",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "ca"
  }, {
    "country_name": "United Kingdom",
    "country_code": "gb",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "gb"
  }, {
    "country_name": "Germany",
    "country_code": "de",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "de"
  }, {
    "country_name": "France",
    "country_code": "fr",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "fr"
  }, {
    "country_name": "Spain",
    "country_code": "es",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "es"
  }, {
    "country_name": "Italy",
    "country_code": "it",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "it"
  }, {
    "country_name": "Sweden",
    "country_code": "se",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "se"
  }, {
    "country_name": "Greece",
    "country_code": "gr",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "gr"
  }, {
    "country_name": "Portugal",
    "country_code": "pt",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "pt"
  }, {
    "country_name": "Netherlands",
    "country_code": "nl",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "nl"
  }, {
    "country_name": "Belgium",
    "country_code": "be",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "be"
  }, {
    "country_name": "Russia",
    "country_code": "ru",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "ru"
  }, {
    "country_name": "Ukraine",
    "country_code": "ua",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "ua"
  }, {
    "country_name": "Poland",
    "country_code": "pl",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "pl"
  }, {
    "country_name": "Israel",
    "country_code": "il",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "il"
  }, {
    "country_name": "Turkey",
    "country_code": "tr",
    "start_port": 3001,
    "end_port": 3099,
    "domain": "tr"
  }, {
    "country_name": "Australia",
    "country_code": "au",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "au"
  }, {
    "country_name": "Malaysia",
    "country_code": "my",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "my"
  }, {
    "country_name": "Thailand",
    "country_code": "th",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "th"
  }, {
    "country_name": "South Korea",
    "country_code": "kr",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "kr"
  }, {
    "country_name": "Japan",
    "country_code": "jp",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "jp"
  }, {
    "country_name": "Philippines",
    "country_code": "ph",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "ph"
  }, {
    "country_name": "Singapore",
    "country_code": "sg",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "sg"
  }, {
    "country_name": "China",
    "country_code": "cn",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "cn"
  }, {
    "country_name": "Honk Kong ",
    "country_code": "hk",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "hk"
  }, {
    "country_name": "Taiwan",
    "country_code": "tw",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "tw"
  }, {
    "country_name": "India",
    "country_code": "in",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "in"
  }, {
    "country_name": "Pakistan",
    "country_code": "pk",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "pk"
  }, {
    "country_name": "Iran",
    "country_code": "ir",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "ir"
  }, {
    "country_name": "Indonesia",
    "country_code": "id",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "id"
  }, {
    "country_name": "Azerbaijan",
    "country_code": "az",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "az"
  }, {
    "country_name": "Kazakhstan",
    "country_code": "kz",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "kz"
  }, {
    "country_name": "UAE",
    "country_code": "ae",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "ae"
  }, {
    "country_name": "Mexico",
    "country_code": "mx",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "mx"
  }, {
    "country_name": "Brazil",
    "country_code": "br",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "br"
  }, {
    "country_name": "Argentina",
    "country_code": "ar",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "ar"
  }, {
    "country_name": "Chile",
    "country_code": "cl",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "cl"
  }, {
    "country_name": "Peru",
    "country_code": "pe",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "pe"
  }, {
    "country_name": "Ecuador",
    "country_code": "ec",
    "start_port": 20001,
    "end_port": 29999,
    "domain": "ec"
  }, {
    "country_name": "Colombia",
    "country_code": "co",
    "start_port": 30001,
    "end_port": 39999,
    "domain": "co"
  }, {
    "country_name": "South Africa",
    "country_code": "za",
    "start_port": 40001,
    "end_port": 49999,
    "domain": "za"
  }, {
    "country_name": "Egypt",
    "country_code": "eg",
    "start_port": 10001,
    "end_port": 19999,
    "domain": "eg"
  }, {
    "country_name": "Saudi Arabia",
    "country_code": "sa",
    "start_port": 44001,
    "end_port": 44999,
    "domain": "sa"
  }, {
    "country_name": "Denmark",
    "country_code": "dk",
    "start_port": 19001,
    "end_port": 19999,
    "domain": "dk"
  }, {
    "country_name": "Angola",
    "country_code": "ao",
    "start_port": 40001,
    "end_port": 40999,
    "domain": "ao"
  }, {
    "country_name": "Cameroon",
    "country_code": "cm",
    "start_port": 41001,
    "end_port": 41999,
    "domain": "cm"
  }, {
    "country_name": "Central African Republic",
    "country_code": "cf",
    "start_port": 42001,
    "end_port": 42999,
    "domain": "cf"
  }, {
    "country_name": "Chad",
    "country_code": "td",
    "start_port": 43001,
    "end_port": 43999,
    "domain": "td"
  }, {
    "country_name": "Benin",
    "country_code": "bj",
    "start_port": 44001,
    "end_port": 44999,
    "domain": "bj"
  }, {
    "country_name": "Ethiopia",
    "country_code": "et",
    "start_port": 45001,
    "end_port": 45999,
    "domain": "et"
  }, {
    "country_name": "Djibouti",
    "country_code": "dj",
    "start_port": 46001,
    "end_port": 46999,
    "domain": "dj"
  }, {
    "country_name": "Gambia",
    "country_code": "gm",
    "start_port": 47001,
    "end_port": 47999,
    "domain": "gm"
  }, {
    "country_name": "Ghana",
    "country_code": "gh",
    "start_port": 48001,
    "end_port": 48999,
    "domain": "gh"
  }, {
    "country_name": "Côte d'Ivoire",
    "country_code": "ci",
    "start_port": 49001,
    "end_port": 49999,
    "domain": "ci"
  }, {
    "country_name": "Kenya",
    "country_code": "ke",
    "start_port": 10001,
    "end_port": 10999,
    "domain": "ke"
  }, {
    "country_name": "Liberia",
    "country_code": "lr",
    "start_port": 11001,
    "end_port": 11999,
    "domain": "lr"
  }, {
    "country_name": "Madagascar",
    "country_code": "mg",
    "start_port": 12001,
    "end_port": 12999,
    "domain": "mg"
  }, {
    "country_name": "Mali",
    "country_code": "ml",
    "start_port": 13001,
    "end_port": 13999,
    "domain": "ml"
  }, {
    "country_name": "Mauritania",
    "country_code": "mr",
    "start_port": 14001,
    "end_port": 14999,
    "domain": "mr"
  }, {
    "country_name": "Mauritius",
    "country_code": "mu",
    "start_port": 15001,
    "end_port": 15999,
    "domain": "mu"
  }, {
    "country_name": "Morocco",
    "country_code": "ma",
    "start_port": 16001,
    "end_port": 16999,
    "domain": "ma"
  }, {
    "country_name": "Mozambique",
    "country_code": "mz",
    "start_port": 17001,
    "end_port": 17999,
    "domain": "mz"
  }, {
    "country_name": "Nigeria",
    "country_code": "ng",
    "start_port": 18001,
    "end_port": 18999,
    "domain": "ng"
  }, {
    "country_name": "Senegal",
    "country_code": "sn",
    "start_port": 19001,
    "end_port": 19999,
    "domain": "sn"
  }, {
    "country_name": "Seychelles",
    "country_code": "sc",
    "start_port": 20001,
    "end_port": 20999,
    "domain": "sc"
  }, {
    "country_name": "Zimbabwe",
    "country_code": "zw",
    "start_port": 21001,
    "end_port": 21999,
    "domain": "zw"
  }, {
    "country_name": "South Sudan",
    "country_code": "ss",
    "start_port": 22001,
    "end_port": 22999,
    "domain": "ss"
  }, {
    "country_name": "Sudan",
    "country_code": "sd",
    "start_port": 23001,
    "end_port": 23999,
    "domain": "sd"
  }, {
    "country_name": "Togo",
    "country_code": "tg",
    "start_port": 24001,
    "end_port": 24999,
    "domain": "tg"
  }, {
    "country_name": "Tunisia",
    "country_code": "tn",
    "start_port": 25001,
    "end_port": 25999,
    "domain": "tn"
  }, {
    "country_name": "Uganda",
    "country_code": "ug",
    "start_port": 26001,
    "end_port": 26999,
    "domain": "ug"
  }, {
    "country_name": "Zambia",
    "country_code": "zm",
    "start_port": 27001,
    "end_port": 27999,
    "domain": "zm"
  }, {
    "country_name": "Afghanistan",
    "country_code": "af",
    "start_port": 28001,
    "end_port": 28999,
    "domain": "af"
  }, {
    "country_name": "Bahrain",
    "country_code": "bh",
    "start_port": 29001,
    "end_port": 29999,
    "domain": "bh"
  }, {
    "country_name": "Bangladesh",
    "country_code": "bd",
    "start_port": 30001,
    "end_port": 30999,
    "domain": "bd"
  }, {
    "country_name": "Armenia",
    "country_code": "am",
    "start_port": 31001,
    "end_port": 31999,
    "domain": "am"
  }, {
    "country_name": "Bhutan",
    "country_code": "bt",
    "start_port": 32001,
    "end_port": 32999,
    "domain": "bt"
  }, {
    "country_name": "Myanmar",
    "country_code": "mm",
    "start_port": 33001,
    "end_port": 33999,
    "domain": "mm"
  }, {
    "country_name": "Cambodia",
    "country_code": "kh",
    "start_port": 34001,
    "end_port": 34999,
    "domain": "kh"
  }, {
    "country_name": "Georgia",
    "country_code": "ge",
    "start_port": 36001,
    "end_port": 36999,
    "domain": "ge"
  }, {
    "country_name": "Iraq",
    "country_code": "iq",
    "start_port": 37001,
    "end_port": 37999,
    "domain": "iq"
  }, {
    "country_name": "Jordan",
    "country_code": "jo",
    "start_port": 38001,
    "end_port": 38999,
    "domain": "jo"
  }, {
    "country_name": "Lebanon",
    "country_code": "lb",
    "start_port": 39001,
    "end_port": 39999,
    "domain": "lb"
  }, {
    "country_name": "Maldives",
    "country_code": "mv",
    "start_port": 40001,
    "end_port": 40999,
    "domain": "mv"
  }, {
    "country_name": "Mongolia",
    "country_code": "mn",
    "start_port": 41001,
    "end_port": 41999,
    "domain": "mn"
  }, {
    "country_name": "Oman",
    "country_code": "om",
    "start_port": 42001,
    "end_port": 42999,
    "domain": "om"
  }, {
    "country_name": "Qatar",
    "country_code": "qa",
    "start_port": 43001,
    "end_port": 43999,
    "domain": "qa"
  }, {
    "country_name": "Saudi Arabia",
    "country_code": "sa",
    "start_port": 44001,
    "end_port": 44999,
    "domain": "sa"
  }, {
    "country_name": "Vietnam",
    "country_code": "vn",
    "start_port": 45001,
    "end_port": 45999,
    "domain": "vn"
  }, {
    "country_name": "Turkmenistan",
    "country_code": "tm",
    "start_port": 46001,
    "end_port": 46999,
    "domain": "tm"
  }, {
    "country_name": "Uzbekistan",
    "country_code": "uz",
    "start_port": 47001,
    "end_port": 47999,
    "domain": "uz"
  }, {
    "country_name": "Yemen",
    "country_code": "ye",
    "start_port": 48001,
    "end_port": 48999,
    "domain": "ye"
  }, {
    "country_name": "Albania",
    "country_code": "al",
    "start_port": 49001,
    "end_port": 49999,
    "domain": "al"
  }, {
    "country_name": "Andorra",
    "country_code": "ad",
    "start_port": 10001,
    "end_port": 10999,
    "domain": "ad"
  }, {
    "country_name": "Austria",
    "country_code": "at",
    "start_port": 11001,
    "end_port": 11999,
    "domain": "at"
  }, {
    "country_name": "Bosnia and Herzegovina",
    "country_code": "ba",
    "start_port": 13001,
    "end_port": 13999,
    "domain": "ba"
  }, {
    "country_name": "Bulgaria",
    "country_code": "bg",
    "start_port": 14001,
    "end_port": 14999,
    "domain": "bg"
  }, {
    "country_name": "Belarus",
    "country_code": "by",
    "start_port": 15001,
    "end_port": 15999,
    "domain": "by"
  }, {
    "country_name": "Croatia",
    "country_code": "hr",
    "start_port": 16001,
    "end_port": 16999,
    "domain": "hr"
  }, {
    "country_name": "Cyprus",
    "country_code": "cy",
    "start_port": 35001,
    "end_port": 35999,
    "domain": "cy"
  }, {
    "country_name": "Czech Republic",
    "country_code": "cz",
    "start_port": 18001,
    "end_port": 18999,
    "domain": "cz"
  }, {
    "country_name": "Denmark",
    "country_code": "dk",
    "start_port": 19001,
    "end_port": 19999,
    "domain": "dk"
  }, {
    "country_name": "Estonia",
    "country_code": "ee",
    "start_port": 20001,
    "end_port": 20999,
    "domain": "ee"
  }, {
    "country_name": "Finland",
    "country_code": "fi",
    "start_port": 21001,
    "end_port": 21999,
    "domain": "fi"
  }, {
    "country_name": "Hungary",
    "country_code": "hu",
    "start_port": 23001,
    "end_port": 23999,
    "domain": "hu"
  }, {
    "country_name": "Iceland",
    "country_code": "is",
    "start_port": 24001,
    "end_port": 24999,
    "domain": "is"
  }, {
    "country_name": "Ireland",
    "country_code": "ie",
    "start_port": 25001,
    "end_port": 25999,
    "domain": "ie"
  }, {
    "country_name": "Latvia",
    "country_code": "lv",
    "start_port": 26001,
    "end_port": 26999,
    "domain": "lv"
  }, {
    "country_name": "Liechtenstein",
    "country_code": "li",
    "start_port": 27001,
    "end_port": 27999,
    "domain": "li"
  }, {
    "country_name": "Lithuania",
    "country_code": "lt",
    "start_port": 28001,
    "end_port": 28999,
    "domain": "lt"
  }, {
    "country_name": "Luxembourg",
    "country_code": "lu",
    "start_port": 29001,
    "end_port": 29999,
    "domain": "lu"
  }, {
    "country_name": "Malta",
    "country_code": "mt",
    "start_port": 30001,
    "end_port": 30999,
    "domain": "mt"
  }, {
    "country_name": "Monaco",
    "country_code": "mc",
    "start_port": 31001,
    "end_port": 31999,
    "domain": "mc"
  }, {
    "country_name": "Moldova",
    "country_code": "md",
    "start_port": 32001,
    "end_port": 32999,
    "domain": "md"
  }, {
    "country_name": "Montenegro",
    "country_code": "me",
    "start_port": 33001,
    "end_port": 33999,
    "domain": "me"
  }, {
    "country_name": "Norway",
    "country_code": "no",
    "start_port": 34001,
    "end_port": 34999,
    "domain": "no"
  }, {
    "country_name": "Romania",
    "country_code": "ro",
    "start_port": 35001,
    "end_port": 35999,
    "domain": "ro"
  }, {
    "country_name": "Serbia",
    "country_code": "rs",
    "start_port": 36001,
    "end_port": 36999,
    "domain": "rs"
  }, {
    "country_name": "Slovakia",
    "country_code": "sk",
    "start_port": 37001,
    "end_port": 37999,
    "domain": "sk"
  }, {
    "country_name": "Slovenia",
    "country_code": "si",
    "start_port": 38001,
    "end_port": 38999,
    "domain": "si"
  }, {
    "country_name": "Switzerland",
    "country_code": "ch",
    "start_port": 39001,
    "end_port": 39999,
    "domain": "ch"
  }, {
    "country_name": "Macedonia",
    "country_code": "mk",
    "start_port": 40001,
    "end_port": 40999,
    "domain": "mk"
  }, {
    "country_name": "Bahamas",
    "country_code": "bs",
    "start_port": 41001,
    "end_port": 41999,
    "domain": "bs"
  }, {
    "country_name": "Belize",
    "country_code": "bz",
    "start_port": 42001,
    "end_port": 42999,
    "domain": "bz"
  }, {
    "country_name": "British Virgin Islands",
    "country_code": "vg",
    "start_port": 43001,
    "end_port": 43999,
    "domain": "vg"
  }, {
    "country_name": "Costa Rica",
    "country_code": "cr",
    "start_port": 44001,
    "end_port": 44999,
    "domain": "cr"
  }, {
    "country_name": "Cuba",
    "country_code": "cu",
    "start_port": 45001,
    "end_port": 45999,
    "domain": "cu"
  }, {
    "country_name": "Dominica",
    "country_code": "dm",
    "start_port": 46001,
    "end_port": 46999,
    "domain": "dm"
  }, {
    "country_name": "Haiti",
    "country_code": "ht",
    "start_port": 47001,
    "end_port": 47999,
    "domain": "ht"
  }, {
    "country_name": "Honduras",
    "country_code": "hn",
    "start_port": 48001,
    "end_port": 48999,
    "domain": "hn"
  }, {
    "country_name": "Jamaica",
    "country_code": "jm",
    "start_port": 49001,
    "end_port": 49999,
    "domain": "jm"
  }, {
    "country_name": "Aruba",
    "country_code": "aw",
    "start_port": 10001,
    "end_port": 10999,
    "domain": "aw"
  }, {
    "country_name": "Panama",
    "country_code": "pa",
    "start_port": 11001,
    "end_port": 11999,
    "domain": "pa"
  }, {
    "country_name": "Puerto Rico",
    "country_code": "pr",
    "start_port": 12001,
    "end_port": 12999,
    "domain": "pr"
  }, {
    "country_name": "Trinidad and Tobago",
    "country_code": "tt",
    "start_port": 13001,
    "end_port": 13999,
    "domain": "tt"
  }, {
    "country_name": "Fiji",
    "country_code": "fj",
    "start_port": 14001,
    "end_port": 14999,
    "domain": "fj"
  }, {
    "country_name": "New Zealand",
    "country_code": "nz",
    "start_port": 15001,
    "end_port": 15999,
    "domain": "nz"
  }, {
    "country_name": "Bolivia",
    "country_code": "bo",
    "start_port": 16001,
    "end_port": 16999,
    "domain": "bo"
  }, {
    "country_name": "Paraguay",
    "country_code": "py",
    "start_port": 17001,
    "end_port": 17999,
    "domain": "py"
  }, {
    "country_name": "Uruguay",
    "country_code": "uy",
    "start_port": 18001,
    "end_port": 18999,
    "domain": "uy"
  }, {
    "country_name": "Venezuela",
    "country_code": "ve",
    "start_port": 19001,
    "end_port": 19999,
    "domain": "ve"
  }
];

export {
  resiPremiumCountries
};