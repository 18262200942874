import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Table,
  Tooltip
} from 'reactstrap';
import * as request from 'request';
import Select from 'react-select';
import { FirebaseContext } from 'contexts/Firebase';
import {
  resiPremiumASN,
  resiPremiumCities,
  resiPremiumCountries,
  resiPremiumSites
} from 'Services/ResidentialPremium';
import {
  PageLoader,
  CheckoutModal,
  showToastAlert,
  DoughnutChart,
  getDate,
  validateIPv4,
  generateRandomString,
  copyData,
  downloadFile
} from 'Services/Utility';
import Sidebar from 'components/Dashboard/Sidebar';
import Header from 'components/Dashboard/Header'

import $ from 'jquery'; 


const Premier = () => {
  const { user, firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const [dcPlans, setDCPlans] = useState([]);
  const [userDCPlans, setUserDCPlans] = useState([]);
  const [dcAuthTokenList, setDCAuthTokenList] = useState([]);
  const [dcAuthToken, setDCAuthToken] = useState({ value: "", label: "AuthToken" });
  const [dcProxies, setDCProxies] = useState([]);
  const [dcSrcIPList, setDCSrcIPList] = useState([]);
  const [dcSrcIP, setDCSrcIP] = useState({ value: "", label: "Auth IPs" });
  const [dcIP, setDCIP] = useState('');
  const [authType, setAuthType] = useState('user');
  const [activePlan, setActivePlan] = useState({});

  const [dcPlanExpiry, setDCExpiry] = useState('N/A');
  const [isDCPlansActive, setDCPlansActive] = useState(null);
  const [isDCRenewActive, setDCRenewActive] = useState(null);

  // const [resiPlans, setResiPlans] = useState([]);
  // const [isResiPlansActive, setResiPlansActive] = useState(null);
  const [userResiPlans, setUserResiPlans] = useState([]);
  const [resiLocations, setResiLocations] = useState([]);
  const [resiContinentList, setResiContinentList] = useState([]);
  const [resiCountryList, setResiCountryList] = useState([]);
  const [resiContinent, setResiContinent] = useState({ value: "", label: "Continent" });
  const [resiCountry, setResiCountry] = useState({ value: "", label: "Country" });

  // const [resiPremPlans, setResiPremPlans] = useState([]);
  // const [isResiPremPlansActive, setResiPremPlansActive] = useState(null);
  const [userResiPremPlans, setUserResiPremPlans] = useState([]);
  const [resiPremCountryList, setResiPremCountryList] = useState([]);
  const [resiPremCityList, setResiPremCityList] = useState([]);
  const [resiPremASNList, setResiPremASNList] = useState([]);
  const [resiPremCountry, setResiPremCountry] = useState({ value: "", label: "Country" });
  const [resiPremCity, setResiPremCity] = useState({ value: "", label: "City" });
  const [resiPremASN, setResiPremASN] = useState({ value: "", label: "ASN" });
  const [resiPremSite, setResiPremSite] = useState({ value: "", label: "Site" });
  const [resiPremSiteList] = useState(resiPremiumSites.map(site => {
    return { label: site.name, value: site.name }
  }));
  const [isResiPremSiteSelected, setResiPremSiteSelected] = useState(false);

  const [resiProxyType, setResiProxyType] = useState({ value: "sticky", label: "Sticky" });
  const [nbrOfProxies, setNbrOfProxies] = useState(255);
  const [resiPlanTypeList] = useState([
    { value: "HollowPremier", label: "HollowPremier" },
    { value: "HollowPremium", label: "HollowPremium" }
  ]);
  const [resiProxyTypeList] = useState([
    { value: "sticky", label: "Sticky" },
    { value: "rotating", label: "Rotating" }
  ]);
  const [resiPlanType, setResiPlanType] = useState({ value: "HollowPremium", label: "HollowPremier" });
  const [resiExpiry, setResiExpiry] = useState('N/A');
  const [resiDataUsage, setResiDataUsage] = useState('0.00 / 0.00 GB');
  const [resiProxyAuth, setResiProxyAuth] = useState('username:password');
  const [resiProxies, setResiProxies] = useState([]);
  const [tooltips, setTooltips] = useState({
    'dropGuide': false, 'support': false, 'resiFAQ': false, 'dcFAQ': false
  });
  const [dataUsageCharts, setDataUsageCharts] = useState([<DoughnutChart key={Math.random()} />]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [checkoutModals, setCheckoutModals] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDCPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/datacenter/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const getResiPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/premier/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching HollowPremier plan!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const updateDCPlan = (authToken) => {
    console.log(authToken);
    updateDCPlansMetaData(dcPlans, isDCPlansActive, userDCPlans, dcAuthTokenList, authToken);
  }

  const updateResiPlan = (authToken) => {
    console.log(authToken);
    updateDCPlansMetaData(dcPlans, isDCPlansActive, userDCPlans, dcAuthTokenList, authToken);
  }


  const requestAuthChange = async (authToken, newType) =>{
    let idToken = await firebase.auth.currentUser.getIdToken(true);

    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/datacenter/changeAuth`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken: idToken,
          authtoken: authToken,
          authtype: newType
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          setAuthType(newType);
          setLoading(false);

          showToastAlert('Success', 'Authtype Changed successfully');
          window.location.reload();

          return resolve(resData);
        }
      });
    });
  }

  const changeAuthType = async (newType) => {
    setLoading(true);

    if(activePlan.authtype == newType){
      return;
    }else{
      
      await requestAuthChange(activePlan.authToken, newType);
    }
    
  }

  const getResiPremPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential_premium/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching HollowPremium plan!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const refreshResiPremProxyAuth = async () => {
    let idToken = await firebase.auth.currentUser.getIdToken(true);
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential_premium/refreshProxyAuth`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while refreshing proxy auth!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          showToastAlert('Success', 'HollowPremium Proxy Auth is successfully refreshed');
          return resolve(resData);
        }
      });
    });
  }

  const onChangeResiPlanType = (event) => {
    setResiPlanType({ value: event.value, label: event.label });
    setResiProxies([]);
    if (event.value === 'HollowPremier') {
      updateResiMetaData(userResiPlans);
    }
    if (event.value === 'HollowPremium') {
      updateResiPremMetaData(userResiPremPlans);
    }
  }

  const updateDCPlansMetaData = (plans, isPlansActive, userPlans, planOptions, authToken) => {
    console.log(plans);
    console.log(userPlans);
    console.log(planOptions);
    console.log(authToken);
    const value = planOptions.filter(option => option.value === authToken)[0];

    if (!value) {
      let planDetails = orderHistory.find((obj) => {return obj.authToken === authToken})
      console.log(planDetails);
      planDetails['status'] = "Expired";
      planDetails['timeRemainingPercentage'] = 0;
      planDetails['authtype'] = 'ip';
      planDetails['srcips'] = [];
      planDetails['proxies'] = [];
      setActivePlan(planDetails);
      setDataUsageCharts([
        <DoughnutChart key={Math.random()} text="0 Hours" dataset={[100,0]} />
      ]);
      setLoading(false);

      return;
    }
    setDCAuthToken(value);
    const dcPlan = userPlans.filter(plan => plan.authToken === authToken)[0];
    const planData = dcPlan.planData;
    console.log(planData);
    setAuthType(planData.authtype);
    var dcProxies;
    if(planData.authtype === 'ip'){

      dcProxies = planData.proxies.join('\r\n');
    }else{
      dcProxies = planData.proxies.join(`:${planData.username}:${planData.password}\r\n`);
      dcProxies = dcProxies + `:${planData.username}:${planData.password}`;
    }
    const dcSrcIPList = planData.srcips.map(ip => {
      return { value: ip, label: ip }
    });
    // const dcPlanExpiry = getDate(new Date(parseInt(planData.starttime) * 1000 + parseInt(planData.expiryhours) * 3600000));

    let isDCRenewActive = false;
    let plan = plans.filter(p => p.planID === dcPlan.planID)[0];

    if (dcPlan.subPlanID && plan['subPlans'][dcPlan.subPlanID]) {
      isDCRenewActive = plan['subPlans'][dcPlan.subPlanID].isRenewable;
    }
    // setDCProxies(dcProxies);
    setDCSrcIPList(dcSrcIPList);
    // setDCSrcIP(dcSrcIPList[0]);
    // setDCExpiry(dcPlanExpiry);
    setDCRenewActive(isDCRenewActive);
    let planDetails = orderHistory.find((obj) => {return obj.authToken === authToken})
    console.log(planDetails);
    planDetails['status'] = planData.status;
    planDetails['timeRemainingPercentage'] = 0;
    planDetails['authtype'] = planData.authtype;
    planDetails['srcips'] = planData.srcips;
    planDetails['proxies'] = dcProxies;
    setActivePlan(planDetails);
    let expiryhours = parseInt(planData.expiryhours);
    let usedHours = parseInt((new Date().getTime()/1000 - parseInt(planData.starttime))/3600);
    let remainingHours = expiryhours - usedHours;
    let usedPercentage = parseInt(100*usedHours/expiryhours);
    setDataUsageCharts([
      <DoughnutChart key={Math.random()} text={remainingHours + " Hours"} dataset={[100-usedPercentage,usedPercentage]} />
    ]);
    setLoading(false);


    return;

  }

  const onChangeDCAuthToken = (authToken) => {
    updateDCPlansMetaData(dcPlans, isDCPlansActive, userDCPlans, dcAuthTokenList, authToken);
  }

  const renewDCPlan = async () => {
    setCheckoutModals([]);
    let discordId = await firebase.db.ref(`users/${firebase.auth.currentUser.uid}/discordId`).once('value');
    discordId = discordId.val();
    if (!discordId) {
      showToastAlert('Error', 'Please join discord server to purchase a plan');
      return;
    }

    let authToken = activePlan.authToken;
    if (!authToken) {
      return;
    }
    console.log("Renewing ", authToken);
    let proxyData = userDCPlans.filter(plan => plan.authToken === authToken)[0];
    if (!proxyData || !proxyData.subPlanID) {
      showToastAlert('Error', 'Your plan is expired!');

      console.log(proxyData);
      return;
    }

    let showModal = true;
    let checkoutPlanID = proxyData.planID;
    let checkoutsubPlanID = proxyData.subPlanID;
    let checkoutPlanType = 'datacenter';
    let updatePlanRefID = proxyData.updatePlanRefID;

    let planData = dcPlans.filter(plan => plan.planID === checkoutPlanID)[0];
    let checkoutPlanPrice = planData['subPlans'][checkoutsubPlanID]['price'];
    let planPrice = `Plan Price $${checkoutPlanPrice}`;
    setCheckoutModals([
      <CheckoutModal
        showModal={showModal}
        checkoutPlanID={checkoutPlanID}
        checkoutsubPlanID={checkoutsubPlanID}
        updatePlanRefID={updatePlanRefID}
        checkoutPlanType={checkoutPlanType}
        checkoutPlanPrice={checkoutPlanPrice}
        planPrice={planPrice}
        key={Math.random()}
        firebase={firebase}
      />
    ]);
  }

  const addSrcIPs = (addIP) => {
    if (validateIPv4(addIP)) {
      let srcips = dcSrcIPList.map(ip => ip.value);
      srcips.push(addIP);
      handleSrcIPs(srcips);
    }
  }

  const removeSrcIP = (removeIP) => {
    console.log("Removing IP", removeIP);
    console.log(dcSrcIPList);

    let srcips = dcSrcIPList.map(ip => ip.value).filter(ip => ip !== removeIP);

    console.log(srcips);
    handleSrcIPs(srcips);
  }

  const handleSrcIPs = async (srcips) => {
    srcips = Array.from(new Set(srcips));
    if (srcips.length && srcips.every(validateIPv4)) {
      setLoading(true);
      let idToken = await firebase.auth.currentUser.getIdToken(true);
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/datacenter/changeIP`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken,
          srcips,
          authtoken: dcAuthToken.value,
        })
      };

      request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'Error occurred while updating Authenticated IPs';
          setLoading(false);
          showToastAlert('Error', errorMessage);
        } else {
          if (resData.status === 'success' && Object.keys(resData.data).length) {
            const dcPlans = userDCPlans.map(plan => {
              if (plan.authToken === dcAuthToken.value) {
                plan.planData = resData.data;
              }
              return plan;
            });
            setUserDCPlans(dcPlans);

            const dcSrcIPList = resData.data.srcips.map(ip => {
              return { value: ip, label: ip }
            });
            setDCSrcIPList(dcSrcIPList);
            setDCSrcIP(dcSrcIPList[0]);
            setDCIP('');
            setLoading(false);
            showToastAlert('Success', 'Authenticated IPs updated successfully');
            updateDCPlan(dcAuthToken.value);
          } else {
            setLoading(false);
          }
        }
      });
    }
  };

  const updateResiMetaData = (userPlans) => {
    let resiDataUsage = '0.00/0.00 GB', resiExpiry = 'N/A', resiProxyAuth = 'username:password', chartText = '0/0 GB',
      key = Math.random(), chartDataSet = [100, 0];

    if (userPlans.length) {
      let plan = userPlans[0];
      let planData = plan.planData;
      let dataUsedGB = parseFloat((parseFloat(planData.dataUsage) / 1000000000).toFixed(2));
      let dataLimitGB = parseFloat((parseInt(planData.dataLimitMegaBytes) / 1000).toFixed(2));
      let dataRemainGB = parseFloat((dataLimitGB - dataUsedGB).toFixed(2));
      resiDataUsage = `${dataUsedGB.toFixed(2)} / ${dataLimitGB.toFixed(2)} GB`;
      resiExpiry = getDate(new Date(new Date(planData.createDate).getTime() + parseInt(planData.validityHours) * 3600000));
      resiProxyAuth = `${planData.name}:${planData.pass}`;
      chartText = `${(dataUsedGB).toFixed(2)} / ${dataLimitGB.toFixed(2)} GB`;
      chartDataSet = [ dataUsedGB, dataRemainGB];
      key = planData.name;
      console.log(planData);
      let planDetails = { authToken : planData.name , planName : 'Hollow Premier'};
      planDetails['status'] = planData.status;
      planDetails['timeRemainingPercentage'] = 0;
      planDetails['expiry'] = resiExpiry;
      setActivePlan(planDetails);

    }
    setDataUsageCharts([
      <DoughnutChart key={key} text={chartText} dataset={chartDataSet} />
    ]);
    setResiDataUsage(resiDataUsage);
    setResiExpiry(resiExpiry);
    setResiProxyAuth(resiProxyAuth);
    setLoading(false);
  }

  const updateResiPremMetaData = (userPlans) => {
    let resiDataUsage = '0.00/0.00 GB', resiExpiry = 'N/A', resiProxyAuth = 'username:password', chartText = '0/0',
      key = Math.random(), chartDataSet = [0, 100];

    if (userPlans.length) {
      let plan = userPlans[0];
      let planData = plan.planData;
      console.log(planData);
      let dataUsedGB = parseFloat(planData.traffic);
      let dataLimitGB = parseFloat(planData.traffic_limit);
      let dataRemainGB = parseFloat((dataLimitGB - dataUsedGB).toFixed(2));
      resiDataUsage = `${(dataLimitGB - dataUsedGB).toFixed(2)} / ${dataLimitGB.toFixed(2)} GB`;
      resiExpiry = 'N/A';
      resiProxyAuth = `${planData.username}:${plan.password}`;
      chartText = resiDataUsage;
      chartDataSet = [dataUsedGB, dataRemainGB];
      key = planData.username;

      let planDetails = { authToken : planData.username , planName : 'Hollow Premium'};
      planDetails['status'] = planData.status;
      planDetails['timeRemainingPercentage'] = 0;
      planDetails['expiry'] = resiExpiry;
      setActivePlan(planDetails);

    }
    setDataUsageCharts([
      <DoughnutChart key={key} text={chartText} dataset={chartDataSet} />
    ]);
    setResiDataUsage(resiDataUsage);
    setResiExpiry(resiExpiry);
    setResiProxyAuth(resiProxyAuth);
  }

  const onChangeResiContinent = (event) => {
    let countryList = [];
    let countries = resiLocations.filter(location => location['continent'] === event.value);
    countries.forEach((country) => {
      countryList.push({ value: country['name'], label: country['name'] });
    });
    setResiCountryList(countryList);
    setResiCountry(countryList[0]);
    setResiContinent({ value: event.value, label: event.label });
  }

  const onChangeResiCountry = (event) => {
    setResiCountry({ value: event.value, label: event.label });
  }

  const onChangeResiProxyType = (event) => {
    setResiProxyType({ value: event.value, label: event.label });
  }

  const onChangeResiPremCountry = (event) => {
    setResiPremSiteSelected(false);
    let cityList = [];
    resiPremiumCities.filter(city => city.Country.toLowerCase() === event.value).forEach((city) => {
      cityList.push({ value: city.City, label: city.City });
    });
    cityList.unshift({ value: "", label: "City" });
    setResiPremCityList(cityList);
    setResiPremCity(cityList[0]);

    let asnList = [];
    resiPremiumASN.filter(asn => asn.Country.toLowerCase() === event.value).forEach((asn) => {
      asnList.push({ value: asn.ASN, label: `${asn.Carrier} - ${asn.ASN}` });
    });
    asnList.unshift({ value: "", label: "ASN" });
    setResiPremASNList(asnList);
    setResiPremASN(asnList[0]);

    setResiPremCountry({ value: event.value, label: event.label });
  }

  const onChangeResiPremCity = (event) => {
    setResiPremCity({ value: event.value, label: event.label });
  }

  const onChangeResiPremASN = (event) => {
    setResiPremASN({ value: event.value, label: event.label });
  }

  const onChangeResiPremSite = (event) => {
    setResiPremSiteSelected(true);
    setResiPremSite({ value: event.value, label: event.label });
    let site = resiPremiumSites.filter(site => site.name === event.value)[0];
    let country = site.country;
    let domains = site.domains;
    let asn = site.ASN;
    let proxies = [];
    let generatedProxies = 0;
    let isBackConnect = site.isBackConnect?true: false;
    for (let i = 0; i < country.length; i++) {
      let noOfPrxoies = i === country.length - 1 ? nbrOfProxies - generatedProxies : Math.ceil(nbrOfProxies / country.length);
      let domainProxies = 0;
      for (let j = 0; j < domains.length; j++) {
        let selectedASN, isASN = false;
        if(asn){
          isASN = true;
          selectedASN = asn[Math.floor(Math.random() * asn.length)];   
        }
        let nProxies = j === domains.length - 1 ? noOfPrxoies - domainProxies : Math.ceil(noOfPrxoies / domains.length);
        let tempProxies = generateResiPremProxies(userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nProxies, country[i], 'site', domains[j], isASN, selectedASN, isBackConnect);
        proxies.push(...tempProxies);
        generatedProxies += nProxies;
        domainProxies += nProxies;
      }
    }
    // shuffle generated proxies
    for (let i = proxies.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [proxies[i], proxies[rand]] = [proxies[rand], proxies[i]];
    }
    proxies = proxies.slice(0,nbrOfProxies);
    setResiProxies(proxies.join('\r\n'));
  }

  const onChangenbrOfProxy = (event) => {
    let nbrOfProxies = parseInt(event.target.value);
    if (nbrOfProxies) {
      nbrOfProxies = Math.min(nbrOfProxies, 5000);
      setNbrOfProxies(nbrOfProxies);
    }
  }

  const generateQuickProxy = (country) => {
    if (resiPlanType.value === 'HollowPremier') {
      if (country === 'US') {
        country = 'United States';
      } else if (country === 'GB') {
        country = 'United Kingdom';
      } else if (country === 'CA') {
        country = 'Canada';
      } else if (country === 'DE') {
        country = 'Germany';
      }
      let proxies = generateResiProxies(userResiPlans, resiLocations, resiCountry, resiProxyType, nbrOfProxies, country, 'sticky');
      setResiProxies(proxies.join('\r\n'));
    }
    if (resiPlanType.value === 'HollowPremium') {
      if (country === 'US') {
        country = 'us';
      } else if (country === 'GB') {
        country = 'gb';
      } else if (country === 'CA') {
        country = 'ca';
      } else if (country === 'DE') {
        country = 'de';
      }
      let proxies = generateResiPremProxies(userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies, country, 'sticky');
      setResiProxies(proxies.join('\r\n'));
    }
  }

  const generateResiProxies = (userResiPlans, resiLocations, resiCountry, resiProxyType, nbrOfProxies, selectedCountry = '', selectedProxyType = '') => {
    try {
      setLoading(true);
      let country = selectedCountry || resiCountry.value;
      let proxyType = selectedProxyType || resiProxyType.value;
      let nbrOfProxy = nbrOfProxies || 255;
      let location = resiLocations.filter(loc => loc.name === country)[0];
      if (!proxyType || !nbrOfProxy || !location) {
        throw new Error('Invalid proxy configurations !');
      }

      let domain = location.domain;
      let portStart = location.portStart;
      let portEnd = location.portEnd;

      let proxyData = userResiPlans[0];
      if (!proxyData || !proxyData.planData) {
        throw new Error('Invalid proxy authToken !');
      }
      let name = proxyData.planData.name;
      let pass = proxyData.planData.pass;
      let proxies = [];

      if (proxyType === 'sticky') {
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let randomStr = generateRandomString(10);
          let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
          let proxy = `${domain}:${port}:${name}:${pass}-${randomStr}`;
          proxies.push(proxy);
        }
      } else if (proxyType === 'rotating') {
        let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
        let proxy = `${domain}:${port}:${name}:${pass}`;
        proxies.push(proxy);
      }
      setLoading(false);
      return proxies;
    } catch (error) {
      showToastAlert('Error', error.message);
      setLoading(false);
      return [];
    }
  };

  const generateResiPremProxies = (userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies, selectedCountry = '', selectedProxyType = '', domain = '', isASN = false, selectedASN = '', isBackConnect = false) => {
    try {
      setLoading(true);
      let country_code = selectedCountry || resiPremCountry.value;
      let proxyType = selectedProxyType || resiProxyType.value;
      let nbrOfProxy = nbrOfProxies || 255;
      let location = resiPremiumCountries.filter(country => country.country_code === country_code)[0];
      if (!proxyType || !nbrOfProxy || !location) {
        throw new Error('Invalid proxy configurations !');
      }

      let isp, city;
      if (!selectedCountry) {
        isp = resiPremASN.value;
        city = resiPremCity.value;
      }
      country_code = country_code.toUpperCase();

      let proxyData = userResiPremPlans[0];
      if (!proxyData || !proxyData.planData) {
        throw new Error('Invalid proxy username !');
      }
      let name = proxyData.planData.username;
      let pass = proxyData.password;
      let ispStr = isp ? `-asn-${isp}` : '';
      let cityStr = city ? `-city-${city}` : '';
      let proxies = [];

      if (proxyType === 'sticky') {
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let random = generateRandomString(8);
          let proxy = `hl.hollowpremium.com:7777:customer-${name}-cc-${country_code}${cityStr}${ispStr}-sessid-${random}-sesstime-13:${pass}`;
          proxies.push(proxy);
        }
      } else if (proxyType === 'rotating') {
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let proxy = `hl.hollowpremium.com:7777:customer-${name}-cc-${country_code}${cityStr}${ispStr}:${pass}`;
          proxies.push(proxy);
        }
      } else if (proxyType === 'site') {
        let portStart = location.start_port;
        let portEnd = location.end_port;
        let ispStr = isASN ? `-asn-${selectedASN}` : '';
        
        for (let i = 1; i <= parseInt(nbrOfProxy); i++) {
          let port = Math.floor(Math.random() * (portEnd - portStart + 1) + portStart);
          let proxy = '';
          if(isBackConnect){
            domain = 'hl.hollowpremium.com';
            let random = generateRandomString(8);
            port = 7777;
            proxy = `${domain}:${port}:customer-${name}-cc-${country_code}-sessid-${random}-sesstime-13${ispStr}:${pass}`;
          }else{
            proxy = `${domain}:${port}:customer-${name}-cc-${country_code}${ispStr}:${pass}`;
          }
          proxies.push(proxy);
        }
      }
      setLoading(false);
      return proxies;
    } catch (error) {
      showToastAlert('Error', error.message);
      setLoading(false);
      return [];
    }
  }

  const copyProxies = (type) => {
    let proxies = '';
    if (type === 'DC') {
      proxies = document.getElementById('dcProxyList').value;
    }
    if (type === 'RESI') {
      proxies = document.getElementById('resiProxyList').value;
    }
    copyData(proxies);
  }

  const downloadProxies = (type) => {
    let proxies = '', fileName = '';
    if (type === 'DC') {
      fileName = `HollowISP_${dcAuthToken.value}.txt`;
      proxies = document.getElementById('dcProxyList').value;
    }
    if (type === 'RESI') {
      fileName = resiPlanType.value === 'HollowPremier' ? `HollowPremier.txt` : `HollowPremium.txt`;
      proxies = document.getElementById('resiProxyList').value;
    }
    downloadFile(fileName, proxies);
  }

  const toggleTooltip = (type) => {
    let toolstipsCopy = { ...tooltips };
    toolstipsCopy[type] = !(toolstipsCopy[type]);
    setTooltips(toolstipsCopy);
  }

  useEffect(() => {
    if (userResiPlans.length && resiLocations.length && resiContinentList.length && resiCountryList.length && resiContinent.value && resiCountry.value && resiProxyType.value && nbrOfProxies) {
        let proxies = generateResiProxies(userResiPlans, resiLocations, resiCountry, resiProxyType, nbrOfProxies);
        setResiProxies(proxies.join('\r\n'));
    }
  }, [resiPlanType, userResiPlans, resiLocations, resiContinentList, resiCountryList, resiContinent, resiCountry, resiProxyType, nbrOfProxies]);

  useEffect(() => {
    console.log("Received data", orderHistory);
    if(orderHistory.length > 0){
      // updateResiPremMetaData(userResiPremPlans);
      updateResiMetaData(userResiPlans);
      console.log("Setting up : ", orderHistory[0]);
      // updateDCPlan(orderHistory[0].authToken);
    }
}, [orderHistory]);

  // useEffect(() => {
  //   if (userResiPremPlans.length && resiPremCountryList.length && resiPremCityList.length && resiPremASNList.length && resiPremCountry.value && resiProxyType.value && nbrOfProxies) {
  //     console.log("Generating Proxies");
  //     if (resiPlanType.value === 'HollowPremium' && !isResiPremSiteSelected) {
  //       let proxies = generateResiPremProxies(userResiPremPlans, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies);
  //       setResiProxies(proxies.join('\r\n'));
  //     }else{
  //       console.log("Not generating proxies1");
  //     }
  //   }else{
  //     console.log("Not generating proxies");
  //   }
  // }, [resiPlanType, userResiPremPlans, resiPremCountryList, resiPremCityList, resiPremASNList, resiPremCountry, resiPremCity, resiPremASN, resiProxyType, nbrOfProxies, isResiPremSiteSelected]);

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (!user) {
            history.push('/login');
            return;
          }
          firebase.db.ref('users/' + user.uid).once('value',(snapshot, error)=>{
            console.log(snapshot.val());
            console.log(error);
          });
          let idToken = await firebase.auth.currentUser.getIdToken(true);
          let plans = await Promise.all([ getResiPlans(idToken)]);
          console.log(plans);
          if (plans.every(i => i === null)) {
            setLoading(false);
          }
          let orderHistoryList = [];

      
          if (plans[0] && plans[0].status === 'success') {
            let resiData = plans[0];
            if (Object.keys(resiData.data).length) {
              // setResiPlansActive(resiData.isActivePlan);
              // const resiPlanList = Object.keys(resiData.plans).map(key => ({
              //   ...resiData.plans[key],
              //   planID: key,
              // }));
              // setResiPlans(resiPlanList);

              const userResiPlans = Object.keys(resiData.data).map(key => ({
                ...resiData.data[key],
                authToken: key,
              }));
              setUserResiPlans(userResiPlans);

              setResiLocations(resiData.countries);
              let continentList = [];
              resiData.countries.forEach((location) => {
                continentList.push({ value: location['continent'], label: location['continent'] });
              });
              continentList = continentList.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
              let continent = continentList[0] || { value: "", label: "Continent" };
              setResiContinentList(continentList);
              setResiContinent(continent);

              let countryList = [];
              let countries = resiData.countries.filter(country => country['continent'] === continent['value']);
              countries.forEach((country) => {
                countryList.push({ value: country['name'], label: country['name'] });
              });
              let country = countryList[0] || { value: "", label: "Country" };
              setResiCountryList(countryList);
              setResiCountry(country);

              updateResiMetaData(userResiPlans);
            } else {
              updateResiMetaData([]);
            }
            orderHistoryList = [...orderHistoryList, ...resiData.orderHistory];
          }

          orderHistoryList.sort((obj1, obj2) => (obj1.createdAt - obj2.createdAt) * -1);
          setOrderHistory(orderHistoryList);
          setLoading(false);

        } catch (error) {
          showToastAlert('Error', error.message);
          setLoading(false);
        }
      });
    })();
  }, [history, firebase.auth]);

  return (
    <>
          {loading && <PageLoader />}

      <div className="d-flex" id="wrapper">
        <Sidebar planType='premier'></Sidebar>

        <div id="page-content-wrapper">
  
  <nav className=" d-md-none navbar navbar-expand-lg border-bottom">

    <button id="menu-toggle" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <i className="fas fa-bars text-light"></i>
    </button>

  </nav>
  
  <div className="container-fluid second-part mt-3">
    <div className="row">
      <Header name={(user && user.displayName) || 'User'}></Header>
      
      <div className="col-lg-6">
        <div className="dash-bg h-100">
          <div className="plan-usage">
            <div className="plan-left text-center mb-3">
                
            {dataUsageCharts.map(dataChart => (
                          dataChart
                        ))}
              
            <div className="down-bg-part">
              <a className="dash-btn d-block mt-4" href='/#pricing' >Top Up</a>              
            </div>
            </div>
            <div className="plan-right">
              <div className="four-box-part">
                <div className="box-grey">
                  <img src={require("assets/img/box1.svg")} alt=""/>
                  <div className="profile-details">
                    <div className="welcome">Level</div>
                    <div className="p-name">VIP</div>
                  </div>
                </div>
                <div className="box-grey">
                  <img src={require("assets/img/box2.svg")} alt=""/>
                  <div className="profile-details">
                    <div className="welcome">Status</div>
                    <div className="p-name">{activePlan.status? activePlan.status.toUpperCase() : 'Expired'.toUpperCase()}</div>
                  </div>
                </div>
                <div className="box-grey">
                  <img src={require("assets/img/box3.svg")} alt=""/>
                  <div className="profile-details">
                    <div className="welcome">Order</div>
                    <div className="p-name">{activePlan.authToken}</div>
                  </div>
                </div>
                <div className="box-grey">
                  <img src={require("assets/img/home-blue.svg")} alt=""/>
                  <div className="profile-details">
                    <div className="welcome">Expiry Date</div>
                    <div className="p-name">{activePlan.expiry}</div>
                  </div>
                </div>
              </div>

              <div className="down-bg-part mt-4 pt-4">
                <div className="profile-details">
                  <div className="welcome">Selected Plan</div>
                  <div className="p-name mt-2">{activePlan.planName}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
      <div className="dash-bg h-100">
                <div className="new-gap">
                 
                    <h2>Generate Proxies </h2>
                   
                    
                      <div className="form-row pt-3 dashboard-card ">

                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">Country</label>
                          <Select
                              className="react-select react-select-secondary select-dashboard"
                              classNamePrefix="react-select"
                              name="resiContinent"
                              id="resiContinent"
                              value={resiContinent}
                              options={resiContinentList}
                              onChange={onChangeResiContinent}
                            />
                            
                         
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">Pool</label>
                          <Select
                              className="react-select react-select-secondary select-dashboard"
                              classNamePrefix="react-select"
                              name="resiCountry"
                              id="resiCountry"
                              value={resiCountry}
                              options={resiCountryList}
                              onChange={onChangeResiCountry}
                            />
                         
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">Proxy Amount</label>
                          <div className="d-flex">
                          <Input
                          min="1"
                          max="5000"
                          type="number"
                          placeholder="Quantity..."
                          name="nbrOfProxies"
                          className="input-dashboard w-100"
                          value={nbrOfProxies}
                          onChange={onChangenbrOfProxy}
                        />
                          
                        </div>
                        </div>
                        <div className="form-group col-md-6">
                          
                        <label htmlFor="inputEmail4">Type</label>
                          <Select
                          className="react-select react-select-secondary select-dashboard"
                          classNamePrefix="react-select"
                          name="resiProxyType"
                          id="resiProxyType"
                          value={resiProxyType}
                          options={resiProxyTypeList}
                          onChange={onChangeResiProxyType}
                        />

                        </div>
                        
                      </div>
                
                </div>
              </div>
              </div>

      <div className="col-lg-6">
        <div className="dash-bg h-100">
          <h2>Past Orders</h2>
          <div className="table-container orderHistory-table-container">
                          <Table responsive borderless size="xs" id="orderHistoryTable">
                <thead>
                <tr>
                  <th>token</th>
                  <th>Plan</th>
                  <th>Start Date</th>
                  <th>Cost</th>
                  
                </tr>
                </thead>
                <tbody>
                {orderHistory.map((order, index) => {
                  if(order.planType != 'premier'){
                    return;
                  }
                  return (
                    <tr key={order.createdAt} data-token={order.authToken} onClick={()=>{updateDCPlan(order.authToken)}} >
                      <td>{order.authToken || order.username}</td>
                      <td>{order.planName}</td>
                      <td>{getDate(new Date(order.createdAt * 1000))}</td>
                      <td>{order.planPrice - order.couponCodeDiscount?order.couponCodeDiscount:0}</td>

                    </tr>
                  )
                })}
                </tbody>               
             
              </Table>
            </div>
          </div>
      </div>
      <div className="col-lg-6">
        <div className="dash-bg h-100">
          <div className="new-gap">
            <div className="plan-right">
              <h2>Proxy List </h2>
              <Input type="textarea" id="dcProxyList" className="ip-details proxy-number" value={resiProxies?resiProxies:''} />
              
                             
              <div className="botttom-btn d-flex">
                <div className="down-bg-part mt-4 pt-1 w-100 mr-2" onClick={()=>{copyProxies('DC')}}>
                  <span  className="dash-btn d-block copy-btn"><img src={require("assets/img/copy.svg")} className="pr-1" alt=""/> Copy List</span>
                </div>
                <div className="down-bg-part mt-4 pt-1 w-100" onClick={()=>{downloadProxies('DC')}}>
                  <span className="dash-btn d-block"><img src={require("assets/img/download.svg")} className="pr-1" alt=""/> Download List</span>
                </div>
              </div>
               
            </div>
           
          
          </div>
        </div>
      </div>
    </div>
   


  </div>
</div>
{checkoutModals.map(checkoutModal => (
          checkoutModal
        ))}

      </div>
     




    </>
  )
}

export default Premier;