import React, { useContext, useState, useEffect, useCallback } from 'react';
// reactstrap components
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  ButtonGroup,
  InputGroup,
  Input,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import * as request from 'request';
import ReactHtmlParser from 'react-html-parser';
import { FirebaseContext } from '../../contexts/Firebase';
import {
  PageLoader,
  CheckoutModal,
  showToastAlert
} from '../../Services/Utility';


const Pricing = () => {
  const { user, firebase } = useContext(FirebaseContext);
  const [activeTab, setActiveTab] = useState('residential');
  const [dcPlans, setDCPlans] = useState([]);
  const [dcPlanMetaData, setDCPlanMetaData] = useState([]);
  const [dcCategories, setDCCategories] = useState([]);
  const [dcCategory, setDCCategory] = useState('');
  const [inStockDC, setInStockDC] = useState({});
  const [isDCPlansActive, setDCPlansActive] = useState(null);

  const [primePlans, setPrimePlans] = useState([]);
  const [primePlanMetaData, setPrimePlanMetaData] = useState([]);
  const [primeCategories, setPrimeCategories] = useState([]);
  const [primeCategory, setPrimeCategory] = useState('');
  const [inStockPrime, setInStockPrime] = useState({});
  const [isPrimePlansActive, setPrimePlansActive] = useState(null);

  const [resiPlans, setResiPlans] = useState([]);
  const [resiPlanMetaData, setResiPlanMetaData] = useState([]);
  const [resiCategories, setResiCategories] = useState([]);
  const [resiCategory, setResiCategory] = useState('');
  const [isResiPlansActive, setResiPlansActive] = useState(null);


  const [premierPlans, setPremierPlans] = useState([]);
  const [premierPlanMetaData, setPremierPlanMetaData] = useState([]);
  const [premierCategories, setPremierCategories] = useState([]);
  const [premierCategory, setPremierCategory] = useState('');
  const [isPremierPlansActive, setPremierPlansActive] = useState(null);


  const [resiPremPlans, setResiPremPlans] = useState([]);
  const [resiPremPlanMetaData, setResiPremPlanMetaData] = useState([]);
  const [resiPremCategories, setResiPremCategories] = useState([]);
  const [resiPremCategory, setResiPremCategory] = useState('');
  const [isResiPremPlansActive, setResiPremPlansActive] = useState(null);
  const [checkoutModals, setCheckoutModals] = useState([]);
  const [loading, setLoading] = useState(true);
  // const supportedSites = [
  //   "footlocker.png", "shopify.png", "supreme.png",
  //   "adidas.png", "yeezy.png", "dicks.png",
  //   "walmart.png", "bestbuy.png", "target.png",
  //   "nike.png", "hibbett.png", "recaptcha.png"
  // ];

  const getInStockDC = () => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/datacenter/inStockDC`
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          return reject(new Error('Error while getting proxies allocation left for datacenter'));
        } else {
          if (!Object.keys(resData.data).length) {
            return reject(new Error('No servers are avaialble for datacenter plan'));
          }
          return resolve(resData.data);
        }
      });
    });
  }

  const getInStockPrime = () => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/prime/inStockDC`
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          return reject(new Error('Error while getting proxies allocation left for datacenter'));
        } else {
          if (!Object.keys(resData.data).length) {
            return reject(new Error('No servers are avaialble for datacenter plan'));
          }
          return resolve(resData.data);
        }
      });
    });
  }

  const getPlanMetaData = useCallback((planType, plans, isActivePlan, category, sortBy) => {
    try {
      return plans.map(plan => {
        let subPlans = Object.entries(plan.subPlans)
          .sort((subP1, subP2) => subP1[1][sortBy] - subP2[1][sortBy]);
        
        let options = subPlans.map((subP) => {
          let label = "";
          if(planType === 'residential' || planType === 'residential_premium' || planType === 'premier' ){
            label = subP[1].dataLimitMB / 1000;
          }else if(planType === 'prime'){
            label = subP[1].numberOfProxies + '/' + subP[1].dataLimitMB / 1000;
          }else{
            
            label = subP[1].numberOfProxies;
          }
          return {
            value: subP[0],
            label: label
          }
        });
        let value = options[0];
        let subPlan = subPlans[0][1];
        let price = subPlan.price;
        let isActive = isActivePlan && plan.active && subPlan.active;
        if (planType === 'datacenter' ) {
          isActive = isActive && subPlan.numberOfProxies <= inStockDC[plan.servername];
        }
        if (planType === 'prime' ) {
          isActive = isActive && subPlan.numberOfProxies <= inStockPrime[plan.servername];
        }

        return {
          planData: {
            ...plan
          },
          price,
          options,
          value,
          isActive,
        }
      });
    } catch (error) {
      console.log(error);
      showToastAlert('Error', error.message);
      return [];
    }
  }, [inStockDC , inStockPrime]);

  const updateSubPlan = (planType, planID, option, operation = 'add') => {
    let planMetaData, isActive;
    if (planType === 'datacenter') {
      planMetaData = [...dcPlanMetaData];
      isActive = isDCPlansActive;
    }
    if (planType === 'prime') {
      planMetaData = [...primePlanMetaData];
      isActive = isPrimePlansActive;
    }
    if (planType === 'residential') {
      planMetaData = [...resiPlanMetaData];
      isActive = isResiPlansActive;
    }
    if (planType === 'premier') {
      planMetaData = [...premierPlanMetaData];
      isActive = isPremierPlansActive;
    }
    if (planType === 'residential_premium') {
      planMetaData = [...resiPremPlanMetaData];
      isActive = isResiPremPlansActive;
    }

    planMetaData.map((plan) => {
      if (plan.planData.planID === planID) {
        let subPlanIndex = plan.options.indexOf(option);
        if (subPlanIndex <= 0 && operation === 'minus') {
          subPlanIndex = 0;
        } else if (subPlanIndex >= (plan.options.length - 1) && operation === 'add') {
          subPlanIndex = plan.options.length - 1;
        } else {
          subPlanIndex = operation === 'add' ? ++subPlanIndex : --subPlanIndex;
        }

        let subPlanID = plan.options[subPlanIndex]['value'];
        let subPlan = plan.planData.subPlans[subPlanID];
        let value = plan.options[subPlanIndex];
        let price = subPlan.price;
        isActive = isActive && plan.planData.active && subPlan.active;
        if (planType === 'datacenter') {
          isActive = isActive && subPlan.numberOfProxies <= inStockDC[plan.planData.servername];
        }
        if (planType === 'prime') {
          isActive = isActive && subPlan.numberOfProxies <= inStockPrime[plan.planData.servername];
        }

        plan['value'] = value;
        plan['price'] = price;
        plan['isActive'] = isActive;
      }
      return plan;
    });
    if (planType === 'datacenter') {
      setDCPlanMetaData(planMetaData);
    }
    if (planType === 'residential') {
      setResiPlanMetaData(planMetaData);
    }
    if (planType === 'premier') {
      setPremierPlanMetaData(planMetaData);
    }
    if (planType === 'residential_premium') {
      setResiPremPlanMetaData(planMetaData);
    }
    if (planType === 'prime') {
      setPrimePlanMetaData(planMetaData);
    }
  }

  const openCheckoutModal = async (planType, planID, subPlanID) => {
    try {
      setCheckoutModals([]);
      if (!user) {
        showToastAlert('Error', 'Log in required to purchase a plan');
        return;
      }
      let discordId = await firebase.db.ref(`users/${firebase.auth.currentUser.uid}/discordId`).once('value');
      discordId = discordId.val();
      if (!discordId) {
        showToastAlert('Error', 'Please join discord server to purchase a plan');
        return;
      }
      let showModal = true;
      let plans;
      if (planType === 'datacenter') {
        plans = dcPlans;
      }else if (planType === 'prime') {
        plans = primePlans;
      }else if (planType === 'residential') {
        plans = resiPlans;      
      }else if (planType === 'premier') {
        plans = premierPlans;
      }else if (planType === 'residential_premium') {
        plans = resiPremPlans;
      }
      let planData = plans.filter(plan => plan.planID === planID)[0];
      if (!planData) {
        return;
      }
      let subPlan = planData['subPlans'][subPlanID];
      let checkoutPlanPrice = subPlan.price;
      let planPrice = `Plan Price $${checkoutPlanPrice}`;
      setCheckoutModals([
        <CheckoutModal
          showModal={showModal}
          checkoutPlanID={planID}
          checkoutsubPlanID={subPlanID}
          checkoutPlanType={planType}
          checkoutPlanPrice={checkoutPlanPrice}
          planPrice={planPrice}
          key={Math.random()}
          firebase={firebase}
        />
      ]);
    } catch (error) {
      showToastAlert('Error', error.message);
    }
  }

  useEffect(() => {
    try {
      firebase.db.ref('plans/').on('value', async (snapshot) => {
        const dbPlans = snapshot.val();
        // Set datacenter plans info
        const dcPlans = dbPlans['datacenter'];

        setDCPlansActive(dcPlans.active);
        delete dcPlans.active;

        const dcCategoryList = Object.keys(dcPlans.categories).map(key => ({
          ...dcPlans.categories[key],
          category: key,
        }));
        dcCategoryList.reverse();
        setDCCategories(dcCategoryList);
        setDCCategory(dcCategoryList[0] && dcCategoryList[0]['category']);
        delete dcPlans.categories;

        let inStockDC = {};
        try {
          inStockDC = await getInStockDC();
        } catch (error) {
          Object.values(dcPlans).map(plan => inStockDC[plan['servername']] = 0);
        }
        setInStockDC(inStockDC);      

        const dcPlanList = Object.keys(dcPlans).map(key => ({
          ...dcPlans[key],
          planID: key,
        }));
        setDCPlans(dcPlanList);

        // Set Prime plan Information
        const primePlans = dbPlans['prime'];

        // setPrimePlansActive(primePlans.active);
        // delete primePlans.active;

        // const primeCategoryList = Object.keys(primePlans.categories).map(key => ({
        //   ...primePlans.categories[key],
        //   category: key,
        // }));
        // primeCategoryList.reverse();
        // setPrimeCategories(primeCategoryList);
        // setPrimeCategory(primeCategoryList[0] && primeCategoryList[0]['category']);
        // delete primePlans.categories;

        let inStockPrime = {};
        // try {
        //   inStockPrime = await getInStockPrime();
        // } catch (error) {
        //   Object.values(primePlans).map(plan => inStockPrime[plan['servername']] = 0);
        // }
        // setInStockPrime(inStockPrime);      

        // const primePlanList = Object.keys(primePlans).map(key => ({
        //   ...primePlans[key],
        //   planID: key,
        // }));
        // setPrimePlans(primePlanList);

        // Set premier plans info
        const premierPlans = dbPlans['premier'];
        setPremierPlansActive(premierPlans.active);
        delete premierPlans.active;

        const premierCategoryList = Object.keys(premierPlans.categories).map(key => ({
          ...premierPlans.categories[key],
          category: key,
        }));
        setPremierCategories(premierCategoryList);
        setPremierCategory(premierCategoryList[0] && premierCategoryList[0]['category']);
        delete premierPlans.categories;

        const premierPlanList = Object.keys(premierPlans).map(key => ({
          ...premierPlans[key],
          planID: key,
        }));
        setPremierPlans(premierPlanList);

        // Set residential plans info
        // const resiPlans = dbPlans['residential'];
        // setResiPlansActive(resiPlans.active);
        // delete resiPlans.active;

        // const resiCategoryList = Object.keys(resiPlans.categories).map(key => ({
        //   ...resiPlans.categories[key],
        //   category: key,
        // }));
        // setResiCategories(resiCategoryList);
        // setResiCategory(resiCategoryList[0] && resiCategoryList[0]['category']);
        // delete resiPlans.categories;

        // const resiPlanList = Object.keys(resiPlans).map(key => ({
        //   ...resiPlans[key],
        //   planID: key,
        // }));
        // setResiPlans(resiPlanList);

        // Set residential premium plans info
        const resiPremPlans = dbPlans['residential_premium'];
        setResiPremPlansActive(resiPremPlans.active);
        delete resiPremPlans.active;

        const resiPremCategoryList = Object.keys(resiPremPlans.categories).map(key => ({
          ...resiPremPlans.categories[key],
          category: key,
        }));
        setResiPremCategories(resiPremCategoryList);
        setResiPremCategory(resiPremCategoryList[0] && resiPremCategoryList[0]['category']);
        delete resiPremPlans.categories;

        const resiPremPlanList = Object.keys(resiPremPlans).map(key => ({
          ...resiPremPlans[key],
          planID: key,
        }));
        setResiPremPlans(resiPremPlanList);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      showToastAlert('Error', 'Error while fetching plan details!');
      // console.log(error.message);
    }
  }, [firebase.db]);

  useEffect(() => {
    return () => {
      firebase.db.ref('plans/').off();
    }
  }, [firebase.db]);

  useEffect(() => {
    if (dcPlans.length && isDCPlansActive !== null && dcCategories.length && dcCategory && Object.keys(inStockDC).length) {
      const dcPlanMetaData = getPlanMetaData('datacenter', dcPlans, isDCPlansActive, dcCategory, 'numberOfProxies');
      setDCPlanMetaData(dcPlanMetaData);
    }
  }, [getPlanMetaData, dcPlans, isDCPlansActive, dcCategories, dcCategory, inStockDC]);

  useEffect(() => {
    if (primePlans.length && isPrimePlansActive !== null && primeCategories.length && primeCategory && Object.keys(inStockPrime).length) {
      const primePlanMetaData = getPlanMetaData('prime', primePlans, isPrimePlansActive, primeCategory, 'numberOfProxies');
      setPrimePlanMetaData(primePlanMetaData);
    }
  }, [getPlanMetaData, primePlans, isPrimePlansActive, primeCategories, primeCategory, inStockPrime]);

  useEffect(() => {
    if (resiPlans.length && isResiPlansActive !== null && resiCategories.length && resiCategory) {
      const resiPlanMetaData = getPlanMetaData('residential', resiPlans, isResiPlansActive, resiCategory, 'dataLimitMB');
      setResiPlanMetaData(resiPlanMetaData);
    }
  }, [getPlanMetaData, resiPlans, isResiPlansActive, resiCategories, resiCategory]);

  useEffect(() => {
    if (premierPlans.length && isPremierPlansActive !== null && premierCategories.length && premierCategory) {
      const premierPlanMetaData = getPlanMetaData('premier', premierPlans, isPremierPlansActive, premierCategory, 'dataLimitMB');
      setPremierPlanMetaData(premierPlanMetaData);
    }
  }, [getPlanMetaData, premierPlans, isPremierPlansActive, premierCategories, premierCategory]);


  useEffect(() => {
    if (resiPremPlans.length && isResiPremPlansActive !== null && resiPremCategories.length && resiPremCategory) {
      const resiPremPlanMetaData = getPlanMetaData('residential_premium', resiPremPlans, isResiPremPlansActive, resiPremCategory, 'dataLimitMB');
      setResiPremPlanMetaData(resiPremPlanMetaData);
    }
  }, [getPlanMetaData, resiPremPlans, isResiPremPlansActive, resiPremCategories, resiPremCategory]);

  const getPlanCard = (planType, planData, price, options, value, isActive, valueIndex) => {
    return (
      <Col md="6" key={planData.planID}>
        <Card
          data-planid={planData.planID}
          data-wow-duration="2s"
          className="mt-3 plan-card wow animate__animated animate__fadeInDown"
        >
          <CardHeader className="plan-card-header">
            <CardTitle data-wow-duration="2s" className="plan-card-title wow animate__animated animate__fadeInDown">
              {planData.name}
            </CardTitle>
            <div data-wow-duration="2s" className="plan-card-type wow animate__animated animate__fadeInDown">
              {planType === 'residential' || planType === 'residential_premium' || planType === 'prime' || planType === 'premier' ? 'Residential' : 'Datacenter'}
            </div>
            <div data-wow-duration="2s" className="plan-card-price wow animate__animated animate__fadeInDown">
              ${price}
            </div>
          </CardHeader>

          <CardBody data-wow-duration="2s" className="plan-card-body wow animate__animated animate__fadeInDown">
            <ul data-wow-duration="2s" className="plan-card-features wow animate__animated animate__fadeInDown">
              {ReactHtmlParser(planData.features)}
            </ul>

            <div data-wow-duration="2s" className="plan-card-update-subPlan text-center wow animate__animated animate__fadeInDown">
              <InputGroup className="mt-2 d-inline-block align-items-center">
                <Button
                  disabled={valueIndex < 1}
                  className="btn-round btn-simple btn-update-resi-card btn-minus"
                  color="secondary"
                  onClick={() => updateSubPlan(planType, planData.planID, value, 'minus')}
                >
                  <i className="fas fa-minus"></i>
                </Button>
                <Input
                  readOnly
                  className="input-subplan input-resi-subplan"
                  value={planType === 'residential' || planType === 'residential_premium' || planType === 'prime' || planType === 'premier' ? value.label + 'GB' : value.label}
                  type="text"
                />
                <Button
                  disabled={valueIndex >= (options.length - 1)}
                  className="btn-round btn-simple btn-update-resi-card btn-plus"
                  color="secondary"
                  onClick={() => updateSubPlan(planType, planData.planID, value, 'add')}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </InputGroup>
            </div>

            <div data-wow-duration="2s" className="mt-3 text-center wow animate_animated animate_fadeInDown">
              <Button
                disabled={!isActive}
                data-planid={planData.planID}
                outline
                color="secondary"
                className="w-75 btn-round btn-plan-purchase"
                size="md"
                onClick={() => openCheckoutModal(planType, planData.planID, value.value)}
              >
                {isActive ? 'Purchase' : 'Sold Out!'}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }

  return (
    <>
      {loading && <PageLoader />}
      <section className={activeTab === 'residential' ? "col-12 wow Pricing_BG" : "col-12 wow"} id="pricing">
        <Row>
          <Col xs="12">
            <h1 className="page-title">
              Pricing Plans
              <ButtonGroup className="mt-n3 float-right plan-section-group wow animate__animated animate__fadeInDown" data-wow-duration="2s">
               
                <Button
                  color="secondary"
                  className={activeTab === "residential" ? "active btn-round btn-plan-selection btn-residential" : "btn-round btn-plan-selection btn-residential"}
                  size="md"
                  onClick={() => setActiveTab('residential')}
                >
                  Residential
                </Button>
                <Button
                  data-wow-duration="2s"
                  color="secondary"
                  className={activeTab === "datacenter" ? "active btn-round btn-plan-selection btn-datacenter" : "btn-round btn-plan-selection btn-datacenter"}
                  size="md"
                  onClick={() => setActiveTab('datacenter')}
                >
                  Datacenter
                </Button>
              </ButtonGroup>
            </h1>
          </Col>

          {activeTab === "residential" ?
            <Col xs="12" className="residentials-planlist mt-3">
              <Row className="justify-content-center">
                {/* {resiPlanMetaData && resiPlanMetaData.map((metaData, index) => {
                  let planData = metaData.planData;
                  let {
                    price,
                    options,
                    value,
                    isActive,
                  } = metaData;
                  let valueIndex = options.indexOf(value);
                  return getPlanCard('residential', planData, price, options, value, isActive, valueIndex);
                })} */}

                {resiPremPlanMetaData && resiPremPlanMetaData.map((metaData, index) => {
                  let planData = metaData.planData;
                  let {
                    price,
                    options,
                    value,
                    isActive,
                  } = metaData;
                  let valueIndex = options.indexOf(value);
                  return getPlanCard('residential_premium', planData, price, options, value, isActive, valueIndex);
                })}

                {premierPlanMetaData && premierPlanMetaData.map((metaData, index) => {
                  let planData = metaData.planData;
                  let {
                    price,
                    options,
                    value,
                    isActive,
                  } = metaData;
                  let valueIndex = options.indexOf(value);
                  return getPlanCard('premier', planData, price, options, value, isActive, valueIndex);
                })}

                {primePlanMetaData && primePlanMetaData.map((metaData, index) => {
                  let planData = metaData.planData;
                  let {
                    price,
                    options,
                    value,
                    isActive,
                  } = metaData;
                  let valueIndex = options.indexOf(value);
                  return getPlanCard('prime', planData, price, options, value, isActive, valueIndex);
                })}

              
              </Row>
            </Col>
            : null}

          {activeTab === "datacenter" ?
            <Col className="datacenter-planlist mt-3">
              <Row className="justify-content-center" style={{display:"none"}}>
                <Col xs="12" md="4" className="text-center pb-2">
                  <Nav pills fill className="category-pills">
                    {dcCategories && dcCategories.map((category, index) => {
                      return (
                        <NavItem key={category.category}>
                          <NavLink
                            className={dcCategory === category.category ? 'active' : ''}
                            onClick={() => setDCCategory(category.category)}
                          >{category.name}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </Col>
              </Row>
              <Row>
                {dcPlanMetaData && dcPlanMetaData.map((metaData, index) => {
                  let planData = metaData.planData;
                  let {
                    price,
                    options,
                    value,
                    isActive,
                  } = metaData;
                  let valueIndex = options.indexOf(value);
                  return getPlanCard('datacenter', planData, price, options, value, isActive, valueIndex);
                })}

                {/* <Col className="wow animate_animated animate_fadeInDown" data-wow-duration="10s">
                  <h1 data-wow-duration="2s" className="page-title mt-5 wow animate_animated animate_fadeInDown">
                    Supported Sites
                  </h1>
                  {supportedSites && supportedSites.map((site, index) => {
                    return (
                      <img
                        key={site}
                        alt="..."
                        data-wow-duration="2s"
                        className="plan-card-datacenter-img wow animate_animated animate_fadeInDown"
                        src={require(`assets/img/${site}`)}
                      />
                    )
                  })}
                </Col> */}
              </Row>
            </Col>
            : null}
        </Row>
        {checkoutModals.map(checkoutModal => (
          checkoutModal
        ))}
      </section>
    </>
  );
}

export default Pricing;