import React from "react";
import { Doughnut, Chart } from 'react-chartjs-2';


class DoughnutChart extends React.Component {
  constructor(props) {
    super(props);
    let text = props.text || '100%';
    let type = props.text || 'resi';
    let dataset = props.dataset || [0, 0];

    let data = {
     
      config: [{
        cutoutPercentage: 0
      }],
      text: text,
      datasets: [{
        data: dataset,
        backgroundColor: [
          '#c7b299',
          '#131119'
        ],
        hoverBackgroundColor: [
          '#c7b299',
          '#131119'
        ],
        borderWidth: 0
      }]
    };
    if(type === 'dc'){
      data.labels = [
        'Remaining',
        'Used'
      ]
    }else{
      data.labels = [
        'Used',
        'Remaining'
      ]
    }

    let donutOptions = {
      responsive: true,
      cutoutPercentage: 90,
      legend: {
        display: false
      }
    };

    this.state = {
      data,
      donutOptions,
      type : type
    };
  }

  render() {
    const { data, donutOptions, type } = this.state;

    var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function () {
        originalDoughnutDraw.apply(this, arguments);

        var chart = this.chart.chart;
        var ctx = chart.ctx;
        var width = chart.width;
        var height = chart.height;

        ctx.font = "1.5rem Mulish";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#ffffff";

        var text = chart.config.data.text,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
          
          ctx.fillText(text, textX, textY - 15);
          ctx.font = "1rem Mulish";
          textX = Math.round((width - ctx.measureText(type === 'dc' ? "Remaining" : "Used").width) / 2);

          ctx.fillStyle = "#7e7c86";

          ctx.fillText(type === 'dc' ? "Remaining" : "Used", textX, textY + 15);
      }
    });

    return (
      <Doughnut
        options={donutOptions}
        data={data}
        width={200}
      />
    );
  }
}

export default DoughnutChart;
