import React from 'react';
import { Container, Row, Col } from 'reactstrap';


const TOS = () => {
  return (
    <Container>
      <Row>
        <Col xs="12" className="wow animate__animated animate__fadeInDown" data-wow-duration="2s">
          <h1 className="page-title">Terms and Conditions</h1>
          <p className="description text-center font-weight-bold mt-n2 mb-3">The Customer of "HollowProxies, LLC" Acknowledges That:</p>

          <div className="wow animate__animated animate__fadeInDown" data-wow-duration="2s">
            <p className="description">• All Sales are <strong>Final</strong></p>
            <p className="description">• There will be no refunds or exchanges as this is a digital product</p>
            <p className="description">• There will be no warranties on any product given</p>
            <p className="description">• "HollowProxies" will not be responsible for any throttles,bans, or any other issues due to misuse and abuse of products by the customer</p>
            <p className="description">• Proxies will be delivered via email or direct message to the customer. Proxies will be functional upon arrival to the customer</p>
            <p className="description">• "HollowProxies" does not guarantee any success by the customer purchasing any of the available products</p>
            <p className="description">• The "Customer" or "Buyer" do acknowledge that any dispute/chargeback initiated via their "Financial institution", "Bank", or "Credit Card company" is an attempt to fraudulently attain the product while receiving reimbursement of the total value, which will also be recognized by their "Financial Institution", "Bank", or "Credit Card Company" as fraud, which will, in turn, be denied of any such dispute/chargeback. We reserve the right to add a $200 special processing fee, for disputes initiated by you, which are resolved in our favor.</p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default TOS;