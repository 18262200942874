import React from 'react';
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  NavLink
} from 'reactstrap';


const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md="6" className="mt-3">
            <Link to="/">
              <img
                className="logo"
                src={require("assets/img/logo.png")}
                alt="HollowProxies"
              />
            </Link>
            <div className="d-inline-block title"><strong>Hollow</strong>Proxies</div>
            <Nav className="d-inline-block">
              <NavLink
                className="d-inline-block"
                href="https://twitter.com/hollowproxies"
                target="_blank">
                <i className="fab fa-twitter"></i>
              </NavLink>
              <NavLink
                className="d-inline-block"
                href="https://twitter.com/hollowproxies"
                target="_blank">
                <i className="fab fa-discord"></i>
              </NavLink>
            </Nav>
          </Col>
          <Col md="6" className="mt-3">
            <h5 className="navigation-title">Navigation</h5>
            <Nav className="navigation-links">
              <NavLink href="/#home">
                Home
              </NavLink>
              <NavLink to="/about-us" tag={Link}>
                About
              </NavLink>
              <NavLink to="#" tag={Link}>
                Join
              </NavLink>
              <NavLink href="/#faq">
                FAQ
              </NavLink>
            </Nav>
          </Col>
        </Row>

        <Row>
          <Col md="6" className="d-none d-md-block">
            <p className="m-0 p-0 copyright">© 2020 Hollow LLC. All rights reserved.</p>
          </Col>
          <Col md="6" className="mt-3">
            <Nav className="policy-links mt-3">
              <NavLink
                className="d-inline-block"
                tag={Link}
                to="/terms-and-conditions">
                Terms of Service
              </NavLink>
              <NavLink
                className="d-inline-block"
                tag={Link}
                to="/privacy-policy">
                Privacy Policy
              </NavLink>
              <NavLink
                className="d-inline-block"
                tag={Link}
                to="/refund-policy">
                Refund Policy
              </NavLink>
            </Nav>
          </Col>
          <Col md="6" className="mt-3 d-block d-md-none">
            <p className="m-0 copyright">© 2020 Hollow LLC. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;