import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import * as request from 'request';
import { showToastAlert } from './Utility';
import $ from 'jquery'; 


var Stripe;
(async () => {
  Stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
})();
const API_SERVER_URL = process.env.REACT_APP_SERVER_URL;


const INITIAL_STATE = {
  showModal: false,
  checkoutPlanID: '',
  checkoutsubPlanID: '',
  updatePlanRefID: '',
  checkoutPlanType: '',
  checkoutPlanPrice: 0,
  couponCode: '',
  planPrice: 'Plan Price $0',
  couponDiscount: '', 
  isSubscription: false
};

class CheckoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      checkoutPlanID: props.checkoutPlanID || '',
      checkoutsubPlanID: props.checkoutsubPlanID || '',
      updatePlanRefID: props.updatePlanRefID || '',
      checkoutPlanType: props.checkoutPlanType || '',
      checkoutPlanPrice: props.checkoutPlanPrice || 0,
      planPrice: props.planPrice || 'Plan Price $0',
      couponCode: '',
      couponDiscount: '', 
      isSubscription : false
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal = () => {
    this.setState({
      ...INITIAL_STATE
    });
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  applyCouponCode = async (event) => {
    let $current = event.target;
    let couponCode = this.state.couponCode.trim();
    let planType = this.state.checkoutPlanType;
    let planPrice = this.state.checkoutPlanPrice;
    let isSubscription = this.state.isSubscription;

    if (couponCode) {
      $current.disabled = true;
      $current.innerHTML = `<div class="spinner-grow text-light" role="status"></div> Checking..`;
      let idToken = await this.props.firebase.auth.currentUser.getIdToken(true);

      let options = {
        url: `${API_SERVER_URL}/getCouponCodeDiscount`,
        method: 'POST',
        headers: {
          'Authorization': idToken
        },
        form: {
          couponCode,
          planType,
          planPrice,
          isSubscription
        }
      };

      return request(options, (error, response, body) => {
        $current.disabled = false;
        $current.innerHTML = '';
        $current.innerText = 'APPLY';
        let resData = body && JSON.parse(body);

        if (error || response.statusCode !== 200) {
          document.getElementById('checkoutPayBtn').innerText = `Pay $${planPrice.toFixed(2)}`;
          this.setState({
            couponCode: '',
            couponDiscount: ''
          });
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while getting coupon code info!';
          showToastAlert('Error', errorMessage);
        } else {
          if (resData.status === 'success') {
            let discountAmount = parseFloat(resData['discountAmount']);
            let paymentAmount = parseFloat(parseFloat(planPrice - discountAmount).toFixed(2));
            document.getElementById('checkoutPayBtn').innerText = `Pay $${paymentAmount.toFixed(2)}`;
            this.setState({
              couponDiscount: `Applied Coupon Discount -$${discountAmount.toFixed(2)}`
            });
            showToastAlert('Success', 'Your coupon code is sucessfully applied.');
          }
        }
      });
    }
  }

  doPlanCheckout = async (event) => {
    let $current = event.target;
    try {
      $current.disabled = true;
      $current.innerHTML = `<div class="spinner-grow text-light" role="status"></div> Processing...`;

      let idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
      let planID = this.state.checkoutPlanID;
      let planType = this.state.checkoutPlanType;
      let subPlanID = this.state.checkoutsubPlanID;
      let updatePlanRefID = this.state.updatePlanRefID;
      let couponCode = this.state.couponCode;
      let planPrice = this.state.checkoutPlanPrice;
      let isSubscription = this.state.isSubscription;

      let checkoutData = {
        planID,
        subPlanID,
        planType,
        isSubscription
      };
      if (updatePlanRefID) {
        checkoutData['updatePlanRefID'] = updatePlanRefID;
      }
      if (couponCode) {
        checkoutData['couponCode'] = couponCode;
      }

      let options = {
        url: `${API_SERVER_URL}/createSession`,
        method: 'POST',
        headers: {
          'Authorization': idToken
        },
        form: checkoutData
      };

      return request(options, (error, response, body) => {
        $current.disabled = false;
        $current.innerHTML = '';
        $current.innerText = `Pay $${planPrice}`;
        let resData = body && JSON.parse(body);

        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred during plan checkout !';
          showToastAlert('Error', errorMessage);
        } else {
          if (resData.id) {
            Stripe.redirectToCheckout({
              sessionId: resData.id
            }).then((result) => {
              if ('error' in result) {
                showToastAlert('Error', result['error']['message']);
              }
            });
          } else if (resData.status === 'success' && resData.redirect_url) {
            this.props.history.push('/success');
          } else {
            showToastAlert('Error', 'Something went wrong with your payment, Please try again later !');
          }
        }
      });
    } catch (error) {
      $current.disabled = false;
      $current.innerHTML = '';
      $current.innerText = `Pay $${this.state.checkoutPlanPrice}`;
      showToastAlert('Error', error.message || 'A server error occurred during plan checkout !');
    }
  }

  render() {
    const { showModal, checkoutPlanID, checkoutsubPlanID, updatePlanRefID, checkoutPlanType, checkoutPlanPrice, couponCode, planPrice, couponDiscount } = this.state;
    const isCheckoutInvalid = checkoutPlanID === '' || checkoutPlanType === '';
    const isCouponInvalid = couponCode === '';

    return (
      <Modal
        isOpen={showModal}
        toggle={this.closeModal}
        onClosed={this.closeModal}
        className="checkoutModal"
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={this.closeModal}>
          <img
            src={require("assets/img/logo.png")}
            alt="Hollow Proxies"
          />
          <p className="title mt-3">CONFIRM YOUR PAYMENT</p>
        </ModalHeader>
        <ModalBody>
          <input
            name="checkoutPlanID"
            type="hidden"
            value={checkoutPlanID}
            onChange={this.onChange}
            id="checkoutPlanID"
          />
          <input
            name="checkoutsubPlanID"
            type="hidden"
            value={checkoutsubPlanID}
            onChange={this.onChange}
            id="checkoutsubPlanID"
          />
          <input
            name="updatePlanRefID"
            type="hidden"
            value={updatePlanRefID}
            onChange={this.onChange}
            id="updatePlanRefID"
          />
          <input
            name="checkoutPlanType"
            type="hidden"
            value={checkoutPlanType}
            onChange={this.onChange}
            id="checkoutPlanType"
          />
          <input
            name="checkoutPlanPrice"
            type="hidden"
            value={checkoutPlanPrice}
            onChange={this.onChange}
            id="checkoutPlanPrice"
          />
          <div className="input-group mb-3" id="paymentApplyCouponCode">
            <input
              name="couponCode"
              type="text"
              value={couponCode}
              onChange={this.onChange}
              className="form-control input-theme"
              id="couponCode"
              placeholder="Coupon Code"
            />
            
            <div className="input-group-append">
              <Button
                disabled={isCouponInvalid}
                color="default"
                className="h-100 m-0 btn-theme"
                id="applyCouponCodeBtn"
                onClick={this.applyCouponCode}
              >
                APPLY
              </Button>
            </div>
          </div>
          {!updatePlanRefID && (checkoutPlanType == 'datacenter') &&
          <div>
          <Label check className="mt-3">
              <Input type="checkbox" id="isSubscription" onChange={(event)=>{this.setState({isSubscription : event.target.checked})}} />{' '}
              Enable Subscription
            </Label>
          </div>}
          <div id="paymentInfo">


            <div id="planPrice" className="mt-3">
              {planPrice}
            </div>
            <div id="couponDiscount">
              {couponDiscount}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="text-center mt-2 w-100">
            <Button
              disabled={isCheckoutInvalid}
              color="default"
              className="w-100 btn-theme"
              id="checkoutPayBtn"
              size="lg"
              onClick={this.doPlanCheckout}
            >
              {checkoutPlanPrice ? `Pay $${checkoutPlanPrice}` : 'Checkout'}
            </Button>
            <p id="checkoutTemrs">
              By Purchasing our products, you are agree with our Terms of Service.
            </p>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter(CheckoutModal);
