import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import firebaseConfig from "./FirebaseConfig";


class Firebase {
  static firebase;
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.database();
  }

  static getInstance() {
    if (!Firebase.firebase) {
      Firebase.firebase = new Firebase();
    }
    return Firebase.firebase;
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
}

let firebase = Firebase.getInstance();
export default firebase;