const resiPremiumCities = [
  {
    "Country": "AF",
    "CountryName": "Afghanistan",
    "City": "Charikar",
    "ParameterValue": "charikar"
  },
  {
    "Country": "AF",
    "CountryName": "Afghanistan",
    "City": "Kabul",
    "ParameterValue": "kabul"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Berat",
    "ParameterValue": "berat"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Burrel",
    "ParameterValue": "burrel"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Elbasan",
    "ParameterValue": "elbasan"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Fier",
    "ParameterValue": "fier"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Lushnje",
    "ParameterValue": "lushnje"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Pogradec",
    "ParameterValue": "pogradec"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Shkoder",
    "ParameterValue": "shkoder"
  },
  {
    "Country": "AL",
    "CountryName": "Albania",
    "City": "Tirana",
    "ParameterValue": "tirana"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Adrar",
    "ParameterValue": "adrar"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Algiers",
    "ParameterValue": "algiers"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Annaba",
    "ParameterValue": "annaba"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Biskra",
    "ParameterValue": "biskra"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Blida",
    "ParameterValue": "blida"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Bouira",
    "ParameterValue": "bouira"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Constantine",
    "ParameterValue": "constantine"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Djelfa",
    "ParameterValue": "djelfa"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Guelma",
    "ParameterValue": "guelma"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Illizi",
    "ParameterValue": "illizi"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Jijel",
    "ParameterValue": "jijel"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Khenchela",
    "ParameterValue": "khenchela"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Laghouat",
    "ParameterValue": "laghouat"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Mascara",
    "ParameterValue": "mascara"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Medea",
    "ParameterValue": "medea"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Mila",
    "ParameterValue": "mila"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Mostaganem",
    "ParameterValue": "mostaganem"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "M'sila",
    "ParameterValue": "m'sila"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Oran",
    "ParameterValue": "oran"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Ouargla",
    "ParameterValue": "ouargla"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Relizane",
    "ParameterValue": "relizane"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Saida",
    "ParameterValue": "saida"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Skikda",
    "ParameterValue": "skikda"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Tindouf",
    "ParameterValue": "tindouf"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Tipasa",
    "ParameterValue": "tipasa"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Tissemsilt",
    "ParameterValue": "tissemsilt"
  },
  {
    "Country": "DZ",
    "CountryName": "Algeria",
    "City": "Touggourt",
    "ParameterValue": "touggourt"
  },
  {
    "Country": "AO",
    "CountryName": "Angola",
    "City": "Benguela",
    "ParameterValue": "benguela"
  },
  {
    "Country": "AO",
    "CountryName": "Angola",
    "City": "Huambo",
    "ParameterValue": "huambo"
  },
  {
    "Country": "AO",
    "CountryName": "Angola",
    "City": "Lobito",
    "ParameterValue": "lobito"
  },
  {
    "Country": "AO",
    "CountryName": "Angola",
    "City": "Luanda",
    "ParameterValue": "luanda"
  },
  {
    "Country": "AO",
    "CountryName": "Angola",
    "City": "Lubango",
    "ParameterValue": "lubango"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Balcarce",
    "ParameterValue": "balcarce"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Campana",
    "ParameterValue": "campana"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Chamical",
    "ParameterValue": "chamical"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Chascomus",
    "ParameterValue": "chascomus"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Chilecito",
    "ParameterValue": "chilecito"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Chivilcoy",
    "ParameterValue": "chivilcoy"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Corrientes",
    "ParameterValue": "corrientes"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Formosa",
    "ParameterValue": "formosa"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Goya",
    "ParameterValue": "goya"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Gualeguay",
    "ParameterValue": "gualeguay"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Junin",
    "ParameterValue": "junin"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Lobos",
    "ParameterValue": "lobos"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Mendoza",
    "ParameterValue": "mendoza"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Mercedes",
    "ParameterValue": "mercedes"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Pergamino",
    "ParameterValue": "pergamino"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Posadas",
    "ParameterValue": "posadas"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Rawson",
    "ParameterValue": "rawson"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Reconquista",
    "ParameterValue": "reconquista"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Resistencia",
    "ParameterValue": "resistencia"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Rosario",
    "ParameterValue": "rosario"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Salta",
    "ParameterValue": "salta"
  },
  {
    "Country": "AR",
    "CountryName": "Argentina",
    "City": "Ushuaia",
    "ParameterValue": "ushuaia"
  },
  {
    "Country": "AM",
    "CountryName": "Armenia",
    "City": "Armavir",
    "ParameterValue": "armavir"
  },
  {
    "Country": "AM",
    "CountryName": "Armenia",
    "City": "Ashtarak",
    "ParameterValue": "ashtarak"
  },
  {
    "Country": "AM",
    "CountryName": "Armenia",
    "City": "Gyumri",
    "ParameterValue": "gyumri"
  },
  {
    "Country": "AM",
    "CountryName": "Armenia",
    "City": "Hrazdan",
    "ParameterValue": "hrazdan"
  },
  {
    "Country": "AM",
    "CountryName": "Armenia",
    "City": "Vanadzor",
    "ParameterValue": "vanadzor"
  },
  {
    "Country": "AM",
    "CountryName": "Armenia",
    "City": "Yerevan",
    "ParameterValue": "yerevan"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Adelaide",
    "ParameterValue": "adelaide"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Brisbane",
    "ParameterValue": "brisbane"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Caboolture",
    "ParameterValue": "caboolture"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Canberra",
    "ParameterValue": "canberra"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Melbourne",
    "ParameterValue": "melbourne"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Perth",
    "ParameterValue": "perth"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Sydney",
    "ParameterValue": "sydney"
  },
  {
    "Country": "AU",
    "CountryName": "Australia",
    "City": "Townsville",
    "ParameterValue": "townsville"
  },
  {
    "Country": "AT",
    "CountryName": "Austria",
    "City": "Innsbruck",
    "ParameterValue": "innsbruck"
  },
  {
    "Country": "AT",
    "CountryName": "Austria",
    "City": "Linz",
    "ParameterValue": "linz"
  },
  {
    "Country": "AT",
    "CountryName": "Austria",
    "City": "Vienna",
    "ParameterValue": "vienna"
  },
  {
    "Country": "AZ",
    "CountryName": "Azerbaijan",
    "City": "Baku",
    "ParameterValue": "baku"
  },
  {
    "Country": "AZ",
    "CountryName": "Azerbaijan",
    "City": "Lankaran",
    "ParameterValue": "lankaran"
  },
  {
    "Country": "AZ",
    "CountryName": "Azerbaijan",
    "City": "Sabirabad",
    "ParameterValue": "sabirabad"
  },
  {
    "Country": "AZ",
    "CountryName": "Azerbaijan",
    "City": "Xirdalan",
    "ParameterValue": "xirdalan"
  },
  {
    "Country": "BS",
    "CountryName": "Bahamas",
    "City": "Freeport",
    "ParameterValue": "freeport"
  },
  {
    "Country": "BS",
    "CountryName": "Bahamas",
    "City": "Nassau",
    "ParameterValue": "nassau"
  },
  {
    "Country": "BH",
    "CountryName": "Bahrain",
    "City": "Manama",
    "ParameterValue": "manama"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Chittagong",
    "ParameterValue": "chittagong"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Comilla",
    "ParameterValue": "comilla"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Dhaka",
    "ParameterValue": "dhaka"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Jessore",
    "ParameterValue": "jessore"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Khulna",
    "ParameterValue": "khulna"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Mymensingh",
    "ParameterValue": "mymensingh"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Narayanganj",
    "ParameterValue": "narayanganj"
  },
  {
    "Country": "BD",
    "CountryName": "Bangladesh",
    "City": "Sylhet",
    "ParameterValue": "sylhet"
  },
  {
    "Country": "BB",
    "CountryName": "Barbados",
    "City": "Bridgetown",
    "ParameterValue": "bridgetown"
  },
  {
    "Country": "BY",
    "CountryName": "Belarus",
    "City": "Babruysk",
    "ParameterValue": "babruysk"
  },
  {
    "Country": "BY",
    "CountryName": "Belarus",
    "City": "Hrodna",
    "ParameterValue": "hrodna"
  },
  {
    "Country": "BY",
    "CountryName": "Belarus",
    "City": "Maladzyechna",
    "ParameterValue": "maladzyechna"
  },
  {
    "Country": "BY",
    "CountryName": "Belarus",
    "City": "Mazyr",
    "ParameterValue": "mazyr"
  },
  {
    "Country": "BY",
    "CountryName": "Belarus",
    "City": "Minsk",
    "ParameterValue": "minsk"
  },
  {
    "Country": "BE",
    "CountryName": "Belgium",
    "City": "Brussels",
    "ParameterValue": "brussels"
  },
  {
    "Country": "BE",
    "CountryName": "Belgium",
    "City": "Charleroi",
    "ParameterValue": "charleroi"
  },
  {
    "Country": "BE",
    "CountryName": "Belgium",
    "City": "Namur",
    "ParameterValue": "namur"
  },
  {
    "Country": "BZ",
    "CountryName": "Belize",
    "City": "Belmopan",
    "ParameterValue": "belmopan"
  },
  {
    "Country": "BZ",
    "CountryName": "Belize",
    "City": "Corozal",
    "ParameterValue": "corozal"
  },
  {
    "Country": "BJ",
    "CountryName": "Benin",
    "City": "Cotonou",
    "ParameterValue": "cotonou"
  },
  {
    "Country": "BJ",
    "CountryName": "Benin",
    "City": "Lokossa",
    "ParameterValue": "lokossa"
  },
  {
    "Country": "BJ",
    "CountryName": "Benin",
    "City": "Natitingou",
    "ParameterValue": "natitingou"
  },
  {
    "Country": "BJ",
    "CountryName": "Benin",
    "City": "Porto-Novo",
    "ParameterValue": "porto-novo"
  },
  {
    "Country": "BO",
    "CountryName": "Bolivia",
    "City": "Cochabamba",
    "ParameterValue": "cochabamba"
  },
  {
    "Country": "BO",
    "CountryName": "Bolivia",
    "City": "Sucre",
    "ParameterValue": "sucre"
  },
  {
    "Country": "BO",
    "CountryName": "Bolivia",
    "City": "Tarija",
    "ParameterValue": "tarija"
  },
  {
    "Country": "BO",
    "CountryName": "Bolivia",
    "City": "Trinidad",
    "ParameterValue": "trinidad"
  },
  {
    "Country": "BA",
    "CountryName": "Bosnia and Herzegovina",
    "City": "Brcko",
    "ParameterValue": "brcko"
  },
  {
    "Country": "BA",
    "CountryName": "Bosnia and Herzegovina",
    "City": "Mostar",
    "ParameterValue": "mostar"
  },
  {
    "Country": "BA",
    "CountryName": "Bosnia and Herzegovina",
    "City": "Prijedor",
    "ParameterValue": "prijedor"
  },
  {
    "Country": "BA",
    "CountryName": "Bosnia and Herzegovina",
    "City": "Sarajevo",
    "ParameterValue": "sarajevo"
  },
  {
    "Country": "BA",
    "CountryName": "Bosnia and Herzegovina",
    "City": "Tuzla",
    "ParameterValue": "tuzla"
  },
  {
    "Country": "BA",
    "CountryName": "Bosnia and Herzegovina",
    "City": "Zenica",
    "ParameterValue": "zenica"
  },
  {
    "Country": "BW",
    "CountryName": "Botswana",
    "City": "Francistown",
    "ParameterValue": "francistown"
  },
  {
    "Country": "BW",
    "CountryName": "Botswana",
    "City": "Gaborone",
    "ParameterValue": "gaborone"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Abaetetuba",
    "ParameterValue": "abaetetuba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Acu",
    "ParameterValue": "acu"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Altamira",
    "ParameterValue": "altamira"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Alvorada",
    "ParameterValue": "alvorada"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Americana",
    "ParameterValue": "americana"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Aracaju",
    "ParameterValue": "aracaju"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Aracuai",
    "ParameterValue": "aracuai"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Arapiraca",
    "ParameterValue": "arapiraca"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Arcoverde",
    "ParameterValue": "arcoverde"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Barbacena",
    "ParameterValue": "barbacena"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Barreiras",
    "ParameterValue": "barreiras"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Barreiros",
    "ParameterValue": "barreiros"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Barretos",
    "ParameterValue": "barretos"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Bauru",
    "ParameterValue": "bauru"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Blumenau",
    "ParameterValue": "blumenau"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Brumado",
    "ParameterValue": "brumado"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Caico",
    "ParameterValue": "caico"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Cameta",
    "ParameterValue": "cameta"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Camocim",
    "ParameterValue": "camocim"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Campinas",
    "ParameterValue": "campinas"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Canoas",
    "ParameterValue": "canoas"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Canoinhas",
    "ParameterValue": "canoinhas"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Carpina",
    "ParameterValue": "carpina"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Caruaru",
    "ParameterValue": "caruaru"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Cascavel",
    "ParameterValue": "cascavel"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Castro",
    "ParameterValue": "castro"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Catanduva",
    "ParameterValue": "catanduva"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Chapadinha",
    "ParameterValue": "chapadinha"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Curitiba",
    "ParameterValue": "curitiba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Curvelo",
    "ParameterValue": "curvelo"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Dourados",
    "ParameterValue": "dourados"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Fortaleza",
    "ParameterValue": "fortaleza"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Franca",
    "ParameterValue": "franca"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Garanhuns",
    "ParameterValue": "garanhuns"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Goianesia",
    "ParameterValue": "goianesia"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Guaira",
    "ParameterValue": "guaira"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Guanambi",
    "ParameterValue": "guanambi"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Guarapuava",
    "ParameterValue": "guarapuava"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Guaratingueta",
    "ParameterValue": "guaratingueta"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Imperatriz",
    "ParameterValue": "imperatriz"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Inhumas",
    "ParameterValue": "inhumas"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Ipatinga",
    "ParameterValue": "ipatinga"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Ipora",
    "ParameterValue": "ipora"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Irece",
    "ParameterValue": "irece"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Itabuna",
    "ParameterValue": "itabuna"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Itapetinga",
    "ParameterValue": "itapetinga"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Jaguaquara",
    "ParameterValue": "jaguaquara"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Jaguarao",
    "ParameterValue": "jaguarao"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Janauba",
    "ParameterValue": "janauba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Joinville",
    "ParameterValue": "joinville"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Juazeiro",
    "ParameterValue": "juazeiro"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Leopoldina",
    "ParameterValue": "leopoldina"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Limeira",
    "ParameterValue": "limeira"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Linhares",
    "ParameterValue": "linhares"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Londrina",
    "ParameterValue": "londrina"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Manaus",
    "ParameterValue": "manaus"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Maracaju",
    "ParameterValue": "maracaju"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Mineiros",
    "ParameterValue": "mineiros"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Mossoro",
    "ParameterValue": "mossoro"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Natal",
    "ParameterValue": "natal"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Niquelandia",
    "ParameterValue": "niquelandia"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Olinda",
    "ParameterValue": "olinda"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Osorio",
    "ParameterValue": "osorio"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Palmas",
    "ParameterValue": "palmas"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Paracatu",
    "ParameterValue": "paracatu"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Paracuru",
    "ParameterValue": "paracuru"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Paragominas",
    "ParameterValue": "paragominas"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Passos",
    "ParameterValue": "passos"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Pelotas",
    "ParameterValue": "pelotas"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Petrolina",
    "ParameterValue": "petrolina"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Pindamonhangaba",
    "ParameterValue": "pindamonhangaba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Piracicaba",
    "ParameterValue": "piracicaba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Quarai",
    "ParameterValue": "quarai"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Recife",
    "ParameterValue": "recife"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Salvador",
    "ParameterValue": "salvador"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Santos",
    "ParameterValue": "santos"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Sinop",
    "ParameterValue": "sinop"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Sobral",
    "ParameterValue": "sobral"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Sorocaba",
    "ParameterValue": "sorocaba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Taua",
    "ParameterValue": "taua"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Teresina",
    "ParameterValue": "teresina"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Tubarao",
    "ParameterValue": "tubarao"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Uba",
    "ParameterValue": "uba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Uberaba",
    "ParameterValue": "uberaba"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Uruguaiana",
    "ParameterValue": "uruguaiana"
  },
  {
    "Country": "BR",
    "CountryName": "Brazil",
    "City": "Vilhena",
    "ParameterValue": "vilhena"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Blagoevgrad",
    "ParameterValue": "blagoevgrad"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Burgas",
    "ParameterValue": "burgas"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Kardzhali",
    "ParameterValue": "kardzhali"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Pazardzhik",
    "ParameterValue": "pazardzhik"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Pernik",
    "ParameterValue": "pernik"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Pleven",
    "ParameterValue": "pleven"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Plovdiv",
    "ParameterValue": "plovdiv"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Shumen",
    "ParameterValue": "shumen"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Silistra",
    "ParameterValue": "silistra"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Sliven",
    "ParameterValue": "sliven"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Sofia",
    "ParameterValue": "sofia"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Varna",
    "ParameterValue": "varna"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Vidin",
    "ParameterValue": "vidin"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Vratsa",
    "ParameterValue": "vratsa"
  },
  {
    "Country": "BG",
    "CountryName": "Bulgaria",
    "City": "Yambol",
    "ParameterValue": "yambol"
  },
  {
    "Country": "BF",
    "CountryName": "Burkina Faso",
    "City": "Ouagadougou",
    "ParameterValue": "ouagadougou"
  },
  {
    "Country": "BI",
    "CountryName": "Burundi",
    "City": "Bubanza",
    "ParameterValue": "bubanza"
  },
  {
    "Country": "BI",
    "CountryName": "Burundi",
    "City": "Bujumbura",
    "ParameterValue": "bujumbura"
  },
  {
    "Country": "BI",
    "CountryName": "Burundi",
    "City": "Kayanza",
    "ParameterValue": "kayanza"
  },
  {
    "Country": "BI",
    "CountryName": "Burundi",
    "City": "Mwaro",
    "ParameterValue": "mwaro"
  },
  {
    "Country": "BI",
    "CountryName": "Burundi",
    "City": "Rutana",
    "ParameterValue": "rutana"
  },
  {
    "Country": "KH",
    "CountryName": "Cambodia",
    "City": "Battambang",
    "ParameterValue": "battambang"
  },
  {
    "Country": "KH",
    "CountryName": "Cambodia",
    "City": "Kampot",
    "ParameterValue": "kampot"
  },
  {
    "Country": "CM",
    "CountryName": "Cameroon",
    "City": "Bamenda",
    "ParameterValue": "bamenda"
  },
  {
    "Country": "CM",
    "CountryName": "Cameroon",
    "City": "Buea",
    "ParameterValue": "buea"
  },
  {
    "Country": "CM",
    "CountryName": "Cameroon",
    "City": "Douala",
    "ParameterValue": "douala"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Abbotsford",
    "ParameterValue": "abbotsford"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Baie-Comeau",
    "ParameterValue": "baie-comeau"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Calgary",
    "ParameterValue": "calgary"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Cornwall",
    "ParameterValue": "cornwall"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Courtenay",
    "ParameterValue": "courtenay"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Edmonton",
    "ParameterValue": "edmonton"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Edmundston",
    "ParameterValue": "edmundston"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Hamilton",
    "ParameterValue": "hamilton"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Kitchener",
    "ParameterValue": "kitchener"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Moncton",
    "ParameterValue": "moncton"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Montreal",
    "ParameterValue": "montreal"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Nanaimo",
    "ParameterValue": "nanaimo"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Oshawa",
    "ParameterValue": "oshawa"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Ottawa",
    "ParameterValue": "ottawa"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Penticton",
    "ParameterValue": "penticton"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Saskatoon",
    "ParameterValue": "saskatoon"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Sherbrooke",
    "ParameterValue": "sherbrooke"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Toronto",
    "ParameterValue": "toronto"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Vancouver",
    "ParameterValue": "vancouver"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Victoria",
    "ParameterValue": "victoria"
  },
  {
    "Country": "CA",
    "CountryName": "Canada",
    "City": "Winnipeg",
    "ParameterValue": "winnipeg"
  },
  {
    "Country": "CF",
    "CountryName": "Central African Republic",
    "City": "Obo",
    "ParameterValue": "obo"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Angol",
    "ParameterValue": "angol"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Antofagasta",
    "ParameterValue": "antofagasta"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Arica",
    "ParameterValue": "arica"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Calama",
    "ParameterValue": "calama"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Chillan",
    "ParameterValue": "chillan"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Coquimbo",
    "ParameterValue": "coquimbo"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Coronel",
    "ParameterValue": "coronel"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Iquique",
    "ParameterValue": "iquique"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Osorno",
    "ParameterValue": "osorno"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Ovalle",
    "ParameterValue": "ovalle"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Rancagua",
    "ParameterValue": "rancagua"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Santiago",
    "ParameterValue": "santiago"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Talca",
    "ParameterValue": "talca"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Talcahuano",
    "ParameterValue": "talcahuano"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Temuco",
    "ParameterValue": "temuco"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Valdivia",
    "ParameterValue": "valdivia"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Vallenar",
    "ParameterValue": "vallenar"
  },
  {
    "Country": "CL",
    "CountryName": "Chile",
    "City": "Vicuna",
    "ParameterValue": "vicuna"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Ankang",
    "ParameterValue": "ankang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Anqing",
    "ParameterValue": "anqing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Anshan",
    "ParameterValue": "anshan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Anyang",
    "ParameterValue": "anyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Baishan",
    "ParameterValue": "baishan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Baoding",
    "ParameterValue": "baoding"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Baotou",
    "ParameterValue": "baotou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Beihai",
    "ParameterValue": "beihai"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Beijing",
    "ParameterValue": "beijing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Beipiao",
    "ParameterValue": "beipiao"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Bengbu",
    "ParameterValue": "bengbu"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Cangzhou",
    "ParameterValue": "cangzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Changchun",
    "ParameterValue": "changchun"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Changde",
    "ParameterValue": "changde"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Changsha",
    "ParameterValue": "changsha"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Changzhi",
    "ParameterValue": "changzhi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Changzhou",
    "ParameterValue": "changzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Chaoyang",
    "ParameterValue": "chaoyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Chaozhou",
    "ParameterValue": "chaozhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Chengdu",
    "ParameterValue": "chengdu"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Chenzhou",
    "ParameterValue": "chenzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Chifeng",
    "ParameterValue": "chifeng"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Chongqing",
    "ParameterValue": "chongqing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Dali",
    "ParameterValue": "dali"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Dalian",
    "ParameterValue": "dalian"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Dandong",
    "ParameterValue": "dandong"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Daqing",
    "ParameterValue": "daqing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Datong",
    "ParameterValue": "datong"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Deyang",
    "ParameterValue": "deyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Dezhou",
    "ParameterValue": "dezhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Dongguan",
    "ParameterValue": "dongguan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Foshan",
    "ParameterValue": "foshan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Fushun",
    "ParameterValue": "fushun"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Fuxin",
    "ParameterValue": "fuxin"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Fuyang",
    "ParameterValue": "fuyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Fuyu",
    "ParameterValue": "fuyu"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Fuzhou",
    "ParameterValue": "fuzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Ganzhou",
    "ParameterValue": "ganzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Guangyuan",
    "ParameterValue": "guangyuan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Guangzhou",
    "ParameterValue": "guangzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Guilin",
    "ParameterValue": "guilin"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Guiyang",
    "ParameterValue": "guiyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Haikou",
    "ParameterValue": "haikou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Handan",
    "ParameterValue": "handan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Hangzhou",
    "ParameterValue": "hangzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Hanzhong",
    "ParameterValue": "hanzhong"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Harbin",
    "ParameterValue": "harbin"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Hefei",
    "ParameterValue": "hefei"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Heihe",
    "ParameterValue": "heihe"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Hengshui",
    "ParameterValue": "hengshui"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Hengyang",
    "ParameterValue": "hengyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Heyuan",
    "ParameterValue": "heyuan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Heze",
    "ParameterValue": "heze"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Hohhot",
    "ParameterValue": "hohhot"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Huaibei",
    "ParameterValue": "huaibei"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Huainan",
    "ParameterValue": "huainan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Huangshi",
    "ParameterValue": "huangshi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Huizhou",
    "ParameterValue": "huizhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Huzhou",
    "ParameterValue": "huzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jiamusi",
    "ParameterValue": "jiamusi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jiangmen",
    "ParameterValue": "jiangmen"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jiaozuo",
    "ParameterValue": "jiaozuo"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jiaxing",
    "ParameterValue": "jiaxing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jieshou",
    "ParameterValue": "jieshou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jinan",
    "ParameterValue": "jinan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jingmen",
    "ParameterValue": "jingmen"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jinhua",
    "ParameterValue": "jinhua"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jining",
    "ParameterValue": "jining"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jinzhou",
    "ParameterValue": "jinzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jiujiang",
    "ParameterValue": "jiujiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Jixi",
    "ParameterValue": "jixi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Kaifeng",
    "ParameterValue": "kaifeng"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Kunming",
    "ParameterValue": "kunming"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Laiwu",
    "ParameterValue": "laiwu"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Langfang",
    "ParameterValue": "langfang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Lanzhou",
    "ParameterValue": "lanzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Leshan",
    "ParameterValue": "leshan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Lhasa",
    "ParameterValue": "lhasa"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Liaocheng",
    "ParameterValue": "liaocheng"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Liaoyang",
    "ParameterValue": "liaoyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Lijiang",
    "ParameterValue": "lijiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Linfen",
    "ParameterValue": "linfen"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Linjiang",
    "ParameterValue": "linjiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Linqing",
    "ParameterValue": "linqing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Linyi",
    "ParameterValue": "linyi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Lishui",
    "ParameterValue": "lishui"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Liuzhou",
    "ParameterValue": "liuzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Luohe",
    "ParameterValue": "luohe"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Luoyang",
    "ParameterValue": "luoyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Luzhou",
    "ParameterValue": "luzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Maoming",
    "ParameterValue": "maoming"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Meizhou",
    "ParameterValue": "meizhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Mianyang",
    "ParameterValue": "mianyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Mishan",
    "ParameterValue": "mishan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Nanchang",
    "ParameterValue": "nanchang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Nanchong",
    "ParameterValue": "nanchong"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Nanjing",
    "ParameterValue": "nanjing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Nanning",
    "ParameterValue": "nanning"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Nanping",
    "ParameterValue": "nanping"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Nantong",
    "ParameterValue": "nantong"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Nanyang",
    "ParameterValue": "nanyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Neijiang",
    "ParameterValue": "neijiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Ningbo",
    "ParameterValue": "ningbo"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Pingdingshan",
    "ParameterValue": "pingdingshan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Pingdu",
    "ParameterValue": "pingdu"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Pingliang",
    "ParameterValue": "pingliang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Pingxiang",
    "ParameterValue": "pingxiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Putian",
    "ParameterValue": "putian"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Qingdao",
    "ParameterValue": "qingdao"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Qinhuangdao",
    "ParameterValue": "qinhuangdao"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Qinzhou",
    "ParameterValue": "qinzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Qiqihar",
    "ParameterValue": "qiqihar"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Quanzhou",
    "ParameterValue": "quanzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Quzhou",
    "ParameterValue": "quzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Rizhao",
    "ParameterValue": "rizhao"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Sanya",
    "ParameterValue": "sanya"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shanghai",
    "ParameterValue": "shanghai"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shangrao",
    "ParameterValue": "shangrao"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shantou",
    "ParameterValue": "shantou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shaoguan",
    "ParameterValue": "shaoguan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shaoxing",
    "ParameterValue": "shaoxing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shenzhen",
    "ParameterValue": "shenzhen"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shiyan",
    "ParameterValue": "shiyan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shizuishan",
    "ParameterValue": "shizuishan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shuangyashan",
    "ParameterValue": "shuangyashan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Shuozhou",
    "ParameterValue": "shuozhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Siping",
    "ParameterValue": "siping"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Suihua",
    "ParameterValue": "suihua"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Suining",
    "ParameterValue": "suining"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Suzhou",
    "ParameterValue": "suzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Taiyuan",
    "ParameterValue": "taiyuan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Taizhou",
    "ParameterValue": "taizhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Tianjin",
    "ParameterValue": "tianjin"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Tianshui",
    "ParameterValue": "tianshui"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Tieling",
    "ParameterValue": "tieling"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Tongliao",
    "ParameterValue": "tongliao"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Tongren",
    "ParameterValue": "tongren"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Weifang",
    "ParameterValue": "weifang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Weihai",
    "ParameterValue": "weihai"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Weinan",
    "ParameterValue": "weinan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Wenzhou",
    "ParameterValue": "wenzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Wuhan",
    "ParameterValue": "wuhan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Wuhu",
    "ParameterValue": "wuhu"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Wuwei",
    "ParameterValue": "wuwei"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Wuxi",
    "ParameterValue": "wuxi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Wuzhou",
    "ParameterValue": "wuzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xiamen",
    "ParameterValue": "xiamen"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xiangfan",
    "ParameterValue": "xiangfan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xiangtan",
    "ParameterValue": "xiangtan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xianyang",
    "ParameterValue": "xianyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xichang",
    "ParameterValue": "xichang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xingyi",
    "ParameterValue": "xingyi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xining",
    "ParameterValue": "xining"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xinxiang",
    "ParameterValue": "xinxiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xinyang",
    "ParameterValue": "xinyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xinyi",
    "ParameterValue": "xinyi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xinyu",
    "ParameterValue": "xinyu"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xinzhou",
    "ParameterValue": "xinzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xuanzhou",
    "ParameterValue": "xuanzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xuchang",
    "ParameterValue": "xuchang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Xuzhou",
    "ParameterValue": "xuzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yancheng",
    "ParameterValue": "yancheng"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yangjiang",
    "ParameterValue": "yangjiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yangquan",
    "ParameterValue": "yangquan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yangzhou",
    "ParameterValue": "yangzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yantai",
    "ParameterValue": "yantai"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yibin",
    "ParameterValue": "yibin"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yichang",
    "ParameterValue": "yichang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yichun",
    "ParameterValue": "yichun"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yinchuan",
    "ParameterValue": "yinchuan"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yining",
    "ParameterValue": "yining"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yiyang",
    "ParameterValue": "yiyang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yongzhou",
    "ParameterValue": "yongzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yulin",
    "ParameterValue": "yulin"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Yuxi",
    "ParameterValue": "yuxi"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zaozhuang",
    "ParameterValue": "zaozhuang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhangjiakou",
    "ParameterValue": "zhangjiakou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhangzhou",
    "ParameterValue": "zhangzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhanjiang",
    "ParameterValue": "zhanjiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhaoqing",
    "ParameterValue": "zhaoqing"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhengzhou",
    "ParameterValue": "zhengzhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhenjiang",
    "ParameterValue": "zhenjiang"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhoukou",
    "ParameterValue": "zhoukou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhuhai",
    "ParameterValue": "zhuhai"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zhuozhou",
    "ParameterValue": "zhuozhou"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zibo",
    "ParameterValue": "zibo"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zigong",
    "ParameterValue": "zigong"
  },
  {
    "Country": "CN",
    "CountryName": "China",
    "City": "Zunyi",
    "ParameterValue": "zunyi"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Armenia",
    "ParameterValue": "armenia"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Barrancabermeja",
    "ParameterValue": "barrancabermeja"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Barranquilla",
    "ParameterValue": "barranquilla"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Bello",
    "ParameterValue": "bello"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Bucaramanga",
    "ParameterValue": "bucaramanga"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Buenaventura",
    "ParameterValue": "buenaventura"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Cartagena",
    "ParameterValue": "cartagena"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Cartago",
    "ParameterValue": "cartago"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Cienaga",
    "ParameterValue": "cienaga"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Duitama",
    "ParameterValue": "duitama"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Ibague",
    "ParameterValue": "ibague"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Ipiales",
    "ParameterValue": "ipiales"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Manizales",
    "ParameterValue": "manizales"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Neiva",
    "ParameterValue": "neiva"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Pasto",
    "ParameterValue": "pasto"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Pereira",
    "ParameterValue": "pereira"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Popayan",
    "ParameterValue": "popayan"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Riohacha",
    "ParameterValue": "riohacha"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Sincelejo",
    "ParameterValue": "sincelejo"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Socorro",
    "ParameterValue": "socorro"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Sogamoso",
    "ParameterValue": "sogamoso"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Tame",
    "ParameterValue": "tame"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Tunja",
    "ParameterValue": "tunja"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Valledupar",
    "ParameterValue": "valledupar"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Villavicencio",
    "ParameterValue": "villavicencio"
  },
  {
    "Country": "CO",
    "CountryName": "Colombia",
    "City": "Yopal",
    "ParameterValue": "yopal"
  },
  {
    "Country": "KM",
    "CountryName": "Comoros",
    "City": "Moroni",
    "ParameterValue": "moroni"
  },
  {
    "Country": "CG",
    "CountryName": "Congo (Brazzaville)",
    "City": "Djambala",
    "ParameterValue": "djambala"
  },
  {
    "Country": "CD",
    "CountryName": "Congo, (Kinshasa)",
    "City": "Kinshasa",
    "ParameterValue": "kinshasa"
  },
  {
    "Country": "CD",
    "CountryName": "Congo, (Kinshasa)",
    "City": "Yangambi",
    "ParameterValue": "yangambi"
  },
  {
    "Country": "CR",
    "CountryName": "Costa Rica",
    "City": "Alajuela",
    "ParameterValue": "alajuela"
  },
  {
    "Country": "CR",
    "CountryName": "Costa Rica",
    "City": "Heredia",
    "ParameterValue": "heredia"
  },
  {
    "Country": "CR",
    "CountryName": "Costa Rica",
    "City": "Liberia",
    "ParameterValue": "liberia"
  },
  {
    "Country": "CR",
    "CountryName": "Costa Rica",
    "City": "Puntarenas",
    "ParameterValue": "puntarenas"
  },
  {
    "Country": "CR",
    "CountryName": "Costa Rica",
    "City": "Quesada",
    "ParameterValue": "quesada"
  },
  {
    "Country": "CI",
    "CountryName": "Côte d'Ivoire",
    "City": "Abidjan",
    "ParameterValue": "abidjan"
  },
  {
    "Country": "CI",
    "CountryName": "Côte d'Ivoire",
    "City": "San-Pedro",
    "ParameterValue": "san-pedro"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Bjelovar",
    "ParameterValue": "bjelovar"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Dubrovnik",
    "ParameterValue": "dubrovnik"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Koprivnica",
    "ParameterValue": "koprivnica"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Osijek",
    "ParameterValue": "osijek"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Pula",
    "ParameterValue": "pula"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Rijeka",
    "ParameterValue": "rijeka"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Sisak",
    "ParameterValue": "sisak"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Split",
    "ParameterValue": "split"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Virovitica",
    "ParameterValue": "virovitica"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Vukovar",
    "ParameterValue": "vukovar"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Zadar",
    "ParameterValue": "zadar"
  },
  {
    "Country": "HR",
    "CountryName": "Croatia",
    "City": "Zagreb",
    "ParameterValue": "zagreb"
  },
  {
    "Country": "CW",
    "CountryName": "Curaçao",
    "City": "Willemstad",
    "ParameterValue": "willemstad"
  },
  {
    "Country": "CY",
    "CountryName": "Cyprus",
    "City": "Nicosia",
    "ParameterValue": "nicosia"
  },
  {
    "Country": "CZ",
    "CountryName": "Czech Republic",
    "City": "Brno",
    "ParameterValue": "brno"
  },
  {
    "Country": "CZ",
    "CountryName": "Czech Republic",
    "City": "Jihlava",
    "ParameterValue": "jihlava"
  },
  {
    "Country": "CZ",
    "CountryName": "Czech Republic",
    "City": "Liberec",
    "ParameterValue": "liberec"
  },
  {
    "Country": "CZ",
    "CountryName": "Czech Republic",
    "City": "Olomouc",
    "ParameterValue": "olomouc"
  },
  {
    "Country": "CZ",
    "CountryName": "Czech Republic",
    "City": "Ostrava",
    "ParameterValue": "ostrava"
  },
  {
    "Country": "CZ",
    "CountryName": "Czech Republic",
    "City": "Pardubice",
    "ParameterValue": "pardubice"
  },
  {
    "Country": "CZ",
    "CountryName": "Czech Republic",
    "City": "Prague",
    "ParameterValue": "prague"
  },
  {
    "Country": "DM",
    "CountryName": "Dominica",
    "City": "Roseau",
    "ParameterValue": "roseau"
  },
  {
    "Country": "DO",
    "CountryName": "Dominican Republic",
    "City": "Azua",
    "ParameterValue": "azua"
  },
  {
    "Country": "DO",
    "CountryName": "Dominican Republic",
    "City": "Bavaro",
    "ParameterValue": "bavaro"
  },
  {
    "Country": "DO",
    "CountryName": "Dominican Republic",
    "City": "Bonao",
    "ParameterValue": "bonao"
  },
  {
    "Country": "DO",
    "CountryName": "Dominican Republic",
    "City": "Cotui",
    "ParameterValue": "cotui"
  },
  {
    "Country": "DO",
    "CountryName": "Dominican Republic",
    "City": "Dajabon",
    "ParameterValue": "dajabon"
  },
  {
    "Country": "DO",
    "CountryName": "Dominican Republic",
    "City": "Moca",
    "ParameterValue": "moca"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Ambato",
    "ParameterValue": "ambato"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Babahoyo",
    "ParameterValue": "babahoyo"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Cayambe",
    "ParameterValue": "cayambe"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Cuenca",
    "ParameterValue": "cuenca"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Esmeraldas",
    "ParameterValue": "esmeraldas"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Guayaquil",
    "ParameterValue": "guayaquil"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Ibarra",
    "ParameterValue": "ibarra"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Jipijapa",
    "ParameterValue": "jipijapa"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Latacunga",
    "ParameterValue": "latacunga"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Loja",
    "ParameterValue": "loja"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Machala",
    "ParameterValue": "machala"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Manta",
    "ParameterValue": "manta"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Milagro",
    "ParameterValue": "milagro"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Portoviejo",
    "ParameterValue": "portoviejo"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Puyo",
    "ParameterValue": "puyo"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Quito",
    "ParameterValue": "quito"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Riobamba",
    "ParameterValue": "riobamba"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Salinas",
    "ParameterValue": "salinas"
  },
  {
    "Country": "EC",
    "CountryName": "Ecuador",
    "City": "Tena",
    "ParameterValue": "tena"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Aswan",
    "ParameterValue": "aswan"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Cairo",
    "ParameterValue": "cairo"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Damanhur",
    "ParameterValue": "damanhur"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Hurghada",
    "ParameterValue": "hurghada"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Ismailia",
    "ParameterValue": "ismailia"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Luxor",
    "ParameterValue": "luxor"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Sohag",
    "ParameterValue": "sohag"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Suez",
    "ParameterValue": "suez"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Tanta",
    "ParameterValue": "tanta"
  },
  {
    "Country": "EG",
    "CountryName": "Egypt",
    "City": "Zagazig",
    "ParameterValue": "zagazig"
  },
  {
    "Country": "SV",
    "CountryName": "El Salvador",
    "City": "Ahuachapan",
    "ParameterValue": "ahuachapan"
  },
  {
    "Country": "SV",
    "CountryName": "El Salvador",
    "City": "Chalatenango",
    "ParameterValue": "chalatenango"
  },
  {
    "Country": "SV",
    "CountryName": "El Salvador",
    "City": "Sonsonate",
    "ParameterValue": "sonsonate"
  },
  {
    "Country": "SV",
    "CountryName": "El Salvador",
    "City": "Usulutan",
    "ParameterValue": "usulutan"
  },
  {
    "Country": "GQ",
    "CountryName": "Equatorial Guinea",
    "City": "Malabo",
    "ParameterValue": "malabo"
  },
  {
    "Country": "EE",
    "CountryName": "Estonia",
    "City": "Haapsalu",
    "ParameterValue": "haapsalu"
  },
  {
    "Country": "EE",
    "CountryName": "Estonia",
    "City": "Tallinn",
    "ParameterValue": "tallinn"
  },
  {
    "Country": "FJ",
    "CountryName": "Fiji",
    "City": "Suva",
    "ParameterValue": "suva"
  },
  {
    "Country": "FI",
    "CountryName": "Finland",
    "City": "Helsinki",
    "ParameterValue": "helsinki"
  },
  {
    "Country": "FI",
    "CountryName": "Finland",
    "City": "Lappeenranta",
    "ParameterValue": "lappeenranta"
  },
  {
    "Country": "FI",
    "CountryName": "Finland",
    "City": "Pori",
    "ParameterValue": "pori"
  },
  {
    "Country": "FI",
    "CountryName": "Finland",
    "City": "Tampere",
    "ParameterValue": "tampere"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Amiens",
    "ParameterValue": "amiens"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Angers",
    "ParameterValue": "angers"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Arras",
    "ParameterValue": "arras"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Auxerre",
    "ParameterValue": "auxerre"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Bordeaux",
    "ParameterValue": "bordeaux"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Bourges",
    "ParameterValue": "bourges"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Brest",
    "ParameterValue": "brest"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Caen",
    "ParameterValue": "caen"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Calais",
    "ParameterValue": "calais"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Clermont-Ferrand",
    "ParameterValue": "clermont-ferrand"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Dijon",
    "ParameterValue": "dijon"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Grenoble",
    "ParameterValue": "grenoble"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Lille",
    "ParameterValue": "lille"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Limoges",
    "ParameterValue": "limoges"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Lorient",
    "ParameterValue": "lorient"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Lyon",
    "ParameterValue": "lyon"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Marseille",
    "ParameterValue": "marseille"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Melun",
    "ParameterValue": "melun"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Metz",
    "ParameterValue": "metz"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Montpellier",
    "ParameterValue": "montpellier"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Mulhouse",
    "ParameterValue": "mulhouse"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Nancy",
    "ParameterValue": "nancy"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Nantes",
    "ParameterValue": "nantes"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Nevers",
    "ParameterValue": "nevers"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Nice",
    "ParameterValue": "nice"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Paris",
    "ParameterValue": "paris"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Reims",
    "ParameterValue": "reims"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Rennes",
    "ParameterValue": "rennes"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Roanne",
    "ParameterValue": "roanne"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Rouen",
    "ParameterValue": "rouen"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Strasbourg",
    "ParameterValue": "strasbourg"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Toulon",
    "ParameterValue": "toulon"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Toulouse",
    "ParameterValue": "toulouse"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Tours",
    "ParameterValue": "tours"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Troyes",
    "ParameterValue": "troyes"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Versailles",
    "ParameterValue": "versailles"
  },
  {
    "Country": "FR",
    "CountryName": "France",
    "City": "Vichy",
    "ParameterValue": "vichy"
  },
  {
    "Country": "GF",
    "CountryName": "French Guiana",
    "City": "Cayenne",
    "ParameterValue": "cayenne"
  },
  {
    "Country": "PF",
    "CountryName": "French Polynesia",
    "City": "Papeete",
    "ParameterValue": "papeete"
  },
  {
    "Country": "GA",
    "CountryName": "Gabon",
    "City": "Libreville",
    "ParameterValue": "libreville"
  },
  {
    "Country": "GM",
    "CountryName": "Gambia",
    "City": "Banjul",
    "ParameterValue": "banjul"
  },
  {
    "Country": "GE",
    "CountryName": "Georgia",
    "City": "Batumi",
    "ParameterValue": "batumi"
  },
  {
    "Country": "GE",
    "CountryName": "Georgia",
    "City": "Kutaisi",
    "ParameterValue": "kutaisi"
  },
  {
    "Country": "GE",
    "CountryName": "Georgia",
    "City": "Tbilisi",
    "ParameterValue": "tbilisi"
  },
  {
    "Country": "GE",
    "CountryName": "Georgia",
    "City": "Telavi",
    "ParameterValue": "telavi"
  },
  {
    "Country": "GE",
    "CountryName": "Georgia",
    "City": "Zugdidi",
    "ParameterValue": "zugdidi"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Augsburg",
    "ParameterValue": "augsburg"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Berlin",
    "ParameterValue": "berlin"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Bielefeld",
    "ParameterValue": "bielefeld"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Bonn",
    "ParameterValue": "bonn"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Bremen",
    "ParameterValue": "bremen"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Bremerhaven",
    "ParameterValue": "bremerhaven"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Chemnitz",
    "ParameterValue": "chemnitz"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Cologne",
    "ParameterValue": "cologne"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Dortmund",
    "ParameterValue": "dortmund"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Dresden",
    "ParameterValue": "dresden"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Duisburg",
    "ParameterValue": "duisburg"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Essen",
    "ParameterValue": "essen"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Freiburg",
    "ParameterValue": "freiburg"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Gera",
    "ParameterValue": "gera"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Hamburg",
    "ParameterValue": "hamburg"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Heidelberg",
    "ParameterValue": "heidelberg"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Karlsruhe",
    "ParameterValue": "karlsruhe"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Kassel",
    "ParameterValue": "kassel"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Kiel",
    "ParameterValue": "kiel"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Leipzig",
    "ParameterValue": "leipzig"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Mainz",
    "ParameterValue": "mainz"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Mannheim",
    "ParameterValue": "mannheim"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Munich",
    "ParameterValue": "munich"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Oldenburg",
    "ParameterValue": "oldenburg"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Stuttgart",
    "ParameterValue": "stuttgart"
  },
  {
    "Country": "DE",
    "CountryName": "Germany",
    "City": "Wuppertal",
    "ParameterValue": "wuppertal"
  },
  {
    "Country": "GH",
    "CountryName": "Ghana",
    "City": "Accra",
    "ParameterValue": "accra"
  },
  {
    "Country": "GH",
    "CountryName": "Ghana",
    "City": "Tamale",
    "ParameterValue": "tamale"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Athens",
    "ParameterValue": "athens"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Kalamata",
    "ParameterValue": "kalamata"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Katerini",
    "ParameterValue": "katerini"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Kavala",
    "ParameterValue": "kavala"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Larissa",
    "ParameterValue": "larissa"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Thessaloniki",
    "ParameterValue": "thessaloniki"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Tripoli",
    "ParameterValue": "tripoli"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Volos",
    "ParameterValue": "volos"
  },
  {
    "Country": "GR",
    "CountryName": "Greece",
    "City": "Xanthi",
    "ParameterValue": "xanthi"
  },
  {
    "Country": "GT",
    "CountryName": "Guatemala",
    "City": "Chiquimula",
    "ParameterValue": "chiquimula"
  },
  {
    "Country": "GT",
    "CountryName": "Guatemala",
    "City": "Coban",
    "ParameterValue": "coban"
  },
  {
    "Country": "GT",
    "CountryName": "Guatemala",
    "City": "Jalapa",
    "ParameterValue": "jalapa"
  },
  {
    "Country": "GT",
    "CountryName": "Guatemala",
    "City": "Quetzaltenango",
    "ParameterValue": "quetzaltenango"
  },
  {
    "Country": "GT",
    "CountryName": "Guatemala",
    "City": "Zacapa",
    "ParameterValue": "zacapa"
  },
  {
    "Country": "GN",
    "CountryName": "Guinea",
    "City": "Conakry",
    "ParameterValue": "conakry"
  },
  {
    "Country": "HT",
    "CountryName": "Haiti",
    "City": "Port-au-Prince",
    "ParameterValue": "port-au-prince"
  },
  {
    "Country": "HN",
    "CountryName": "Honduras",
    "City": "Comayagua",
    "ParameterValue": "comayagua"
  },
  {
    "Country": "HN",
    "CountryName": "Honduras",
    "City": "Tegucigalpa",
    "ParameterValue": "tegucigalpa"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Budapest",
    "ParameterValue": "budapest"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Debrecen",
    "ParameterValue": "debrecen"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Miskolc",
    "ParameterValue": "miskolc"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Nagykanizsa",
    "ParameterValue": "nagykanizsa"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Nyiregyhaza",
    "ParameterValue": "nyiregyhaza"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Sopron",
    "ParameterValue": "sopron"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Szeged",
    "ParameterValue": "szeged"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Szolnok",
    "ParameterValue": "szolnok"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Szombathely",
    "ParameterValue": "szombathely"
  },
  {
    "Country": "HU",
    "CountryName": "Hungary",
    "City": "Zalaegerszeg",
    "ParameterValue": "zalaegerszeg"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ahmedabad",
    "ParameterValue": "ahmedabad"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ahmednagar",
    "ParameterValue": "ahmednagar"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ajmer",
    "ParameterValue": "ajmer"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Akola",
    "ParameterValue": "akola"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Alappuzha",
    "ParameterValue": "alappuzha"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Allahabad",
    "ParameterValue": "allahabad"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ambala",
    "ParameterValue": "ambala"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Amritsar",
    "ParameterValue": "amritsar"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Aurangabad",
    "ParameterValue": "aurangabad"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Barddhaman",
    "ParameterValue": "barddhaman"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Bareilly",
    "ParameterValue": "bareilly"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Belgaum",
    "ParameterValue": "belgaum"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Bhilwara",
    "ParameterValue": "bhilwara"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Bhiwandi",
    "ParameterValue": "bhiwandi"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Bhopal",
    "ParameterValue": "bhopal"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Chandigarh",
    "ParameterValue": "chandigarh"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Chennai",
    "ParameterValue": "chennai"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Coimbatore",
    "ParameterValue": "coimbatore"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Cuttack",
    "ParameterValue": "cuttack"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Delhi",
    "ParameterValue": "delhi"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Dhanbad",
    "ParameterValue": "dhanbad"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Faridabad",
    "ParameterValue": "faridabad"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Gandhinagar",
    "ParameterValue": "gandhinagar"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ghaziabad",
    "ParameterValue": "ghaziabad"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Gulbarga",
    "ParameterValue": "gulbarga"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Guntur",
    "ParameterValue": "guntur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Gurgaon",
    "ParameterValue": "gurgaon"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Guwahati",
    "ParameterValue": "guwahati"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Hapur",
    "ParameterValue": "hapur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Hoshiarpur",
    "ParameterValue": "hoshiarpur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Hubli",
    "ParameterValue": "hubli"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Hyderabad",
    "ParameterValue": "hyderabad"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Imphal",
    "ParameterValue": "imphal"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Indore",
    "ParameterValue": "indore"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Jaipur",
    "ParameterValue": "jaipur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Jammu",
    "ParameterValue": "jammu"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Jhansi",
    "ParameterValue": "jhansi"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Jodhpur",
    "ParameterValue": "jodhpur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kalyan",
    "ParameterValue": "kalyan"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kanpur",
    "ParameterValue": "kanpur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kohima",
    "ParameterValue": "kohima"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kolhapur",
    "ParameterValue": "kolhapur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kolkata",
    "ParameterValue": "kolkata"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kollam",
    "ParameterValue": "kollam"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kota",
    "ParameterValue": "kota"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kozhikode",
    "ParameterValue": "kozhikode"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Kumbakonam",
    "ParameterValue": "kumbakonam"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Lucknow",
    "ParameterValue": "lucknow"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ludhiana",
    "ParameterValue": "ludhiana"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Madurai",
    "ParameterValue": "madurai"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Mangalore",
    "ParameterValue": "mangalore"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Medinipur",
    "ParameterValue": "medinipur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Meerut",
    "ParameterValue": "meerut"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Mumbai",
    "ParameterValue": "mumbai"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Muzaffarpur",
    "ParameterValue": "muzaffarpur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Mysore",
    "ParameterValue": "mysore"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Nagercoil",
    "ParameterValue": "nagercoil"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Nagpur",
    "ParameterValue": "nagpur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Nellore",
    "ParameterValue": "nellore"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ongole",
    "ParameterValue": "ongole"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Panipat",
    "ParameterValue": "panipat"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Patna",
    "ParameterValue": "patna"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Pune",
    "ParameterValue": "pune"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Raichur",
    "ParameterValue": "raichur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Raipur",
    "ParameterValue": "raipur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Rajahmundry",
    "ParameterValue": "rajahmundry"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ranchi",
    "ParameterValue": "ranchi"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Raurkela",
    "ParameterValue": "raurkela"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Saharanpur",
    "ParameterValue": "saharanpur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Siliguri",
    "ParameterValue": "siliguri"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Silvassa",
    "ParameterValue": "silvassa"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Sonipat",
    "ParameterValue": "sonipat"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Thiruvananthapuram",
    "ParameterValue": "thiruvananthapuram"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Tiruppur",
    "ParameterValue": "tiruppur"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Ujjain",
    "ParameterValue": "ujjain"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Vadodara",
    "ParameterValue": "vadodara"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Varanasi",
    "ParameterValue": "varanasi"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Vellore",
    "ParameterValue": "vellore"
  },
  {
    "Country": "IN",
    "CountryName": "India",
    "City": "Vijayawada",
    "ParameterValue": "vijayawada"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Balikpapan",
    "ParameterValue": "balikpapan"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Bandung",
    "ParameterValue": "bandung"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Banyuwangi",
    "ParameterValue": "banyuwangi"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Bekasi",
    "ParameterValue": "bekasi"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Blitar",
    "ParameterValue": "blitar"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Bogor",
    "ParameterValue": "bogor"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Cilacap",
    "ParameterValue": "cilacap"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Cirebon",
    "ParameterValue": "cirebon"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Denpasar",
    "ParameterValue": "denpasar"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Gorontalo",
    "ParameterValue": "gorontalo"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Indramayu",
    "ParameterValue": "indramayu"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Jakarta",
    "ParameterValue": "jakarta"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Jayapura",
    "ParameterValue": "jayapura"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Jember",
    "ParameterValue": "jember"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Kediri",
    "ParameterValue": "kediri"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Kupang",
    "ParameterValue": "kupang"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Magelang",
    "ParameterValue": "magelang"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Majene",
    "ParameterValue": "majene"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Malang",
    "ParameterValue": "malang"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Manado",
    "ParameterValue": "manado"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Manokwari",
    "ParameterValue": "manokwari"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Martapura",
    "ParameterValue": "martapura"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Mataram",
    "ParameterValue": "mataram"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Medan",
    "ParameterValue": "medan"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Padang",
    "ParameterValue": "padang"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Palangkaraya",
    "ParameterValue": "palangkaraya"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Palembang",
    "ParameterValue": "palembang"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Palopo",
    "ParameterValue": "palopo"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Palu",
    "ParameterValue": "palu"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Parepare",
    "ParameterValue": "parepare"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Pasuruan",
    "ParameterValue": "pasuruan"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Pati",
    "ParameterValue": "pati"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Pekanbaru",
    "ParameterValue": "pekanbaru"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Pontianak",
    "ParameterValue": "pontianak"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Salatiga",
    "ParameterValue": "salatiga"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Samarinda",
    "ParameterValue": "samarinda"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Semarang",
    "ParameterValue": "semarang"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Serang",
    "ParameterValue": "serang"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Sorong",
    "ParameterValue": "sorong"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Surabaya",
    "ParameterValue": "surabaya"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Surakarta",
    "ParameterValue": "surakarta"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Tarakan",
    "ParameterValue": "tarakan"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Tasikmalaya",
    "ParameterValue": "tasikmalaya"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Tegal",
    "ParameterValue": "tegal"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Ternate",
    "ParameterValue": "ternate"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Timika",
    "ParameterValue": "timika"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Tuban",
    "ParameterValue": "tuban"
  },
  {
    "Country": "ID",
    "CountryName": "Indonesia",
    "City": "Yogyakarta",
    "ParameterValue": "yogyakarta"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Arak",
    "ParameterValue": "arak"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Ilam",
    "ParameterValue": "ilam"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Isfahan",
    "ParameterValue": "isfahan"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Mashhad",
    "ParameterValue": "mashhad"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Qom",
    "ParameterValue": "qom"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Rasht",
    "ParameterValue": "rasht"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Tehran",
    "ParameterValue": "tehran"
  },
  {
    "Country": "IR",
    "CountryName": "Iran, Islamic Republic of",
    "City": "Yazd",
    "ParameterValue": "yazd"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Baghdad",
    "ParameterValue": "baghdad"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Baqubah",
    "ParameterValue": "baqubah"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Dahuk",
    "ParameterValue": "dahuk"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Karbala",
    "ParameterValue": "karbala"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Kirkuk",
    "ParameterValue": "kirkuk"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Mosul",
    "ParameterValue": "mosul"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Samarra",
    "ParameterValue": "samarra"
  },
  {
    "Country": "IQ",
    "CountryName": "Iraq",
    "City": "Tikrit",
    "ParameterValue": "tikrit"
  },
  {
    "Country": "IE",
    "CountryName": "Ireland",
    "City": "Drogheda",
    "ParameterValue": "drogheda"
  },
  {
    "Country": "IE",
    "CountryName": "Ireland",
    "City": "Dublin",
    "ParameterValue": "dublin"
  },
  {
    "Country": "IE",
    "CountryName": "Ireland",
    "City": "Galway",
    "ParameterValue": "galway"
  },
  {
    "Country": "IE",
    "CountryName": "Ireland",
    "City": "Limerick",
    "ParameterValue": "limerick"
  },
  {
    "Country": "IE",
    "CountryName": "Ireland",
    "City": "Nenagh",
    "ParameterValue": "nenagh"
  },
  {
    "Country": "IM",
    "CountryName": "Isle of Man",
    "City": "Douglas",
    "ParameterValue": "douglas"
  },
  {
    "Country": "IL",
    "CountryName": "Israel",
    "City": "Haifa",
    "ParameterValue": "haifa"
  },
  {
    "Country": "IL",
    "CountryName": "Israel",
    "City": "Jerusalem",
    "ParameterValue": "jerusalem"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Ancona",
    "ParameterValue": "ancona"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Aosta",
    "ParameterValue": "aosta"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Bari",
    "ParameterValue": "bari"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Bergamo",
    "ParameterValue": "bergamo"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Bologna",
    "ParameterValue": "bologna"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Brindisi",
    "ParameterValue": "brindisi"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Cagliari",
    "ParameterValue": "cagliari"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Caserta",
    "ParameterValue": "caserta"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Catania",
    "ParameterValue": "catania"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Civitavecchia",
    "ParameterValue": "civitavecchia"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Florence",
    "ParameterValue": "florence"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Foggia",
    "ParameterValue": "foggia"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Genoa",
    "ParameterValue": "genoa"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Lecce",
    "ParameterValue": "lecce"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Livorno",
    "ParameterValue": "livorno"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Milan",
    "ParameterValue": "milan"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Modena",
    "ParameterValue": "modena"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Naples",
    "ParameterValue": "naples"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Novara",
    "ParameterValue": "novara"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Palermo",
    "ParameterValue": "palermo"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Pescara",
    "ParameterValue": "pescara"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Ragusa",
    "ParameterValue": "ragusa"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Rome",
    "ParameterValue": "rome"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Salerno",
    "ParameterValue": "salerno"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Sassari",
    "ParameterValue": "sassari"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Taranto",
    "ParameterValue": "taranto"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Trieste",
    "ParameterValue": "trieste"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Turin",
    "ParameterValue": "turin"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Udine",
    "ParameterValue": "udine"
  },
  {
    "Country": "IT",
    "CountryName": "Italy",
    "City": "Verona",
    "ParameterValue": "verona"
  },
  {
    "Country": "JM",
    "CountryName": "Jamaica",
    "City": "Falmouth",
    "ParameterValue": "falmouth"
  },
  {
    "Country": "JM",
    "CountryName": "Jamaica",
    "City": "Kingston",
    "ParameterValue": "kingston"
  },
  {
    "Country": "JM",
    "CountryName": "Jamaica",
    "City": "Lucea",
    "ParameterValue": "lucea"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Chiba",
    "ParameterValue": "chiba"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Fukuoka",
    "ParameterValue": "fukuoka"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Fukushima",
    "ParameterValue": "fukushima"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Hamamatsu",
    "ParameterValue": "hamamatsu"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Kanazawa",
    "ParameterValue": "kanazawa"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Kobe",
    "ParameterValue": "kobe"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Kochi",
    "ParameterValue": "kochi"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Kumamoto",
    "ParameterValue": "kumamoto"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Kyoto",
    "ParameterValue": "kyoto"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Maebashi",
    "ParameterValue": "maebashi"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Matsuyama",
    "ParameterValue": "matsuyama"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Mito",
    "ParameterValue": "mito"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Nagoya",
    "ParameterValue": "nagoya"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Nara",
    "ParameterValue": "nara"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Okayama",
    "ParameterValue": "okayama"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Osaka",
    "ParameterValue": "osaka"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Saitama",
    "ParameterValue": "saitama"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Sapporo",
    "ParameterValue": "sapporo"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Shizuoka",
    "ParameterValue": "shizuoka"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Takamatsu",
    "ParameterValue": "takamatsu"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Tokyo",
    "ParameterValue": "tokyo"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Toyama",
    "ParameterValue": "toyama"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Utsunomiya",
    "ParameterValue": "utsunomiya"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Yamagata",
    "ParameterValue": "yamagata"
  },
  {
    "Country": "JP",
    "CountryName": "Japan",
    "City": "Yokohama",
    "ParameterValue": "yokohama"
  },
  {
    "Country": "JO",
    "CountryName": "Jordan",
    "City": "Amman",
    "ParameterValue": "amman"
  },
  {
    "Country": "JO",
    "CountryName": "Jordan",
    "City": "Irbid",
    "ParameterValue": "irbid"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Almaty",
    "ParameterValue": "almaty"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Atyrau",
    "ParameterValue": "atyrau"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Ekibastuz",
    "ParameterValue": "ekibastuz"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Khromtau",
    "ParameterValue": "khromtau"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Kokshetau",
    "ParameterValue": "kokshetau"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Pavlodar",
    "ParameterValue": "pavlodar"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Ridder",
    "ParameterValue": "ridder"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Semey",
    "ParameterValue": "semey"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Shymkent",
    "ParameterValue": "shymkent"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Taraz",
    "ParameterValue": "taraz"
  },
  {
    "Country": "KZ",
    "CountryName": "Kazakhstan",
    "City": "Temirtau",
    "ParameterValue": "temirtau"
  },
  {
    "Country": "KE",
    "CountryName": "Kenya",
    "City": "Eldoret",
    "ParameterValue": "eldoret"
  },
  {
    "Country": "KE",
    "CountryName": "Kenya",
    "City": "Embu",
    "ParameterValue": "embu"
  },
  {
    "Country": "KE",
    "CountryName": "Kenya",
    "City": "Kajiado",
    "ParameterValue": "kajiado"
  },
  {
    "Country": "KE",
    "CountryName": "Kenya",
    "City": "Kisumu",
    "ParameterValue": "kisumu"
  },
  {
    "Country": "KE",
    "CountryName": "Kenya",
    "City": "Mombasa",
    "ParameterValue": "mombasa"
  },
  {
    "Country": "KE",
    "CountryName": "Kenya",
    "City": "Nairobi",
    "ParameterValue": "nairobi"
  },
  {
    "Country": "KE",
    "CountryName": "Kenya",
    "City": "Nakuru",
    "ParameterValue": "nakuru"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Andong",
    "ParameterValue": "andong"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Busan",
    "ParameterValue": "busan"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Changwon",
    "ParameterValue": "changwon"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Chuncheon",
    "ParameterValue": "chuncheon"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Daegu",
    "ParameterValue": "daegu"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Daejeon",
    "ParameterValue": "daejeon"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Gangneung",
    "ParameterValue": "gangneung"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Gunsan",
    "ParameterValue": "gunsan"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Gwangju",
    "ParameterValue": "gwangju"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Gyeongju",
    "ParameterValue": "gyeongju"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Hongseong",
    "ParameterValue": "hongseong"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Iksan",
    "ParameterValue": "iksan"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Incheon",
    "ParameterValue": "incheon"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Jeonju",
    "ParameterValue": "jeonju"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Mokpo",
    "ParameterValue": "mokpo"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Muan",
    "ParameterValue": "muan"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Pohang",
    "ParameterValue": "pohang"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Sejong",
    "ParameterValue": "sejong"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Seoul",
    "ParameterValue": "seoul"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Sokcho",
    "ParameterValue": "sokcho"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Suwon",
    "ParameterValue": "suwon"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Ulsan",
    "ParameterValue": "ulsan"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Wonju",
    "ParameterValue": "wonju"
  },
  {
    "Country": "KR",
    "CountryName": "Korea (South)",
    "City": "Yeosu",
    "ParameterValue": "yeosu"
  },
  {
    "Country": "KG",
    "CountryName": "Kyrgyzstan",
    "City": "Bishkek",
    "ParameterValue": "bishkek"
  },
  {
    "Country": "KG",
    "CountryName": "Kyrgyzstan",
    "City": "Osh",
    "ParameterValue": "osh"
  },
  {
    "Country": "LA",
    "CountryName": "Lao PDR",
    "City": "Vientiane",
    "ParameterValue": "vientiane"
  },
  {
    "Country": "LV",
    "CountryName": "Latvia",
    "City": "Jelgava",
    "ParameterValue": "jelgava"
  },
  {
    "Country": "LV",
    "CountryName": "Latvia",
    "City": "Riga",
    "ParameterValue": "riga"
  },
  {
    "Country": "LB",
    "CountryName": "Lebanon",
    "City": "Beirut",
    "ParameterValue": "beirut"
  },
  {
    "Country": "LS",
    "CountryName": "Lesotho",
    "City": "Maseru",
    "ParameterValue": "maseru"
  },
  {
    "Country": "LR",
    "CountryName": "Liberia",
    "City": "Monrovia",
    "ParameterValue": "monrovia"
  },
  {
    "Country": "LT",
    "CountryName": "Lithuania",
    "City": "Kaunas",
    "ParameterValue": "kaunas"
  },
  {
    "Country": "LT",
    "CountryName": "Lithuania",
    "City": "Pasvalys",
    "ParameterValue": "pasvalys"
  },
  {
    "Country": "LT",
    "CountryName": "Lithuania",
    "City": "Raseiniai",
    "ParameterValue": "raseiniai"
  },
  {
    "Country": "LT",
    "CountryName": "Lithuania",
    "City": "Vilnius",
    "ParameterValue": "vilnius"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Bitola",
    "ParameterValue": "bitola"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Bogdanci",
    "ParameterValue": "bogdanci"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Debar",
    "ParameterValue": "debar"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Delcevo",
    "ParameterValue": "delcevo"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Gevgelija",
    "ParameterValue": "gevgelija"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Gostivar",
    "ParameterValue": "gostivar"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Kavadarci",
    "ParameterValue": "kavadarci"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Kumanovo",
    "ParameterValue": "kumanovo"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Negotino",
    "ParameterValue": "negotino"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Prilep",
    "ParameterValue": "prilep"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Radovis",
    "ParameterValue": "radovis"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Skopje",
    "ParameterValue": "skopje"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Struga",
    "ParameterValue": "struga"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Strumica",
    "ParameterValue": "strumica"
  },
  {
    "Country": "MK",
    "CountryName": "Macedonia, Republic of",
    "City": "Tetovo",
    "ParameterValue": "tetovo"
  },
  {
    "Country": "MG",
    "CountryName": "Madagascar",
    "City": "Antananarivo",
    "ParameterValue": "antananarivo"
  },
  {
    "Country": "MG",
    "CountryName": "Madagascar",
    "City": "Mahajanga",
    "ParameterValue": "mahajanga"
  },
  {
    "Country": "MG",
    "CountryName": "Madagascar",
    "City": "Toamasina",
    "ParameterValue": "toamasina"
  },
  {
    "Country": "MW",
    "CountryName": "Malawi",
    "City": "Blantyre",
    "ParameterValue": "blantyre"
  },
  {
    "Country": "MW",
    "CountryName": "Malawi",
    "City": "Lilongwe",
    "ParameterValue": "lilongwe"
  },
  {
    "Country": "MY",
    "CountryName": "Malaysia",
    "City": "Butterworth",
    "ParameterValue": "butterworth"
  },
  {
    "Country": "MY",
    "CountryName": "Malaysia",
    "City": "Ipoh",
    "ParameterValue": "ipoh"
  },
  {
    "Country": "MY",
    "CountryName": "Malaysia",
    "City": "Kuantan",
    "ParameterValue": "kuantan"
  },
  {
    "Country": "MY",
    "CountryName": "Malaysia",
    "City": "Kuching",
    "ParameterValue": "kuching"
  },
  {
    "Country": "MY",
    "CountryName": "Malaysia",
    "City": "Malacca",
    "ParameterValue": "malacca"
  },
  {
    "Country": "MY",
    "CountryName": "Malaysia",
    "City": "Seremban",
    "ParameterValue": "seremban"
  },
  {
    "Country": "MY",
    "CountryName": "Malaysia",
    "City": "Sibu",
    "ParameterValue": "sibu"
  },
  {
    "Country": "ML",
    "CountryName": "Mali",
    "City": "Bamako",
    "ParameterValue": "bamako"
  },
  {
    "Country": "ML",
    "CountryName": "Mali",
    "City": "Koulikoro",
    "ParameterValue": "koulikoro"
  },
  {
    "Country": "MT",
    "CountryName": "Malta",
    "City": "Imsida",
    "ParameterValue": "imsida"
  },
  {
    "Country": "MT",
    "CountryName": "Malta",
    "City": "Luqa",
    "ParameterValue": "luqa"
  },
  {
    "Country": "MT",
    "CountryName": "Malta",
    "City": "Naxxar",
    "ParameterValue": "naxxar"
  },
  {
    "Country": "MT",
    "CountryName": "Malta",
    "City": "Paola",
    "ParameterValue": "paola"
  },
  {
    "Country": "MT",
    "CountryName": "Malta",
    "City": "Sliema",
    "ParameterValue": "sliema"
  },
  {
    "Country": "MQ",
    "CountryName": "Martinique",
    "City": "Fort-de-France",
    "ParameterValue": "fort-de-france"
  },
  {
    "Country": "MR",
    "CountryName": "Mauritania",
    "City": "Kiffa",
    "ParameterValue": "kiffa"
  },
  {
    "Country": "MR",
    "CountryName": "Mauritania",
    "City": "Nouakchott",
    "ParameterValue": "nouakchott"
  },
  {
    "Country": "MU",
    "CountryName": "Mauritius",
    "City": "Curepipe",
    "ParameterValue": "curepipe"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Aguascalientes",
    "ParameterValue": "aguascalientes"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Campeche",
    "ParameterValue": "campeche"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Celaya",
    "ParameterValue": "celaya"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Chetumal",
    "ParameterValue": "chetumal"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Chilpancingo",
    "ParameterValue": "chilpancingo"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Coatzacoalcos",
    "ParameterValue": "coatzacoalcos"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Colima",
    "ParameterValue": "colima"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Cuauhtemoc",
    "ParameterValue": "cuauhtemoc"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Cuernavaca",
    "ParameterValue": "cuernavaca"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Durango",
    "ParameterValue": "durango"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Ensenada",
    "ParameterValue": "ensenada"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Fresnillo",
    "ParameterValue": "fresnillo"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Guadalajara",
    "ParameterValue": "guadalajara"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Guamuchil",
    "ParameterValue": "guamuchil"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Guasave",
    "ParameterValue": "guasave"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Guaymas",
    "ParameterValue": "guaymas"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Hermosillo",
    "ParameterValue": "hermosillo"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Irapuato",
    "ParameterValue": "irapuato"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Manzanillo",
    "ParameterValue": "manzanillo"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Mexicali",
    "ParameterValue": "mexicali"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Monclova",
    "ParameterValue": "monclova"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Monterrey",
    "ParameterValue": "monterrey"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Morelia",
    "ParameterValue": "morelia"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Nogales",
    "ParameterValue": "nogales"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Orizaba",
    "ParameterValue": "orizaba"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Pachuca",
    "ParameterValue": "pachuca"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Puebla",
    "ParameterValue": "puebla"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Reynosa",
    "ParameterValue": "reynosa"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Salamanca",
    "ParameterValue": "salamanca"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Saltillo",
    "ParameterValue": "saltillo"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Tampico",
    "ParameterValue": "tampico"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Tapachula",
    "ParameterValue": "tapachula"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Tepic",
    "ParameterValue": "tepic"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Tijuana",
    "ParameterValue": "tijuana"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Toluca",
    "ParameterValue": "toluca"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Torreon",
    "ParameterValue": "torreon"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Uruapan",
    "ParameterValue": "uruapan"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Veracruz",
    "ParameterValue": "veracruz"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Villahermosa",
    "ParameterValue": "villahermosa"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Xalapa",
    "ParameterValue": "xalapa"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Zamora",
    "ParameterValue": "zamora"
  },
  {
    "Country": "MX",
    "CountryName": "Mexico",
    "City": "Zumpango",
    "ParameterValue": "zumpango"
  },
  {
    "Country": "FM",
    "CountryName": "Micronesia, Federated States of",
    "City": "Tofol",
    "ParameterValue": "tofol"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Briceni",
    "ParameterValue": "briceni"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Cahul",
    "ParameterValue": "cahul"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Calarasi",
    "ParameterValue": "calarasi"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Cantemir",
    "ParameterValue": "cantemir"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Chisinau",
    "ParameterValue": "chisinau"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Cimislia",
    "ParameterValue": "cimislia"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Comrat",
    "ParameterValue": "comrat"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Donduseni",
    "ParameterValue": "donduseni"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Drochia",
    "ParameterValue": "drochia"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Floresti",
    "ParameterValue": "floresti"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Glodeni",
    "ParameterValue": "glodeni"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Ialoveni",
    "ParameterValue": "ialoveni"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Nisporeni",
    "ParameterValue": "nisporeni"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Ocnita",
    "ParameterValue": "ocnita"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Singerei",
    "ParameterValue": "singerei"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Soroca",
    "ParameterValue": "soroca"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Straseni",
    "ParameterValue": "straseni"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Taraclia",
    "ParameterValue": "taraclia"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Telenesti",
    "ParameterValue": "telenesti"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Tiraspol",
    "ParameterValue": "tiraspol"
  },
  {
    "Country": "MD",
    "CountryName": "Moldova",
    "City": "Ungheni",
    "ParameterValue": "ungheni"
  },
  {
    "Country": "MN",
    "CountryName": "Mongolia",
    "City": "Dalandzadgad",
    "ParameterValue": "dalandzadgad"
  },
  {
    "Country": "ME",
    "CountryName": "Montenegro",
    "City": "Bar",
    "ParameterValue": "bar"
  },
  {
    "Country": "ME",
    "CountryName": "Montenegro",
    "City": "Budva",
    "ParameterValue": "budva"
  },
  {
    "Country": "ME",
    "CountryName": "Montenegro",
    "City": "Kotor",
    "ParameterValue": "kotor"
  },
  {
    "Country": "ME",
    "CountryName": "Montenegro",
    "City": "Plav",
    "ParameterValue": "plav"
  },
  {
    "Country": "ME",
    "CountryName": "Montenegro",
    "City": "Podgorica",
    "ParameterValue": "podgorica"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Agadir",
    "ParameterValue": "agadir"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Casablanca",
    "ParameterValue": "casablanca"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Kenitra",
    "ParameterValue": "kenitra"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Marrakesh",
    "ParameterValue": "marrakesh"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Meknes",
    "ParameterValue": "meknes"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Oujda",
    "ParameterValue": "oujda"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Rabat",
    "ParameterValue": "rabat"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Settat",
    "ParameterValue": "settat"
  },
  {
    "Country": "MA",
    "CountryName": "Morocco",
    "City": "Tangier",
    "ParameterValue": "tangier"
  },
  {
    "Country": "MZ",
    "CountryName": "Mozambique",
    "City": "Maputo",
    "ParameterValue": "maputo"
  },
  {
    "Country": "MZ",
    "CountryName": "Mozambique",
    "City": "Matola",
    "ParameterValue": "matola"
  },
  {
    "Country": "MZ",
    "CountryName": "Mozambique",
    "City": "Nampula",
    "ParameterValue": "nampula"
  },
  {
    "Country": "MM",
    "CountryName": "Myanmar",
    "City": "Mandalay",
    "ParameterValue": "mandalay"
  },
  {
    "Country": "MM",
    "CountryName": "Myanmar",
    "City": "Ye",
    "ParameterValue": "ye"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Grootfontein",
    "ParameterValue": "grootfontein"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Mariental",
    "ParameterValue": "mariental"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Ongwediva",
    "ParameterValue": "ongwediva"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Oshakati",
    "ParameterValue": "oshakati"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Otjiwarongo",
    "ParameterValue": "otjiwarongo"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Rundu",
    "ParameterValue": "rundu"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Swakopmund",
    "ParameterValue": "swakopmund"
  },
  {
    "Country": "NA",
    "CountryName": "Namibia",
    "City": "Windhoek",
    "ParameterValue": "windhoek"
  },
  {
    "Country": "NP",
    "CountryName": "Nepal",
    "City": "Biratnagar",
    "ParameterValue": "biratnagar"
  },
  {
    "Country": "NP",
    "CountryName": "Nepal",
    "City": "Hetauda",
    "ParameterValue": "hetauda"
  },
  {
    "Country": "NP",
    "CountryName": "Nepal",
    "City": "Kathmandu",
    "ParameterValue": "kathmandu"
  },
  {
    "Country": "NP",
    "CountryName": "Nepal",
    "City": "Pokhara",
    "ParameterValue": "pokhara"
  },
  {
    "Country": "NL",
    "CountryName": "Netherlands",
    "City": "Amsterdam",
    "ParameterValue": "amsterdam"
  },
  {
    "Country": "NL",
    "CountryName": "Netherlands",
    "City": "Eindhoven",
    "ParameterValue": "eindhoven"
  },
  {
    "Country": "NL",
    "CountryName": "Netherlands",
    "City": "Groningen",
    "ParameterValue": "groningen"
  },
  {
    "Country": "NL",
    "CountryName": "Netherlands",
    "City": "Haarlem",
    "ParameterValue": "haarlem"
  },
  {
    "Country": "NL",
    "CountryName": "Netherlands",
    "City": "Maastricht",
    "ParameterValue": "maastricht"
  },
  {
    "Country": "NL",
    "CountryName": "Netherlands",
    "City": "Rotterdam",
    "ParameterValue": "rotterdam"
  },
  {
    "Country": "NL",
    "CountryName": "Netherlands",
    "City": "Utrecht",
    "ParameterValue": "utrecht"
  },
  {
    "Country": "NC",
    "CountryName": "New Caledonia",
    "City": "Noumea",
    "ParameterValue": "noumea"
  },
  {
    "Country": "NC",
    "CountryName": "New Caledonia",
    "City": "We",
    "ParameterValue": "we"
  },
  {
    "Country": "NZ",
    "CountryName": "New Zealand",
    "City": "Auckland",
    "ParameterValue": "auckland"
  },
  {
    "Country": "NZ",
    "CountryName": "New Zealand",
    "City": "Christchurch",
    "ParameterValue": "christchurch"
  },
  {
    "Country": "NZ",
    "CountryName": "New Zealand",
    "City": "Invercargill",
    "ParameterValue": "invercargill"
  },
  {
    "Country": "NZ",
    "CountryName": "New Zealand",
    "City": "Nelson",
    "ParameterValue": "nelson"
  },
  {
    "Country": "NZ",
    "CountryName": "New Zealand",
    "City": "Tauranga",
    "ParameterValue": "tauranga"
  },
  {
    "Country": "NZ",
    "CountryName": "New Zealand",
    "City": "Wellington",
    "ParameterValue": "wellington"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Bluefields",
    "ParameterValue": "bluefields"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Boaco",
    "ParameterValue": "boaco"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Chinandega",
    "ParameterValue": "chinandega"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Esteli",
    "ParameterValue": "esteli"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Jinotega",
    "ParameterValue": "jinotega"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Managua",
    "ParameterValue": "managua"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Masaya",
    "ParameterValue": "masaya"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Ocotal",
    "ParameterValue": "ocotal"
  },
  {
    "Country": "NI",
    "CountryName": "Nicaragua",
    "City": "Rivas",
    "ParameterValue": "rivas"
  },
  {
    "Country": "NE",
    "CountryName": "Niger",
    "City": "Niamey",
    "ParameterValue": "niamey"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Akure",
    "ParameterValue": "akure"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Calabar",
    "ParameterValue": "calabar"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Enugu",
    "ParameterValue": "enugu"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Ibadan",
    "ParameterValue": "ibadan"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Ikeja",
    "ParameterValue": "ikeja"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Lagos",
    "ParameterValue": "lagos"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Uyo",
    "ParameterValue": "uyo"
  },
  {
    "Country": "NG",
    "CountryName": "Nigeria",
    "City": "Warri",
    "ParameterValue": "warri"
  },
  {
    "Country": "NO",
    "CountryName": "Norway",
    "City": "Bergen",
    "ParameterValue": "bergen"
  },
  {
    "Country": "NO",
    "CountryName": "Norway",
    "City": "Hamar",
    "ParameterValue": "hamar"
  },
  {
    "Country": "NO",
    "CountryName": "Norway",
    "City": "Oslo",
    "ParameterValue": "oslo"
  },
  {
    "Country": "NO",
    "CountryName": "Norway",
    "City": "Stavanger",
    "ParameterValue": "stavanger"
  },
  {
    "Country": "OM",
    "CountryName": "Oman",
    "City": "Muscat",
    "ParameterValue": "muscat"
  },
  {
    "Country": "OM",
    "CountryName": "Oman",
    "City": "Salalah",
    "ParameterValue": "salalah"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Abbottabad",
    "ParameterValue": "abbottabad"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Bahawalpur",
    "ParameterValue": "bahawalpur"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Chiniot",
    "ParameterValue": "chiniot"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Faisalabad",
    "ParameterValue": "faisalabad"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Gilgit",
    "ParameterValue": "gilgit"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Gujranwala",
    "ParameterValue": "gujranwala"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Gujrat",
    "ParameterValue": "gujrat"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Islamabad",
    "ParameterValue": "islamabad"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Karachi",
    "ParameterValue": "karachi"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Kasur",
    "ParameterValue": "kasur"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Kohat",
    "ParameterValue": "kohat"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Lahore",
    "ParameterValue": "lahore"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Larkana",
    "ParameterValue": "larkana"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Mansehra",
    "ParameterValue": "mansehra"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Mardan",
    "ParameterValue": "mardan"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Multan",
    "ParameterValue": "multan"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Okara",
    "ParameterValue": "okara"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Peshawar",
    "ParameterValue": "peshawar"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Quetta",
    "ParameterValue": "quetta"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Rawalpindi",
    "ParameterValue": "rawalpindi"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Sahiwal",
    "ParameterValue": "sahiwal"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Sargodha",
    "ParameterValue": "sargodha"
  },
  {
    "Country": "PK",
    "CountryName": "Pakistan",
    "City": "Sukkur",
    "ParameterValue": "sukkur"
  },
  {
    "Country": "PW",
    "CountryName": "Palau",
    "City": "Koror",
    "ParameterValue": "koror"
  },
  {
    "Country": "PA",
    "CountryName": "Panama",
    "City": "David",
    "ParameterValue": "david"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Arequipa",
    "ParameterValue": "arequipa"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Cajamarca",
    "ParameterValue": "cajamarca"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Callao",
    "ParameterValue": "callao"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Chancay",
    "ParameterValue": "chancay"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Chiclayo",
    "ParameterValue": "chiclayo"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Cusco",
    "ParameterValue": "cusco"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Huacho",
    "ParameterValue": "huacho"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Huancayo",
    "ParameterValue": "huancayo"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Huaraz",
    "ParameterValue": "huaraz"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Ica",
    "ParameterValue": "ica"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Iquitos",
    "ParameterValue": "iquitos"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Juliaca",
    "ParameterValue": "juliaca"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Lima",
    "ParameterValue": "lima"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Moquegua",
    "ParameterValue": "moquegua"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Paita",
    "ParameterValue": "paita"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Pisco",
    "ParameterValue": "pisco"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Piura",
    "ParameterValue": "piura"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Puno",
    "ParameterValue": "puno"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Tacna",
    "ParameterValue": "tacna"
  },
  {
    "Country": "PE",
    "CountryName": "Peru",
    "City": "Trujillo",
    "ParameterValue": "trujillo"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Antipolo",
    "ParameterValue": "antipolo"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Balanga",
    "ParameterValue": "balanga"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Batangas",
    "ParameterValue": "batangas"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Bayombong",
    "ParameterValue": "bayombong"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Butuan",
    "ParameterValue": "butuan"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Dagupan",
    "ParameterValue": "dagupan"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Kidapawan",
    "ParameterValue": "kidapawan"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Koronadal",
    "ParameterValue": "koronadal"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Legazpi",
    "ParameterValue": "legazpi"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Malabon",
    "ParameterValue": "malabon"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Malaybalay",
    "ParameterValue": "malaybalay"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Malolos",
    "ParameterValue": "malolos"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Manila",
    "ParameterValue": "manila"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Masbate",
    "ParameterValue": "masbate"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Nabunturan",
    "ParameterValue": "nabunturan"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Naga",
    "ParameterValue": "naga"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Navotas",
    "ParameterValue": "navotas"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Siquijor",
    "ParameterValue": "siquijor"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Sorsogon",
    "ParameterValue": "sorsogon"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Tandag",
    "ParameterValue": "tandag"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Valenzuela",
    "ParameterValue": "valenzuela"
  },
  {
    "Country": "PH",
    "CountryName": "Philippines",
    "City": "Vigan",
    "ParameterValue": "vigan"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Bydgoszcz",
    "ParameterValue": "bydgoszcz"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Gdynia",
    "ParameterValue": "gdynia"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Gliwice",
    "ParameterValue": "gliwice"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Katowice",
    "ParameterValue": "katowice"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Koszalin",
    "ParameterValue": "koszalin"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Krakow",
    "ParameterValue": "krakow"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Lublin",
    "ParameterValue": "lublin"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Olsztyn",
    "ParameterValue": "olsztyn"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Opole",
    "ParameterValue": "opole"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Poznan",
    "ParameterValue": "poznan"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Szczecin",
    "ParameterValue": "szczecin"
  },
  {
    "Country": "PL",
    "CountryName": "Poland",
    "City": "Warsaw",
    "ParameterValue": "warsaw"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Beja",
    "ParameterValue": "beja"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Braga",
    "ParameterValue": "braga"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Evora",
    "ParameterValue": "evora"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Faro",
    "ParameterValue": "faro"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Funchal",
    "ParameterValue": "funchal"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Guarda",
    "ParameterValue": "guarda"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Leiria",
    "ParameterValue": "leiria"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Lisbon",
    "ParameterValue": "lisbon"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Porto",
    "ParameterValue": "porto"
  },
  {
    "Country": "PT",
    "CountryName": "Portugal",
    "City": "Viseu",
    "ParameterValue": "viseu"
  },
  {
    "Country": "QA",
    "CountryName": "Qatar",
    "City": "Doha",
    "ParameterValue": "doha"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Arad",
    "ParameterValue": "arad"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Bacau",
    "ParameterValue": "bacau"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Botosani",
    "ParameterValue": "botosani"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Braila",
    "ParameterValue": "braila"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Brasov",
    "ParameterValue": "brasov"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Bucharest",
    "ParameterValue": "bucharest"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Buzau",
    "ParameterValue": "buzau"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Cluj-Napoca",
    "ParameterValue": "cluj-napoca"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Craiova",
    "ParameterValue": "craiova"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Deva",
    "ParameterValue": "deva"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Galati",
    "ParameterValue": "galati"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Giurgiu",
    "ParameterValue": "giurgiu"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Iasi",
    "ParameterValue": "iasi"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Oradea",
    "ParameterValue": "oradea"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Sibiu",
    "ParameterValue": "sibiu"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Slatina",
    "ParameterValue": "slatina"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Suceava",
    "ParameterValue": "suceava"
  },
  {
    "Country": "RO",
    "CountryName": "Romania",
    "City": "Vaslui",
    "ParameterValue": "vaslui"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Abakan",
    "ParameterValue": "abakan"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Achinsk",
    "ParameterValue": "achinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Aldan",
    "ParameterValue": "aldan"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Aleysk",
    "ParameterValue": "aleysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Angarsk",
    "ParameterValue": "angarsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Apatity",
    "ParameterValue": "apatity"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Apsheronsk",
    "ParameterValue": "apsheronsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Asbest",
    "ParameterValue": "asbest"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Asha",
    "ParameterValue": "asha"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Astrakhan",
    "ParameterValue": "astrakhan"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Balakovo",
    "ParameterValue": "balakovo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Barnaul",
    "ParameterValue": "barnaul"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Belebey",
    "ParameterValue": "belebey"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Belgorod",
    "ParameterValue": "belgorod"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Belogorsk",
    "ParameterValue": "belogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Berezniki",
    "ParameterValue": "berezniki"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Bezhetsk",
    "ParameterValue": "bezhetsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Birobidzhan",
    "ParameterValue": "birobidzhan"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Biysk",
    "ParameterValue": "biysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Blagodarnyy",
    "ParameterValue": "blagodarnyy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Blagoveshchensk",
    "ParameterValue": "blagoveshchensk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Bodaybo",
    "ParameterValue": "bodaybo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Bologoye",
    "ParameterValue": "bologoye"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Borovichi",
    "ParameterValue": "borovichi"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Bratsk",
    "ParameterValue": "bratsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Bryansk",
    "ParameterValue": "bryansk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Buynaksk",
    "ParameterValue": "buynaksk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Buzuluk",
    "ParameterValue": "buzuluk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Chapayevsk",
    "ParameterValue": "chapayevsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Cheboksary",
    "ParameterValue": "cheboksary"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Chelyabinsk",
    "ParameterValue": "chelyabinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Cherepanovo",
    "ParameterValue": "cherepanovo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Cherepovets",
    "ParameterValue": "cherepovets"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Cherkessk",
    "ParameterValue": "cherkessk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Chernogorsk",
    "ParameterValue": "chernogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Chita",
    "ParameterValue": "chita"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Chusovoy",
    "ParameterValue": "chusovoy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Derbent",
    "ParameterValue": "derbent"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Dimitrovgrad",
    "ParameterValue": "dimitrovgrad"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Dzerzhinsk",
    "ParameterValue": "dzerzhinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Elista",
    "ParameterValue": "elista"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Frolovo",
    "ParameterValue": "frolovo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Gatchina",
    "ParameterValue": "gatchina"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Gelendzhik",
    "ParameterValue": "gelendzhik"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Gornyak",
    "ParameterValue": "gornyak"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Gryazi",
    "ParameterValue": "gryazi"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Gubkin",
    "ParameterValue": "gubkin"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Inta",
    "ParameterValue": "inta"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Irkutsk",
    "ParameterValue": "irkutsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ishim",
    "ParameterValue": "ishim"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Iskitim",
    "ParameterValue": "iskitim"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ivanovo",
    "ParameterValue": "ivanovo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Izhevsk",
    "ParameterValue": "izhevsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kaliningrad",
    "ParameterValue": "kaliningrad"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kaluga",
    "ParameterValue": "kaluga"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kamenka",
    "ParameterValue": "kamenka"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kamyshin",
    "ParameterValue": "kamyshin"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kansk",
    "ParameterValue": "kansk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kaspiysk",
    "ParameterValue": "kaspiysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kemerovo",
    "ParameterValue": "kemerovo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Khabarovsk",
    "ParameterValue": "khabarovsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kineshma",
    "ParameterValue": "kineshma"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kirov",
    "ParameterValue": "kirov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kirovo-Chepetsk",
    "ParameterValue": "kirovo-chepetsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kislovodsk",
    "ParameterValue": "kislovodsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kizel",
    "ParameterValue": "kizel"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Klintsy",
    "ParameterValue": "klintsy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kolpashevo",
    "ParameterValue": "kolpashevo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kostroma",
    "ParameterValue": "kostroma"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kotovsk",
    "ParameterValue": "kotovsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Krasnoarmeysk",
    "ParameterValue": "krasnoarmeysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Krasnodar",
    "ParameterValue": "krasnodar"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Krasnoyarsk",
    "ParameterValue": "krasnoyarsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kropotkin",
    "ParameterValue": "kropotkin"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kumertau",
    "ParameterValue": "kumertau"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kurgan",
    "ParameterValue": "kurgan"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kursk",
    "ParameterValue": "kursk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Kuznetsk",
    "ParameterValue": "kuznetsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Leninogorsk",
    "ParameterValue": "leninogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Lesosibirsk",
    "ParameterValue": "lesosibirsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Lipetsk",
    "ParameterValue": "lipetsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Magadan",
    "ParameterValue": "magadan"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Magas",
    "ParameterValue": "magas"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Magnitogorsk",
    "ParameterValue": "magnitogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Makhachkala",
    "ParameterValue": "makhachkala"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Mariinsk",
    "ParameterValue": "mariinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Maykop",
    "ParameterValue": "maykop"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Miass",
    "ParameterValue": "miass"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Millerovo",
    "ParameterValue": "millerovo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Mirnyy",
    "ParameterValue": "mirnyy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Moscow",
    "ParameterValue": "moscow"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Mozdok",
    "ParameterValue": "mozdok"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Murmansk",
    "ParameterValue": "murmansk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Murom",
    "ParameterValue": "murom"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Nadym",
    "ParameterValue": "nadym"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Nakhodka",
    "ParameterValue": "nakhodka"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Neftekamsk",
    "ParameterValue": "neftekamsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Neryungri",
    "ParameterValue": "neryungri"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Nevinnomyssk",
    "ParameterValue": "nevinnomyssk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Nizhnekamsk",
    "ParameterValue": "nizhnekamsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Nizhneudinsk",
    "ParameterValue": "nizhneudinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Noginsk",
    "ParameterValue": "noginsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Novoaltaysk",
    "ParameterValue": "novoaltaysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Novocherkassk",
    "ParameterValue": "novocherkassk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Novokuznetsk",
    "ParameterValue": "novokuznetsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Novomoskovsk",
    "ParameterValue": "novomoskovsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Novorossiysk",
    "ParameterValue": "novorossiysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Novosibirsk",
    "ParameterValue": "novosibirsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Novotroitsk",
    "ParameterValue": "novotroitsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Obninsk",
    "ParameterValue": "obninsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Okha",
    "ParameterValue": "okha"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Omsk",
    "ParameterValue": "omsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Orenburg",
    "ParameterValue": "orenburg"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Orsk",
    "ParameterValue": "orsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Otradnyy",
    "ParameterValue": "otradnyy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Partizansk",
    "ParameterValue": "partizansk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Penza",
    "ParameterValue": "penza"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Perm",
    "ParameterValue": "perm"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Pervouralsk",
    "ParameterValue": "pervouralsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Petrozavodsk",
    "ParameterValue": "petrozavodsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Podolsk",
    "ParameterValue": "podolsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Polyarnyy",
    "ParameterValue": "polyarnyy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Poronaysk",
    "ParameterValue": "poronaysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Progress",
    "ParameterValue": "progress"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Pskov",
    "ParameterValue": "pskov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Pyatigorsk",
    "ParameterValue": "pyatigorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Rubtsovsk",
    "ParameterValue": "rubtsovsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ruzayevka",
    "ParameterValue": "ruzayevka"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ryazan",
    "ParameterValue": "ryazan"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Rybinsk",
    "ParameterValue": "rybinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Salekhard",
    "ParameterValue": "salekhard"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Samara",
    "ParameterValue": "samara"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Saransk",
    "ParameterValue": "saransk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Saratov",
    "ParameterValue": "saratov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Sasovo",
    "ParameterValue": "sasovo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Sayanogorsk",
    "ParameterValue": "sayanogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Serov",
    "ParameterValue": "serov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Serpukhov",
    "ParameterValue": "serpukhov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Severodvinsk",
    "ParameterValue": "severodvinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Severomorsk",
    "ParameterValue": "severomorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Shadrinsk",
    "ParameterValue": "shadrinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Shebekino",
    "ParameterValue": "shebekino"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Slobodskoy",
    "ParameterValue": "slobodskoy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Smolensk",
    "ParameterValue": "smolensk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Sochi",
    "ParameterValue": "sochi"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Sokol",
    "ParameterValue": "sokol"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Solnechnogorsk",
    "ParameterValue": "solnechnogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Sosnogorsk",
    "ParameterValue": "sosnogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Sovetsk",
    "ParameterValue": "sovetsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Stavropol",
    "ParameterValue": "stavropol"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Sterlitamak",
    "ParameterValue": "sterlitamak"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Surgut",
    "ParameterValue": "surgut"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Svobodnyy",
    "ParameterValue": "svobodnyy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Syktyvkar",
    "ParameterValue": "syktyvkar"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Taganrog",
    "ParameterValue": "taganrog"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Taksimo",
    "ParameterValue": "taksimo"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tambov",
    "ParameterValue": "tambov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tavda",
    "ParameterValue": "tavda"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tayshet",
    "ParameterValue": "tayshet"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tikhoretsk",
    "ParameterValue": "tikhoretsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tikhvin",
    "ParameterValue": "tikhvin"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tolyatti",
    "ParameterValue": "tolyatti"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tomsk",
    "ParameterValue": "tomsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tula",
    "ParameterValue": "tula"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tulun",
    "ParameterValue": "tulun"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tuymazy",
    "ParameterValue": "tuymazy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tver",
    "ParameterValue": "tver"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Tyumen",
    "ParameterValue": "tyumen"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ufa",
    "ParameterValue": "ufa"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Uglich",
    "ParameterValue": "uglich"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ukhta",
    "ParameterValue": "ukhta"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ulyanovsk",
    "ParameterValue": "ulyanovsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Ussuriysk",
    "ParameterValue": "ussuriysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Velsk",
    "ParameterValue": "velsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Verkhoyansk",
    "ParameterValue": "verkhoyansk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Vladikavkaz",
    "ParameterValue": "vladikavkaz"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Vladimir",
    "ParameterValue": "vladimir"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Vladivostok",
    "ParameterValue": "vladivostok"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Volgodonsk",
    "ParameterValue": "volgodonsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Volgograd",
    "ParameterValue": "volgograd"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Volkhov",
    "ParameterValue": "volkhov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Vologda",
    "ParameterValue": "vologda"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Volzhskiy",
    "ParameterValue": "volzhskiy"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Vorkuta",
    "ParameterValue": "vorkuta"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Voronezh",
    "ParameterValue": "voronezh"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Vyborg",
    "ParameterValue": "vyborg"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yakutsk",
    "ParameterValue": "yakutsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yalutorovsk",
    "ParameterValue": "yalutorovsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yaroslavl",
    "ParameterValue": "yaroslavl"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yefremov",
    "ParameterValue": "yefremov"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yekaterinburg",
    "ParameterValue": "yekaterinburg"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yelets",
    "ParameterValue": "yelets"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yemanzhelinsk",
    "ParameterValue": "yemanzhelinsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Yeysk",
    "ParameterValue": "yeysk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Zelenodolsk",
    "ParameterValue": "zelenodolsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Zeya",
    "ParameterValue": "zeya"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Zheleznogorsk",
    "ParameterValue": "zheleznogorsk"
  },
  {
    "Country": "RU",
    "CountryName": "Russian Federation",
    "City": "Zlatoust",
    "ParameterValue": "zlatoust"
  },
  {
    "Country": "RW",
    "CountryName": "Rwanda",
    "City": "Kigali",
    "ParameterValue": "kigali"
  },
  {
    "Country": "RW",
    "CountryName": "Rwanda",
    "City": "Nyanza",
    "ParameterValue": "nyanza"
  },
  {
    "Country": "KN",
    "CountryName": "Saint Kitts and Nevis",
    "City": "Basseterre",
    "ParameterValue": "basseterre"
  },
  {
    "Country": "LC",
    "CountryName": "Saint Lucia",
    "City": "Castries",
    "ParameterValue": "castries"
  },
  {
    "Country": "VC",
    "CountryName": "Saint Vincent and Grenadines",
    "City": "Kingstown",
    "ParameterValue": "kingstown"
  },
  {
    "Country": "SA",
    "CountryName": "Saudi Arabia",
    "City": "Jeddah",
    "ParameterValue": "jeddah"
  },
  {
    "Country": "SA",
    "CountryName": "Saudi Arabia",
    "City": "Makkah",
    "ParameterValue": "makkah"
  },
  {
    "Country": "SA",
    "CountryName": "Saudi Arabia",
    "City": "Medina",
    "ParameterValue": "medina"
  },
  {
    "Country": "SA",
    "CountryName": "Saudi Arabia",
    "City": "Riyadh",
    "ParameterValue": "riyadh"
  },
  {
    "Country": "SA",
    "CountryName": "Saudi Arabia",
    "City": "Sakakah",
    "ParameterValue": "sakakah"
  },
  {
    "Country": "SN",
    "CountryName": "Senegal",
    "City": "Dakar",
    "ParameterValue": "dakar"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Becej",
    "ParameterValue": "becej"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Belgrade",
    "ParameterValue": "belgrade"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Bor",
    "ParameterValue": "bor"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Jagodina",
    "ParameterValue": "jagodina"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Kosjeric",
    "ParameterValue": "kosjeric"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Kovin",
    "ParameterValue": "kovin"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Kragujevac",
    "ParameterValue": "kragujevac"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Kraljevo",
    "ParameterValue": "kraljevo"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Kucevo",
    "ParameterValue": "kucevo"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Lebane",
    "ParameterValue": "lebane"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Leskovac",
    "ParameterValue": "leskovac"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Negotin",
    "ParameterValue": "negotin"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Paracin",
    "ParameterValue": "paracin"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Pirot",
    "ParameterValue": "pirot"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Prijepolje",
    "ParameterValue": "prijepolje"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Prokuplje",
    "ParameterValue": "prokuplje"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Raska",
    "ParameterValue": "raska"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Sabac",
    "ParameterValue": "sabac"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Sombor",
    "ParameterValue": "sombor"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Trstenik",
    "ParameterValue": "trstenik"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Vranje",
    "ParameterValue": "vranje"
  },
  {
    "Country": "RS",
    "CountryName": "Serbia",
    "City": "Zajecar",
    "ParameterValue": "zajecar"
  },
  {
    "Country": "SL",
    "CountryName": "Sierra Leone",
    "City": "Bo",
    "ParameterValue": "bo"
  },
  {
    "Country": "SG",
    "CountryName": "Singapore",
    "City": "Singapore",
    "ParameterValue": "singapore"
  },
  {
    "Country": "SK",
    "CountryName": "Slovakia",
    "City": "Bratislava",
    "ParameterValue": "bratislava"
  },
  {
    "Country": "SK",
    "CountryName": "Slovakia",
    "City": "Nitra",
    "ParameterValue": "nitra"
  },
  {
    "Country": "SK",
    "CountryName": "Slovakia",
    "City": "Trnava",
    "ParameterValue": "trnava"
  },
  {
    "Country": "SI",
    "CountryName": "Slovenia",
    "City": "Crensovci",
    "ParameterValue": "crensovci"
  },
  {
    "Country": "SI",
    "CountryName": "Slovenia",
    "City": "Izola",
    "ParameterValue": "izola"
  },
  {
    "Country": "SI",
    "CountryName": "Slovenia",
    "City": "Kranj",
    "ParameterValue": "kranj"
  },
  {
    "Country": "SI",
    "CountryName": "Slovenia",
    "City": "Ljubljana",
    "ParameterValue": "ljubljana"
  },
  {
    "Country": "SI",
    "CountryName": "Slovenia",
    "City": "Maribor",
    "ParameterValue": "maribor"
  },
  {
    "Country": "SI",
    "CountryName": "Slovenia",
    "City": "Medvode",
    "ParameterValue": "medvode"
  },
  {
    "Country": "SO",
    "CountryName": "Somalia",
    "City": "Mogadishu",
    "ParameterValue": "mogadishu"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Benoni",
    "ParameterValue": "benoni"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Bethlehem",
    "ParameterValue": "bethlehem"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Bloemfontein",
    "ParameterValue": "bloemfontein"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Brits",
    "ParameterValue": "brits"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Durban",
    "ParameterValue": "durban"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Grahamstown",
    "ParameterValue": "grahamstown"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Johannesburg",
    "ParameterValue": "johannesburg"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Kimberley",
    "ParameterValue": "kimberley"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Klerksdorp",
    "ParameterValue": "klerksdorp"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Knysna",
    "ParameterValue": "knysna"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Kroonstad",
    "ParameterValue": "kroonstad"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Kuruman",
    "ParameterValue": "kuruman"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Nelspruit",
    "ParameterValue": "nelspruit"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Oudtshoorn",
    "ParameterValue": "oudtshoorn"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Paarl",
    "ParameterValue": "paarl"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Pietermaritzburg",
    "ParameterValue": "pietermaritzburg"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Polokwane",
    "ParameterValue": "polokwane"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Potchefstroom",
    "ParameterValue": "potchefstroom"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Pretoria",
    "ParameterValue": "pretoria"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Rustenburg",
    "ParameterValue": "rustenburg"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Springs",
    "ParameterValue": "springs"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Tzaneen",
    "ParameterValue": "tzaneen"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Vereeniging",
    "ParameterValue": "vereeniging"
  },
  {
    "Country": "ZA",
    "CountryName": "South Africa",
    "City": "Welkom",
    "ParameterValue": "welkom"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Albacete",
    "ParameterValue": "albacete"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Algeciras",
    "ParameterValue": "algeciras"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Alicante",
    "ParameterValue": "alicante"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Arrecife",
    "ParameterValue": "arrecife"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Badajoz",
    "ParameterValue": "badajoz"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Barcelona",
    "ParameterValue": "barcelona"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Bilbao",
    "ParameterValue": "bilbao"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Burgos",
    "ParameterValue": "burgos"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Ceuta",
    "ParameterValue": "ceuta"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Granada",
    "ParameterValue": "granada"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Huelva",
    "ParameterValue": "huelva"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Linares",
    "ParameterValue": "linares"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Lorca",
    "ParameterValue": "lorca"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Madrid",
    "ParameterValue": "madrid"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Marbella",
    "ParameterValue": "marbella"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Melilla",
    "ParameterValue": "melilla"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Murcia",
    "ParameterValue": "murcia"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Ourense",
    "ParameterValue": "ourense"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Oviedo",
    "ParameterValue": "oviedo"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Palma",
    "ParameterValue": "palma"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Pamplona",
    "ParameterValue": "pamplona"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Santander",
    "ParameterValue": "santander"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Seville",
    "ParameterValue": "seville"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Tarragona",
    "ParameterValue": "tarragona"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Toledo",
    "ParameterValue": "toledo"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Valencia",
    "ParameterValue": "valencia"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Valladolid",
    "ParameterValue": "valladolid"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Vigo",
    "ParameterValue": "vigo"
  },
  {
    "Country": "ES",
    "CountryName": "Spain",
    "City": "Zaragoza",
    "ParameterValue": "zaragoza"
  },
  {
    "Country": "LK",
    "CountryName": "Sri Lanka",
    "City": "Anuradhapura",
    "ParameterValue": "anuradhapura"
  },
  {
    "Country": "LK",
    "CountryName": "Sri Lanka",
    "City": "Colombo",
    "ParameterValue": "colombo"
  },
  {
    "Country": "LK",
    "CountryName": "Sri Lanka",
    "City": "Galle",
    "ParameterValue": "galle"
  },
  {
    "Country": "LK",
    "CountryName": "Sri Lanka",
    "City": "Jaffna",
    "ParameterValue": "jaffna"
  },
  {
    "Country": "LK",
    "CountryName": "Sri Lanka",
    "City": "Kandy",
    "ParameterValue": "kandy"
  },
  {
    "Country": "LK",
    "CountryName": "Sri Lanka",
    "City": "Moratuwa",
    "ParameterValue": "moratuwa"
  },
  {
    "Country": "SD",
    "CountryName": "Sudan",
    "City": "Khartoum",
    "ParameterValue": "khartoum"
  },
  {
    "Country": "SR",
    "CountryName": "Suriname",
    "City": "Paramaribo",
    "ParameterValue": "paramaribo"
  },
  {
    "Country": "SZ",
    "CountryName": "Swaziland",
    "City": "Manzini",
    "ParameterValue": "manzini"
  },
  {
    "Country": "SZ",
    "CountryName": "Swaziland",
    "City": "Mbabane",
    "ParameterValue": "mbabane"
  },
  {
    "Country": "SE",
    "CountryName": "Sweden",
    "City": "Helsingborg",
    "ParameterValue": "helsingborg"
  },
  {
    "Country": "SE",
    "CountryName": "Sweden",
    "City": "Kalmar",
    "ParameterValue": "kalmar"
  },
  {
    "Country": "SE",
    "CountryName": "Sweden",
    "City": "Karlstad",
    "ParameterValue": "karlstad"
  },
  {
    "Country": "CH",
    "CountryName": "Switzerland",
    "City": "Aarau",
    "ParameterValue": "aarau"
  },
  {
    "Country": "CH",
    "CountryName": "Switzerland",
    "City": "Lausanne",
    "ParameterValue": "lausanne"
  },
  {
    "Country": "CH",
    "CountryName": "Switzerland",
    "City": "Zurich",
    "ParameterValue": "zurich"
  },
  {
    "Country": "SY",
    "CountryName": "Syrian Arab Republic (Syria)",
    "City": "Aleppo",
    "ParameterValue": "aleppo"
  },
  {
    "Country": "SY",
    "CountryName": "Syrian Arab Republic (Syria)",
    "City": "Damascus",
    "ParameterValue": "damascus"
  },
  {
    "Country": "TW",
    "CountryName": "Taiwan, Republic of China",
    "City": "Taichung",
    "ParameterValue": "taichung"
  },
  {
    "Country": "TW",
    "CountryName": "Taiwan, Republic of China",
    "City": "Taipei",
    "ParameterValue": "taipei"
  },
  {
    "Country": "TW",
    "CountryName": "Taiwan, Republic of China",
    "City": "Yilan",
    "ParameterValue": "yilan"
  },
  {
    "Country": "TJ",
    "CountryName": "Tajikistan",
    "City": "Dushanbe",
    "ParameterValue": "dushanbe"
  },
  {
    "Country": "TZ",
    "CountryName": "Tanzania, United Republic of",
    "City": "Arusha",
    "ParameterValue": "arusha"
  },
  {
    "Country": "TZ",
    "CountryName": "Tanzania, United Republic of",
    "City": "Kasulu",
    "ParameterValue": "kasulu"
  },
  {
    "Country": "TZ",
    "CountryName": "Tanzania, United Republic of",
    "City": "Kigoma",
    "ParameterValue": "kigoma"
  },
  {
    "Country": "TZ",
    "CountryName": "Tanzania, United Republic of",
    "City": "Mbeya",
    "ParameterValue": "mbeya"
  },
  {
    "Country": "TZ",
    "CountryName": "Tanzania, United Republic of",
    "City": "Morogoro",
    "ParameterValue": "morogoro"
  },
  {
    "Country": "TZ",
    "CountryName": "Tanzania, United Republic of",
    "City": "Musoma",
    "ParameterValue": "musoma"
  },
  {
    "Country": "TZ",
    "CountryName": "Tanzania, United Republic of",
    "City": "Zanzibar",
    "ParameterValue": "zanzibar"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Ayutthaya",
    "ParameterValue": "ayutthaya"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Bangkok",
    "ParameterValue": "bangkok"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Buriram",
    "ParameterValue": "buriram"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Chachoengsao",
    "ParameterValue": "chachoengsao"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Chaiyaphum",
    "ParameterValue": "chaiyaphum"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Chanthaburi",
    "ParameterValue": "chanthaburi"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Chumphon",
    "ParameterValue": "chumphon"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Kalasin",
    "ParameterValue": "kalasin"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Kanchanaburi",
    "ParameterValue": "kanchanaburi"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Krabi",
    "ParameterValue": "krabi"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Lampang",
    "ParameterValue": "lampang"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Lamphun",
    "ParameterValue": "lamphun"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Loei",
    "ParameterValue": "loei"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Mukdahan",
    "ParameterValue": "mukdahan"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Nan",
    "ParameterValue": "nan"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Narathiwat",
    "ParameterValue": "narathiwat"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Nonthaburi",
    "ParameterValue": "nonthaburi"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Pattani",
    "ParameterValue": "pattani"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Phetchabun",
    "ParameterValue": "phetchabun"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Phetchaburi",
    "ParameterValue": "phetchaburi"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Phichit",
    "ParameterValue": "phichit"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Phitsanulok",
    "ParameterValue": "phitsanulok"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Phrae",
    "ParameterValue": "phrae"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Phuket",
    "ParameterValue": "phuket"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Ranong",
    "ParameterValue": "ranong"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Ratchaburi",
    "ParameterValue": "ratchaburi"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Rayong",
    "ParameterValue": "rayong"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Saraburi",
    "ParameterValue": "saraburi"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Songkhla",
    "ParameterValue": "songkhla"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Surin",
    "ParameterValue": "surin"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Tak",
    "ParameterValue": "tak"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Uttaradit",
    "ParameterValue": "uttaradit"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Yala",
    "ParameterValue": "yala"
  },
  {
    "Country": "TH",
    "CountryName": "Thailand",
    "City": "Yasothon",
    "ParameterValue": "yasothon"
  },
  {
    "Country": "TL",
    "CountryName": "Timor-Leste",
    "City": "Dili",
    "ParameterValue": "dili"
  },
  {
    "Country": "TL",
    "CountryName": "Timor-Leste",
    "City": "Maliana",
    "ParameterValue": "maliana"
  },
  {
    "Country": "TT",
    "CountryName": "Trinidad and Tobago",
    "City": "Arima",
    "ParameterValue": "arima"
  },
  {
    "Country": "TT",
    "CountryName": "Trinidad and Tobago",
    "City": "Chaguanas",
    "ParameterValue": "chaguanas"
  },
  {
    "Country": "TT",
    "CountryName": "Trinidad and Tobago",
    "City": "Tunapuna",
    "ParameterValue": "tunapuna"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Bizerte",
    "ParameterValue": "bizerte"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Gafsa",
    "ParameterValue": "gafsa"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Jendouba",
    "ParameterValue": "jendouba"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Kebili",
    "ParameterValue": "kebili"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Mahdia",
    "ParameterValue": "mahdia"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Manouba",
    "ParameterValue": "manouba"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Monastir",
    "ParameterValue": "monastir"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Nabeul",
    "ParameterValue": "nabeul"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Sfax",
    "ParameterValue": "sfax"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Siliana",
    "ParameterValue": "siliana"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Sousse",
    "ParameterValue": "sousse"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Tunis",
    "ParameterValue": "tunis"
  },
  {
    "Country": "TN",
    "CountryName": "Tunisia",
    "City": "Zaghouan",
    "ParameterValue": "zaghouan"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Adana",
    "ParameterValue": "adana"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Ankara",
    "ParameterValue": "ankara"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Antalya",
    "ParameterValue": "antalya"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Artvin",
    "ParameterValue": "artvin"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Bartin",
    "ParameterValue": "bartin"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Batman",
    "ParameterValue": "batman"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Bilecik",
    "ParameterValue": "bilecik"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Bursa",
    "ParameterValue": "bursa"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Edirne",
    "ParameterValue": "edirne"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Gaziantep",
    "ParameterValue": "gaziantep"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Giresun",
    "ParameterValue": "giresun"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Isparta",
    "ParameterValue": "isparta"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Istanbul",
    "ParameterValue": "istanbul"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Izmir",
    "ParameterValue": "izmir"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Kastamonu",
    "ParameterValue": "kastamonu"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Kayseri",
    "ParameterValue": "kayseri"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Kilis",
    "ParameterValue": "kilis"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Konya",
    "ParameterValue": "konya"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Malatya",
    "ParameterValue": "malatya"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Mardin",
    "ParameterValue": "mardin"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Mersin",
    "ParameterValue": "mersin"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Mugla",
    "ParameterValue": "mugla"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Ordu",
    "ParameterValue": "ordu"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Rize",
    "ParameterValue": "rize"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Samsun",
    "ParameterValue": "samsun"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Sanliurfa",
    "ParameterValue": "sanliurfa"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Sivas",
    "ParameterValue": "sivas"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Trabzon",
    "ParameterValue": "trabzon"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Yalova",
    "ParameterValue": "yalova"
  },
  {
    "Country": "TR",
    "CountryName": "Turkey",
    "City": "Yozgat",
    "ParameterValue": "yozgat"
  },
  {
    "Country": "TM",
    "CountryName": "Turkmenistan",
    "City": "Mary",
    "ParameterValue": "mary"
  },
  {
    "Country": "UG",
    "CountryName": "Uganda",
    "City": "Kampala",
    "ParameterValue": "kampala"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Brovary",
    "ParameterValue": "brovary"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Cherkasy",
    "ParameterValue": "cherkasy"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Chernihiv",
    "ParameterValue": "chernihiv"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Chernivtsi",
    "ParameterValue": "chernivtsi"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Dnipro",
    "ParameterValue": "dnipro"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Donetsk",
    "ParameterValue": "donetsk"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Drohobych",
    "ParameterValue": "drohobych"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Horlivka",
    "ParameterValue": "horlivka"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Ivano-Frankivsk",
    "ParameterValue": "ivano-frankivsk"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Kharkiv",
    "ParameterValue": "kharkiv"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Kherson",
    "ParameterValue": "kherson"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Korosten",
    "ParameterValue": "korosten"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Kovel",
    "ParameterValue": "kovel"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Kramatorsk",
    "ParameterValue": "kramatorsk"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Kremenchuk",
    "ParameterValue": "kremenchuk"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Luhansk",
    "ParameterValue": "luhansk"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Lutsk",
    "ParameterValue": "lutsk"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Makiyivka",
    "ParameterValue": "makiyivka"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Mariupol",
    "ParameterValue": "mariupol"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Melitopol",
    "ParameterValue": "melitopol"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Mykolayiv",
    "ParameterValue": "mykolayiv"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Nikopol",
    "ParameterValue": "nikopol"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Nizhyn",
    "ParameterValue": "nizhyn"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Poltava",
    "ParameterValue": "poltava"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Rivne",
    "ParameterValue": "rivne"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Shostka",
    "ParameterValue": "shostka"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Sumy",
    "ParameterValue": "sumy"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Ternopil",
    "ParameterValue": "ternopil"
  },
  {
    "Country": "UA",
    "CountryName": "Ukraine",
    "City": "Zhytomyr",
    "ParameterValue": "zhytomyr"
  },
  {
    "Country": "AE",
    "CountryName": "United Arab Emirates",
    "City": "Ajman",
    "ParameterValue": "ajman"
  },
  {
    "Country": "AE",
    "CountryName": "United Arab Emirates",
    "City": "Dubai",
    "ParameterValue": "dubai"
  },
  {
    "Country": "AE",
    "CountryName": "United Arab Emirates",
    "City": "Sharjah",
    "ParameterValue": "sharjah"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Ayr",
    "ParameterValue": "ayr"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Ballymena",
    "ParameterValue": "ballymena"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Barnsley",
    "ParameterValue": "barnsley"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Belfast",
    "ParameterValue": "belfast"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Bexleyheath",
    "ParameterValue": "bexleyheath"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Birmingham",
    "ParameterValue": "birmingham"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Blackburn",
    "ParameterValue": "blackburn"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Blackpool",
    "ParameterValue": "blackpool"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Bolton",
    "ParameterValue": "bolton"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Bracknell",
    "ParameterValue": "bracknell"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Bradford",
    "ParameterValue": "bradford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Bristol",
    "ParameterValue": "bristol"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Bury",
    "ParameterValue": "bury"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Camberwell",
    "ParameterValue": "camberwell"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Cardiff",
    "ParameterValue": "cardiff"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Chelmsford",
    "ParameterValue": "chelmsford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Coventry",
    "ParameterValue": "coventry"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Craigavon",
    "ParameterValue": "craigavon"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Croydon",
    "ParameterValue": "croydon"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Dagenham",
    "ParameterValue": "dagenham"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Darlington",
    "ParameterValue": "darlington"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Derby",
    "ParameterValue": "derby"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Doncaster",
    "ParameterValue": "doncaster"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Dudley",
    "ParameterValue": "dudley"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Dumbarton",
    "ParameterValue": "dumbarton"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Dundee",
    "ParameterValue": "dundee"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Durham",
    "ParameterValue": "durham"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Edinburgh",
    "ParameterValue": "edinburgh"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Exeter",
    "ParameterValue": "exeter"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Falkirk",
    "ParameterValue": "falkirk"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Gateshead",
    "ParameterValue": "gateshead"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Glasgow",
    "ParameterValue": "glasgow"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Glenrothes",
    "ParameterValue": "glenrothes"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Gloucester",
    "ParameterValue": "gloucester"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Greenock",
    "ParameterValue": "greenock"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Hackney",
    "ParameterValue": "hackney"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Hammersmith",
    "ParameterValue": "hammersmith"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Hartlepool",
    "ParameterValue": "hartlepool"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Hendon",
    "ParameterValue": "hendon"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Hereford",
    "ParameterValue": "hereford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Hounslow",
    "ParameterValue": "hounslow"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Ilford",
    "ParameterValue": "ilford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Ipswich",
    "ParameterValue": "ipswich"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Islington",
    "ParameterValue": "islington"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Kirkwall",
    "ParameterValue": "kirkwall"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Leeds",
    "ParameterValue": "leeds"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Leicester",
    "ParameterValue": "leicester"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Lincoln",
    "ParameterValue": "lincoln"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Lisburn",
    "ParameterValue": "lisburn"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Liverpool",
    "ParameterValue": "liverpool"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Lochgilphead",
    "ParameterValue": "lochgilphead"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "London",
    "ParameterValue": "london"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Londonderry",
    "ParameterValue": "londonderry"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Luton",
    "ParameterValue": "luton"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Magherafelt",
    "ParameterValue": "magherafelt"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Maidenhead",
    "ParameterValue": "maidenhead"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Manchester",
    "ParameterValue": "manchester"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Middlesbrough",
    "ParameterValue": "middlesbrough"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Newport",
    "ParameterValue": "newport"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Newry",
    "ParameterValue": "newry"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Northampton",
    "ParameterValue": "northampton"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Norwich",
    "ParameterValue": "norwich"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Nottingham",
    "ParameterValue": "nottingham"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Oldham",
    "ParameterValue": "oldham"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Omagh",
    "ParameterValue": "omagh"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Oxford",
    "ParameterValue": "oxford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Peterborough",
    "ParameterValue": "peterborough"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Plymouth",
    "ParameterValue": "plymouth"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Poplar",
    "ParameterValue": "poplar"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Preston",
    "ParameterValue": "preston"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Reading",
    "ParameterValue": "reading"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Romford",
    "ParameterValue": "romford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Rotherham",
    "ParameterValue": "rotherham"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Salford",
    "ParameterValue": "salford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Scarborough",
    "ParameterValue": "scarborough"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Scunthorpe",
    "ParameterValue": "scunthorpe"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Sheffield",
    "ParameterValue": "sheffield"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Shrewsbury",
    "ParameterValue": "shrewsbury"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Slough",
    "ParameterValue": "slough"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Southampton",
    "ParameterValue": "southampton"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Stockport",
    "ParameterValue": "stockport"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Sunderland",
    "ParameterValue": "sunderland"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Swansea",
    "ParameterValue": "swansea"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Swindon",
    "ParameterValue": "swindon"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Taunton",
    "ParameterValue": "taunton"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Telford",
    "ParameterValue": "telford"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Uxbridge",
    "ParameterValue": "uxbridge"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Wallasey",
    "ParameterValue": "wallasey"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Wallsend",
    "ParameterValue": "wallsend"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Warrington",
    "ParameterValue": "warrington"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Wembley",
    "ParameterValue": "wembley"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Weston-super-Mare",
    "ParameterValue": "weston-super-mare"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Widnes",
    "ParameterValue": "widnes"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Wigan",
    "ParameterValue": "wigan"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Wokingham",
    "ParameterValue": "wokingham"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Wolverhampton",
    "ParameterValue": "wolverhampton"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Woolwich",
    "ParameterValue": "woolwich"
  },
  {
    "Country": "GB",
    "CountryName": "United Kingdom",
    "City": "Worcester",
    "ParameterValue": "worcester"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Abilene",
    "ParameterValue": "abilene"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Acworth",
    "ParameterValue": "acworth"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Adrian",
    "ParameterValue": "adrian"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Aiken",
    "ParameterValue": "aiken"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Akron",
    "ParameterValue": "akron"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Albany",
    "ParameterValue": "albany"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Albertville",
    "ParameterValue": "albertville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Albuquerque",
    "ParameterValue": "albuquerque"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Alexandria",
    "ParameterValue": "alexandria"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Allentown",
    "ParameterValue": "allentown"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Alsip",
    "ParameterValue": "alsip"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Altoona",
    "ParameterValue": "altoona"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Alvin",
    "ParameterValue": "alvin"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Anaheim",
    "ParameterValue": "anaheim"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Anchorage",
    "ParameterValue": "anchorage"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Anderson",
    "ParameterValue": "anderson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Anniston",
    "ParameterValue": "anniston"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Antioch",
    "ParameterValue": "antioch"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Apex",
    "ParameterValue": "apex"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Apopka",
    "ParameterValue": "apopka"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Appleton",
    "ParameterValue": "appleton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Arlington",
    "ParameterValue": "arlington"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Asheville",
    "ParameterValue": "asheville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Atascadero",
    "ParameterValue": "atascadero"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Atlanta",
    "ParameterValue": "atlanta"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Auburn",
    "ParameterValue": "auburn"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Augusta",
    "ParameterValue": "augusta"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Aurora",
    "ParameterValue": "aurora"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Austin",
    "ParameterValue": "austin"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bakersfield",
    "ParameterValue": "bakersfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Ballwin",
    "ParameterValue": "ballwin"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Baltimore",
    "ParameterValue": "baltimore"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Batavia",
    "ParameterValue": "batavia"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Beaufort",
    "ParameterValue": "beaufort"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Beaumont",
    "ParameterValue": "beaumont"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Beaverton",
    "ParameterValue": "beaverton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bellflower",
    "ParameterValue": "bellflower"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bellingham",
    "ParameterValue": "bellingham"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Belvidere",
    "ParameterValue": "belvidere"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bethany",
    "ParameterValue": "bethany"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bettendorf",
    "ParameterValue": "bettendorf"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Billings",
    "ParameterValue": "billings"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Binghamton",
    "ParameterValue": "binghamton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bixby",
    "ParameterValue": "bixby"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Blacksburg",
    "ParameterValue": "blacksburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bloomfield",
    "ParameterValue": "bloomfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bloomington",
    "ParameterValue": "bloomington"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Blytheville",
    "ParameterValue": "blytheville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Boise",
    "ParameterValue": "boise"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bolingbrook",
    "ParameterValue": "bolingbrook"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Boston",
    "ParameterValue": "boston"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bothell",
    "ParameterValue": "bothell"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Bozeman",
    "ParameterValue": "bozeman"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Brandon",
    "ParameterValue": "brandon"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Brockton",
    "ParameterValue": "brockton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Brookhaven",
    "ParameterValue": "brookhaven"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Brooklyn",
    "ParameterValue": "brooklyn"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Brownsburg",
    "ParameterValue": "brownsburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Brownsville",
    "ParameterValue": "brownsville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Buda",
    "ParameterValue": "buda"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Buffalo",
    "ParameterValue": "buffalo"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Burbank",
    "ParameterValue": "burbank"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Burlington",
    "ParameterValue": "burlington"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Butler",
    "ParameterValue": "butler"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Campbellsville",
    "ParameterValue": "campbellsville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Canton",
    "ParameterValue": "canton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Carlsbad",
    "ParameterValue": "carlsbad"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Carrollton",
    "ParameterValue": "carrollton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Carson",
    "ParameterValue": "carson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Carterville",
    "ParameterValue": "carterville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Cary",
    "ParameterValue": "cary"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Centreville",
    "ParameterValue": "centreville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Charleston",
    "ParameterValue": "charleston"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Charlotte",
    "ParameterValue": "charlotte"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Charlottesville",
    "ParameterValue": "charlottesville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Chattanooga",
    "ParameterValue": "chattanooga"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Chelsea",
    "ParameterValue": "chelsea"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Chesapeake",
    "ParameterValue": "chesapeake"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Cheyenne",
    "ParameterValue": "cheyenne"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Chicago",
    "ParameterValue": "chicago"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Chicopee",
    "ParameterValue": "chicopee"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Chillicothe",
    "ParameterValue": "chillicothe"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Cicero",
    "ParameterValue": "cicero"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Cincinnati",
    "ParameterValue": "cincinnati"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Clarksville",
    "ParameterValue": "clarksville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Clayton",
    "ParameterValue": "clayton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Clemson",
    "ParameterValue": "clemson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Clermont",
    "ParameterValue": "clermont"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Cleveland",
    "ParameterValue": "cleveland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Clifton",
    "ParameterValue": "clifton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Clinton",
    "ParameterValue": "clinton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Columbia",
    "ParameterValue": "columbia"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Columbus",
    "ParameterValue": "columbus"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Concord",
    "ParameterValue": "concord"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Conshohocken",
    "ParameterValue": "conshohocken"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Conway",
    "ParameterValue": "conway"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Conyers",
    "ParameterValue": "conyers"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Coppell",
    "ParameterValue": "coppell"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Corning",
    "ParameterValue": "corning"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Covina",
    "ParameterValue": "covina"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Cullman",
    "ParameterValue": "cullman"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Dallas",
    "ParameterValue": "dallas"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Danbury",
    "ParameterValue": "danbury"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Darien",
    "ParameterValue": "darien"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Davenport",
    "ParameterValue": "davenport"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Davis",
    "ParameterValue": "davis"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Dayton",
    "ParameterValue": "dayton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Dearborn",
    "ParameterValue": "dearborn"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Deltona",
    "ParameterValue": "deltona"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Denver",
    "ParameterValue": "denver"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Detroit",
    "ParameterValue": "detroit"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Dothan",
    "ParameterValue": "dothan"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Douglasville",
    "ParameterValue": "douglasville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Downey",
    "ParameterValue": "downey"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Dubuque",
    "ParameterValue": "dubuque"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Duluth",
    "ParameterValue": "duluth"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Dyer",
    "ParameterValue": "dyer"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Dyersburg",
    "ParameterValue": "dyersburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Edinburg",
    "ParameterValue": "edinburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Elgin",
    "ParameterValue": "elgin"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Elizabeth",
    "ParameterValue": "elizabeth"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Emporia",
    "ParameterValue": "emporia"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Enterprise",
    "ParameterValue": "enterprise"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Ephrata",
    "ParameterValue": "ephrata"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Erie",
    "ParameterValue": "erie"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Escondido",
    "ParameterValue": "escondido"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Eugene",
    "ParameterValue": "eugene"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Eunice",
    "ParameterValue": "eunice"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Evansville",
    "ParameterValue": "evansville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Everett",
    "ParameterValue": "everett"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Fairfax",
    "ParameterValue": "fairfax"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Fairmont",
    "ParameterValue": "fairmont"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Fernley",
    "ParameterValue": "fernley"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Florissant",
    "ParameterValue": "florissant"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Flushing",
    "ParameterValue": "flushing"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Franklin",
    "ParameterValue": "franklin"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Fredonia",
    "ParameterValue": "fredonia"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Fremont",
    "ParameterValue": "fremont"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Friendswood",
    "ParameterValue": "friendswood"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Fullerton",
    "ParameterValue": "fullerton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gaffney",
    "ParameterValue": "gaffney"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gainesville",
    "ParameterValue": "gainesville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gaithersburg",
    "ParameterValue": "gaithersburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Galax",
    "ParameterValue": "galax"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Garland",
    "ParameterValue": "garland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gilbert",
    "ParameterValue": "gilbert"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Girard",
    "ParameterValue": "girard"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Glendale",
    "ParameterValue": "glendale"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Glendora",
    "ParameterValue": "glendora"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gloversville",
    "ParameterValue": "gloversville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Goshen",
    "ParameterValue": "goshen"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Greeneville",
    "ParameterValue": "greeneville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Greenfield",
    "ParameterValue": "greenfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Greensboro",
    "ParameterValue": "greensboro"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Greensburg",
    "ParameterValue": "greensburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Greenville",
    "ParameterValue": "greenville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Greenwood",
    "ParameterValue": "greenwood"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Greer",
    "ParameterValue": "greer"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gresham",
    "ParameterValue": "gresham"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gurnee",
    "ParameterValue": "gurnee"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Gustine",
    "ParameterValue": "gustine"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hackensack",
    "ParameterValue": "hackensack"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hagerstown",
    "ParameterValue": "hagerstown"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Haledon",
    "ParameterValue": "haledon"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hammond",
    "ParameterValue": "hammond"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hanford",
    "ParameterValue": "hanford"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Harlingen",
    "ParameterValue": "harlingen"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Harrisburg",
    "ParameterValue": "harrisburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hartselle",
    "ParameterValue": "hartselle"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hawthorne",
    "ParameterValue": "hawthorne"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hayward",
    "ParameterValue": "hayward"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hemet",
    "ParameterValue": "hemet"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hempstead",
    "ParameterValue": "hempstead"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Henderson",
    "ParameterValue": "henderson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hercules",
    "ParameterValue": "hercules"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hernando",
    "ParameterValue": "hernando"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Herndon",
    "ParameterValue": "herndon"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hesperia",
    "ParameterValue": "hesperia"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hialeah",
    "ParameterValue": "hialeah"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Highland",
    "ParameterValue": "highland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hilliard",
    "ParameterValue": "hilliard"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hillsborough",
    "ParameterValue": "hillsborough"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hoboken",
    "ParameterValue": "hoboken"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Holland",
    "ParameterValue": "holland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hollister",
    "ParameterValue": "hollister"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hollywood",
    "ParameterValue": "hollywood"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Holyoke",
    "ParameterValue": "holyoke"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Houston",
    "ParameterValue": "houston"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hudson",
    "ParameterValue": "hudson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Huntington",
    "ParameterValue": "huntington"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Huntsville",
    "ParameterValue": "huntsville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Hyattsville",
    "ParameterValue": "hyattsville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Imperial",
    "ParameterValue": "imperial"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Indianapolis",
    "ParameterValue": "indianapolis"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Indio",
    "ParameterValue": "indio"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Jacksonville",
    "ParameterValue": "jacksonville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Janesville",
    "ParameterValue": "janesville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Jasper",
    "ParameterValue": "jasper"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Jeffersonville",
    "ParameterValue": "jeffersonville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Jenks",
    "ParameterValue": "jenks"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Johnsburg",
    "ParameterValue": "johnsburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kalamazoo",
    "ParameterValue": "kalamazoo"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kankakee",
    "ParameterValue": "kankakee"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Katy",
    "ParameterValue": "katy"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kearney",
    "ParameterValue": "kearney"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kearny",
    "ParameterValue": "kearny"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Keller",
    "ParameterValue": "keller"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kennesaw",
    "ParameterValue": "kennesaw"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kennett",
    "ParameterValue": "kennett"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kenosha",
    "ParameterValue": "kenosha"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kent",
    "ParameterValue": "kent"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Killeen",
    "ParameterValue": "killeen"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kissimmee",
    "ParameterValue": "kissimmee"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Knightdale",
    "ParameterValue": "knightdale"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Knoxville",
    "ParameterValue": "knoxville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Kokomo",
    "ParameterValue": "kokomo"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lafayette",
    "ParameterValue": "lafayette"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lakeland",
    "ParameterValue": "lakeland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lakeville",
    "ParameterValue": "lakeville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lamar",
    "ParameterValue": "lamar"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lancaster",
    "ParameterValue": "lancaster"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Laredo",
    "ParameterValue": "laredo"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Largo",
    "ParameterValue": "largo"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Laurel",
    "ParameterValue": "laurel"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Laurinburg",
    "ParameterValue": "laurinburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lawrenceburg",
    "ParameterValue": "lawrenceburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lawrenceville",
    "ParameterValue": "lawrenceville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Leander",
    "ParameterValue": "leander"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lebanon",
    "ParameterValue": "lebanon"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Leesburg",
    "ParameterValue": "leesburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lexington",
    "ParameterValue": "lexington"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Liberty",
    "ParameterValue": "liberty"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Littleton",
    "ParameterValue": "littleton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Livermore",
    "ParameterValue": "livermore"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Livingston",
    "ParameterValue": "livingston"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Livonia",
    "ParameterValue": "livonia"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Loganville",
    "ParameterValue": "loganville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lombard",
    "ParameterValue": "lombard"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Longview",
    "ParameterValue": "longview"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Louisville",
    "ParameterValue": "louisville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lowell",
    "ParameterValue": "lowell"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lyndhurst",
    "ParameterValue": "lyndhurst"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lynn",
    "ParameterValue": "lynn"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lynnwood",
    "ParameterValue": "lynnwood"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Lynwood",
    "ParameterValue": "lynwood"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Madera",
    "ParameterValue": "madera"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Madison",
    "ParameterValue": "madison"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mahomet",
    "ParameterValue": "mahomet"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Malvern",
    "ParameterValue": "malvern"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Manor",
    "ParameterValue": "manor"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mansfield",
    "ParameterValue": "mansfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Marion",
    "ParameterValue": "marion"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Maryville",
    "ParameterValue": "maryville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Matawan",
    "ParameterValue": "matawan"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Maywood",
    "ParameterValue": "maywood"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "McAllen",
    "ParameterValue": "mcallen"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "McDonough",
    "ParameterValue": "mcdonough"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "McKeesport",
    "ParameterValue": "mckeesport"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "McKinney",
    "ParameterValue": "mckinney"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mechanicsburg",
    "ParameterValue": "mechanicsburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Media",
    "ParameterValue": "media"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Memphis",
    "ParameterValue": "memphis"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Meridian",
    "ParameterValue": "meridian"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mesa",
    "ParameterValue": "mesa"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mesquite",
    "ParameterValue": "mesquite"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Miami",
    "ParameterValue": "miami"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Middleton",
    "ParameterValue": "middleton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Middletown",
    "ParameterValue": "middletown"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Midland",
    "ParameterValue": "midland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Midvale",
    "ParameterValue": "midvale"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Millsboro",
    "ParameterValue": "millsboro"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Milton",
    "ParameterValue": "milton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Milwaukee",
    "ParameterValue": "milwaukee"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Minneapolis",
    "ParameterValue": "minneapolis"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Minooka",
    "ParameterValue": "minooka"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mission",
    "ParameterValue": "mission"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mobile",
    "ParameterValue": "mobile"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Modesto",
    "ParameterValue": "modesto"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Monaca",
    "ParameterValue": "monaca"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Monroeville",
    "ParameterValue": "monroeville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Montgomery",
    "ParameterValue": "montgomery"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Morgantown",
    "ParameterValue": "morgantown"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Morrilton",
    "ParameterValue": "morrilton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Mundelein",
    "ParameterValue": "mundelein"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Nacogdoches",
    "ParameterValue": "nacogdoches"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Nampa",
    "ParameterValue": "nampa"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Nashville",
    "ParameterValue": "nashville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Newark",
    "ParameterValue": "newark"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Newburgh",
    "ParameterValue": "newburgh"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Newnan",
    "ParameterValue": "newnan"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Norfolk",
    "ParameterValue": "norfolk"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Norman",
    "ParameterValue": "norman"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Novi",
    "ParameterValue": "novi"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Oakland",
    "ParameterValue": "oakland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Oceanside",
    "ParameterValue": "oceanside"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Ogden",
    "ParameterValue": "ogden"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Olathe",
    "ParameterValue": "olathe"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Olney",
    "ParameterValue": "olney"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Omaha",
    "ParameterValue": "omaha"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Orange",
    "ParameterValue": "orange"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Orangeburg",
    "ParameterValue": "orangeburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Orlando",
    "ParameterValue": "orlando"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Oshkosh",
    "ParameterValue": "oshkosh"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Oxnard",
    "ParameterValue": "oxnard"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Paducah",
    "ParameterValue": "paducah"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Palmetto",
    "ParameterValue": "palmetto"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Paramus",
    "ParameterValue": "paramus"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Parkersburg",
    "ParameterValue": "parkersburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Parsons",
    "ParameterValue": "parsons"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Passaic",
    "ParameterValue": "passaic"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Paterson",
    "ParameterValue": "paterson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Peabody",
    "ParameterValue": "peabody"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Pensacola",
    "ParameterValue": "pensacola"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Peoria",
    "ParameterValue": "peoria"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Perkasie",
    "ParameterValue": "perkasie"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Perris",
    "ParameterValue": "perris"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Pflugerville",
    "ParameterValue": "pflugerville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Pharr",
    "ParameterValue": "pharr"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Philadelphia",
    "ParameterValue": "philadelphia"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Phoenix",
    "ParameterValue": "phoenix"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Pittsburg",
    "ParameterValue": "pittsburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Pittsburgh",
    "ParameterValue": "pittsburgh"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Pittsfield",
    "ParameterValue": "pittsfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Plainview",
    "ParameterValue": "plainview"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Plano",
    "ParameterValue": "plano"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Pontiac",
    "ParameterValue": "pontiac"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Portage",
    "ParameterValue": "portage"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Portland",
    "ParameterValue": "portland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Poughkeepsie",
    "ParameterValue": "poughkeepsie"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Princeton",
    "ParameterValue": "princeton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Providence",
    "ParameterValue": "providence"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Radford",
    "ParameterValue": "radford"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Rahway",
    "ParameterValue": "rahway"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Raleigh",
    "ParameterValue": "raleigh"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Redmond",
    "ParameterValue": "redmond"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Reedley",
    "ParameterValue": "reedley"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Reno",
    "ParameterValue": "reno"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Renton",
    "ParameterValue": "renton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Reynoldsburg",
    "ParameterValue": "reynoldsburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Richmond",
    "ParameterValue": "richmond"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Riverside",
    "ParameterValue": "riverside"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Riverview",
    "ParameterValue": "riverview"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Roanoke",
    "ParameterValue": "roanoke"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Rockford",
    "ParameterValue": "rockford"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Rockwood",
    "ParameterValue": "rockwood"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Rowlett",
    "ParameterValue": "rowlett"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Sacramento",
    "ParameterValue": "sacramento"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Salem",
    "ParameterValue": "salem"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Salina",
    "ParameterValue": "salina"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Sandy",
    "ParameterValue": "sandy"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Sapulpa",
    "ParameterValue": "sapulpa"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Saraland",
    "ParameterValue": "saraland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Sarasota",
    "ParameterValue": "sarasota"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Savannah",
    "ParameterValue": "savannah"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Scarsdale",
    "ParameterValue": "scarsdale"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Schenectady",
    "ParameterValue": "schenectady"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Scottsdale",
    "ParameterValue": "scottsdale"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Seattle",
    "ParameterValue": "seattle"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Secaucus",
    "ParameterValue": "secaucus"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Seneca",
    "ParameterValue": "seneca"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Sevierville",
    "ParameterValue": "sevierville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Shelbyville",
    "ParameterValue": "shelbyville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Shreveport",
    "ParameterValue": "shreveport"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Smithfield",
    "ParameterValue": "smithfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Snellville",
    "ParameterValue": "snellville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Somerset",
    "ParameterValue": "somerset"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Southaven",
    "ParameterValue": "southaven"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Southfield",
    "ParameterValue": "southfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Southlake",
    "ParameterValue": "southlake"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Spartanburg",
    "ParameterValue": "spartanburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Spokane",
    "ParameterValue": "spokane"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Springfield",
    "ParameterValue": "springfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Stafford",
    "ParameterValue": "stafford"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Statesboro",
    "ParameterValue": "statesboro"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Statesville",
    "ParameterValue": "statesville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Stockton",
    "ParameterValue": "stockton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Suffolk",
    "ParameterValue": "suffolk"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Summerville",
    "ParameterValue": "summerville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Sumner",
    "ParameterValue": "sumner"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Sunnyvale",
    "ParameterValue": "sunnyvale"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tacoma",
    "ParameterValue": "tacoma"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tallahassee",
    "ParameterValue": "tallahassee"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tampa",
    "ParameterValue": "tampa"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Temecula",
    "ParameterValue": "temecula"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tempe",
    "ParameterValue": "tempe"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Texarkana",
    "ParameterValue": "texarkana"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Thibodaux",
    "ParameterValue": "thibodaux"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Thomasville",
    "ParameterValue": "thomasville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Trenton",
    "ParameterValue": "trenton"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Trinity",
    "ParameterValue": "trinity"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tucson",
    "ParameterValue": "tucson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tulsa",
    "ParameterValue": "tulsa"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tupelo",
    "ParameterValue": "tupelo"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Tyler",
    "ParameterValue": "tyler"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Urbandale",
    "ParameterValue": "urbandale"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Vallejo",
    "ParameterValue": "vallejo"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Vicksburg",
    "ParameterValue": "vicksburg"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Victorville",
    "ParameterValue": "victorville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Vineland",
    "ParameterValue": "vineland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Waltham",
    "ParameterValue": "waltham"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Warren",
    "ParameterValue": "warren"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Washington",
    "ParameterValue": "washington"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Waterbury",
    "ParameterValue": "waterbury"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Waterville",
    "ParameterValue": "waterville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Watsonville",
    "ParameterValue": "watsonville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Waverly",
    "ParameterValue": "waverly"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Wayne",
    "ParameterValue": "wayne"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Waynesville",
    "ParameterValue": "waynesville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Westbrook",
    "ParameterValue": "westbrook"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Westerville",
    "ParameterValue": "westerville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Westfield",
    "ParameterValue": "westfield"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Westland",
    "ParameterValue": "westland"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Westminster",
    "ParameterValue": "westminster"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Whittier",
    "ParameterValue": "whittier"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Wichita",
    "ParameterValue": "wichita"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Wilkes-Barre",
    "ParameterValue": "wilkes-barre"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Wilmington",
    "ParameterValue": "wilmington"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Wilson",
    "ParameterValue": "wilson"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Winston-Salem",
    "ParameterValue": "winston-salem"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Woodhaven",
    "ParameterValue": "woodhaven"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Woodinville",
    "ParameterValue": "woodinville"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Woodside",
    "ParameterValue": "woodside"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Woodstock",
    "ParameterValue": "woodstock"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Yonkers",
    "ParameterValue": "yonkers"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Ypsilanti",
    "ParameterValue": "ypsilanti"
  },
  {
    "Country": "US",
    "CountryName": "United States of America",
    "City": "Yucaipa",
    "ParameterValue": "yucaipa"
  },
  {
    "Country": "UY",
    "CountryName": "Uruguay",
    "City": "Aigua",
    "ParameterValue": "aigua"
  },
  {
    "Country": "UY",
    "CountryName": "Uruguay",
    "City": "Canelones",
    "ParameterValue": "canelones"
  },
  {
    "Country": "UY",
    "CountryName": "Uruguay",
    "City": "Castillos",
    "ParameterValue": "castillos"
  },
  {
    "Country": "UY",
    "CountryName": "Uruguay",
    "City": "Maldonado",
    "ParameterValue": "maldonado"
  },
  {
    "Country": "UY",
    "CountryName": "Uruguay",
    "City": "Minas",
    "ParameterValue": "minas"
  },
  {
    "Country": "UY",
    "CountryName": "Uruguay",
    "City": "Montevideo",
    "ParameterValue": "montevideo"
  },
  {
    "Country": "UY",
    "CountryName": "Uruguay",
    "City": "Salto",
    "ParameterValue": "salto"
  },
  {
    "Country": "UZ",
    "CountryName": "Uzbekistan",
    "City": "Navoiy",
    "ParameterValue": "navoiy"
  },
  {
    "Country": "UZ",
    "CountryName": "Uzbekistan",
    "City": "Tashkent",
    "ParameterValue": "tashkent"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Barinas",
    "ParameterValue": "barinas"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Barquisimeto",
    "ParameterValue": "barquisimeto"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Cabimas",
    "ParameterValue": "cabimas"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Caracas",
    "ParameterValue": "caracas"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Carora",
    "ParameterValue": "carora"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Coro",
    "ParameterValue": "coro"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Maiquetia",
    "ParameterValue": "maiquetia"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Maracaibo",
    "ParameterValue": "maracaibo"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Maracay",
    "ParameterValue": "maracay"
  },
  {
    "Country": "VE",
    "CountryName": "Venezuela (Bolivarian Republic)",
    "City": "Porlamar",
    "ParameterValue": "porlamar"
  },
  {
    "Country": "VN",
    "CountryName": "Viet Nam",
    "City": "Haiphong",
    "ParameterValue": "haiphong"
  },
  {
    "Country": "VN",
    "CountryName": "Viet Nam",
    "City": "Hanoi",
    "ParameterValue": "hanoi"
  },
  {
    "Country": "VN",
    "CountryName": "Viet Nam",
    "City": "Vinh",
    "ParameterValue": "vinh"
  },
  {
    "Country": "YE",
    "CountryName": "Yemen",
    "City": "Aden",
    "ParameterValue": "aden"
  },
  {
    "Country": "YE",
    "CountryName": "Yemen",
    "City": "Hajjah",
    "ParameterValue": "hajjah"
  },
  {
    "Country": "YE",
    "CountryName": "Yemen",
    "City": "Ibb",
    "ParameterValue": "ibb"
  },
  {
    "Country": "YE",
    "CountryName": "Yemen",
    "City": "Lahij",
    "ParameterValue": "lahij"
  },
  {
    "Country": "YE",
    "CountryName": "Yemen",
    "City": "Sanaa",
    "ParameterValue": "sanaa"
  },
  {
    "Country": "ZM",
    "CountryName": "Zambia",
    "City": "Lusaka",
    "ParameterValue": "lusaka"
  },
  {
    "Country": "ZM",
    "CountryName": "Zambia",
    "City": "Solwezi",
    "ParameterValue": "solwezi"
  },
  {
    "Country": "ZW",
    "CountryName": "Zimbabwe",
    "City": "Bulawayo",
    "ParameterValue": "bulawayo"
  },
  {
    "Country": "ZW",
    "CountryName": "Zimbabwe",
    "City": "Harare",
    "ParameterValue": "harare"
  }
];

export {
  resiPremiumCities
};