import React from "react";
// reactstrap components
import { Row, Col, Button } from "reactstrap";


const Main = () => {
  return (
    <section className="col-12 wow" id="home">
      <div className="home-container">
        <Row className="align-items-center">
          <Col md="5">
            <h1 className="title wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              The <strong>only</strong> provider<br /> you need
            </h1>
            <p className="info wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              Private Proxies with Superior Performance Across All Site Types
            </p>
            <Button
              data-wow-duration="2s"
              outline
              color="secondary"
              className="btn-round btn-goto-purchase wow animate__animated animate__fadeInDown"
              size="md"
              href="#pricing"
            >
              Purchase
            </Button>
          </Col>
          <Col md="7">
            <img
              alt="Generate"
              className="img-generate wow animate__animated animate__generate"
              src={require("assets/img/Generate.png")}
              data-wow-duration="2s"
              data-wow-delay="0s" />
            <img
              alt="ProxyList"
              className="img-proxylist wow animate__animated animate__proxylist"
              src={require("assets/img/ProxyList.png")}
              data-wow-duration="2s"
              data-wow-delay="1s" />
            <img
              alt="Datacenter"
              className="img-datacenter wow animate__animated animate__datacenter"
              src={require("assets/img/Datacenter.png")}
              data-wow-duration="2s"
              data-wow-delay="2s" />
            <img
              alt="Usage"
              className="img-usage wow animate__animated animate__usage"
              src={require("assets/img/Usage.png")}
              data-wow-duration="2s"
              data-wow-delay="3s" />
            <img
              alt="Control"
              className="img-control wow animate__animated animate__control"
              src={require("assets/img/Control.png")}
              data-wow-duration="2s"
              data-wow-delay="4s" />
            <img
              alt="Account"
              className="img-account wow animate__animated animate__account"
              src={require("assets/img/Account.png")}
              data-wow-duration="2s"
              data-wow-delay="5s" />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Main;