import React from "react";
import toastr from 'toastr';

/*
  Functional component that render page loading with custom css
 */
const PageLoader = () => {
  return (
    <div className="preloader">
      <div className="preloader-container">
        <div className="preloader-loading-screen"></div>
      </div>
    </div>
  );
}

/*
  Function that show toastr based on toastType(success,error,info)
 */
const showToastAlert = (toastType, toastMessage) => {
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  }

  if (toastType.toLowerCase() === 'success') {
    toastr.success(toastMessage, 'Success')
  } else if (toastType.toLowerCase() === 'info') {
    toastr.info(toastMessage, 'Info')
  } else {
    toastr.error(toastMessage, 'Error')
  }
}

/**
 * [Function that generate Random String of param length]
 */
function generateRandomString(length) {
  let string = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    string += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return string;
}

/**
 * [Function that parse url and returns object of params]
 */
function parseURLParams(url) {
  let queryStart = url.indexOf("?") + 1,
    queryEnd = url.indexOf("#") + 1 || url.length + 1,
    query = url.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = {},
    i, n, v, nv;

  if (query === url || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);

    if (!parms.hasOwnProperty(n)) parms[n] = [];
    parms[n].push(nv.length === 2 ? v : null);
  }
  return parms;
}

/*
  Function that return date in MMDDYYYY for given date selerator
 */
const getDate = (date = new Date(), seperator = "/") => {
  let year = String(date.getFullYear());
  let month = String(date.getMonth() + 1);
  month = month.length === 1 ? `0${month}` : month;
  let day = String(date.getDate());
  day = day.length === 1 ? `0${day}` : day;
  return `${month}${seperator}${day}${seperator}${year}`;
}

/**
 * [Function that check given ip is valid ipv4 or not]
 */
const validateIPv4 = (ipv4) => {
  let ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  if (ipv4.match(ipformat)) {
    return true;
  }
  showToastAlert('Error', 'Please add valid IP');
  return false;
}

/*
  Function that copy passed data
 */
const copyData = (data) => {
  let textarea = document.createElement('textarea');
  textarea.value = data
  document.body.appendChild(textarea);
  textarea.select();
  try {
    let successful = document.execCommand('copy');
    document.body.removeChild(textarea);
    if (successful) {
      showToastAlert('Success', 'Proxy copied successfully');
    } else {
      showToastAlert('Error', "Copy functionality isn't working in current browser, Please use Download option");
    }
  } catch (err) {
    showToastAlert('Error', "Copy functionality isn't working in current browser, Please use Download option");
  }
}

/*
  Function that copy passed data
 */
const downloadFile = (fileName, fileData) => {
  let data = new Blob([fileData], {
    type: 'text/plain'
  });
  let file = window.URL.createObjectURL(data);
  let link = document.createElement('a');
  link.setAttribute('href', file);
  link.setAttribute('download', fileName);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export {
  PageLoader,
  showToastAlert,
  generateRandomString,
  parseURLParams,
  getDate,
  validateIPv4,
  copyData,
  downloadFile
};
