import React from 'react';
import { Container, Row, Col } from 'reactstrap';


const RefundPolicy = () => {
  return (
    <Container>
      <Row>
        <Col xs="12" className="wow animate__animated animate__fadeInDown" data-wow-duration="2s">
          <h1 className="page-title">Refund Policy</h1>
          <p className="description text-center font-weight-bold">The Customer of "HollowProxies, LLC" Acknowledges That:</p>
          <p className="description">• All Sales are <strong>Final</strong>. There will be no refunds given to digital products.


</p>

        </Col>
      </Row>
    </Container>
  )
}

export default RefundPolicy;