import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { FirebaseContext } from '../../contexts';
import {
  showToastAlert,
  parseURLParams
} from '../../Services/Utility';


const Header = () => {
  const { user } = useContext(FirebaseContext);
  const { firebase } = useContext(FirebaseContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);

  useEffect(() => {
    let urlString = window.location.href;
    let urlParams = parseURLParams(urlString);
    let currentUrl = urlString.split('?')[0];
    window.history.replaceState({}, document.title, currentUrl);
    if (urlParams) {
      if (urlParams.status && urlParams.status[0] === 'success') {
        try {
          showToastAlert('success', 'Discord successfully verified');
        } catch (error) {
          showToastAlert('Error', 'Error while verifying Discord');
        }
      } else if (urlParams.message) {
        showToastAlert('Error', urlParams.message[0]);
      }
    }
  }, []);

  const verifyDiscord = async () => {
    let idToken = await firebase.auth.currentUser.getIdToken(true);
    let url = `${window.location.origin}${window.location.pathname}`;
    let state = JSON.stringify({
      'redirect_url': url,
      idToken: idToken
    });
    let discordUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}/discord/callback&response_type=code&scope=identify%20email%20guilds%20guilds.join&state=${state}`;
    window.location.href = discordUrl;
  }

  return (
    <header id="header">
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Navbar className="w-100 bg-darker" color="dark" dark expand="md">
            <NavbarBrand to="/" tag={Link}>
              <img
                alt="Hollow Proxies"
                className="logo"
                src={require("assets/img/logo.png")}
              />
            </NavbarBrand>
            <NavbarToggler
              className={isOpen ? "open" : "close"}
              onClick={toggleNavbar}
            />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="align-items-center mr-auto mx-md-auto" navbar>
                <NavItem>
                  <NavLink href="/#home">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/#features">Features</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/#pricing">Pricing</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/#faq">FAQ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/#socials">Socials</NavLink>
                </NavItem>
                {user ? (
                  <>
                    <NavItem className="nav-dashboard">
                      <Button
                        outline
                        href="/datacenter"
                        className="nav-link btn-dashboard"
                        color="default"
                        size="md">
                        Dashboard
                      </Button>
                    </NavItem>
                    <NavItem className="nav-discord">
                      <Button
                        onClick={verifyDiscord}
                        outline
                        className="nav-link btn-discord"
                        color="default"
                        size="md">
                        Verify Discord
                      </Button>
                    </NavItem>
                    <NavItem className="nav-logout">
                      <Button
                        onClick={firebase.doSignOut}
                        outline
                        className="nav-link btn-logout"
                        color="default"
                        size="md">
                        Logout
                      </Button>
                    </NavItem>
                  </>
                ) : (
                    <>
                      <NavItem className="nav-login">
                        <Button
                          outline
                          href="/login"
                          className="nav-link btn-login"
                          color="default"
                          size="md">
                          Log in
                        </Button>
                      </NavItem>
                    </>
                  )}
              </Nav>
            </Collapse>
          </Navbar>
        </Row>
      </Container>
    </header>
  )
}

export default Header;