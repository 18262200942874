import React from 'react';
import { Container, Col } from 'reactstrap';


const Error404 = () => {
  return (
    <Container>
      <Col className="error404-page">
        <div className="wow animate__animated animate__fadeInDown" data-wow-duration="1s">
          <h1 className="page-title">404</h1>
          <p className="description">{"Page not found :("}</p>
          <p className="sub-description">Ooooups! Looks like you got lost.</p>
        </div>
      </Col>
    </Container>
  )
}

export default Error404;