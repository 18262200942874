import React from "react";
// reactstrap components
import { Row, Col, Button } from "reactstrap";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name : props.name,
      avatar : props.avatar?props.avatar : null,
      discordData : props.discordData? props.discordData : null
    };
    
  }

  componentDidUpdate(prevProps) {
    if(prevProps.name !== this.props.name || prevProps.discordData !== this.props.discordData) {
      this.setState({name: this.props.name, discordData: this.props.discordData});
    } 
    
  }
  

  render(){
  return (
    <div className="col-sm-12">
          <div className="second-top">
            <div className="s-left">
              {this.state.discordData && this.state.discordData.avatar && 
                <img src={"https://cdn.discordapp.com/avatars/517255041170800640/b5eb9fb3832ae7428c6638a62e5fda54.png?size=40"} alt=""/>
              }
              <div className="profile-details pl-3">
                <div className="welcome">Welcome back,</div>
                <div className="p-name">{this.state.discordData?this.state.discordData.username:this.state.name}</div>
              </div>
            </div>
            <div className="s-right">
              {/* <div className="box-grey mr-2">
                <img src={require("assets/img/support.svg")} alt=""/>
                <div className="profile-details pl-3">
                  <div className="welcome">Need Help?</div>
                  <div className="p-name">Support</div>
                </div>
              </div>
              <div className="box-grey">
                <img src={require("assets/img/discord.png")} alt=""/>
                <div className="profile-details pl-3">
                  <div className="welcome">Discord</div>
                  <div className="p-name">Join Server</div>
                </div>
              </div> */}
            </div>
          </div>
      </div>  );
  }
}

export default Header;