import React from 'react';
import { Container, Col, Button } from 'reactstrap';


const Error = () => {
  return (
    <Container>
      <Col className="error-page">
        <div className="wow animate__animated animate__fadeInDown" data-wow-duration="1s">
          <h1 className="page-title">{"Order cancelled! :("}</h1>
          <p className="description">Something went wrong with your payment, Please try again later.</p>
          <div className="mt-3">
            <Button
              data-wow-duration="2s"
              outline
              color="secondary"
              className="btn-round btn-dashboard wow animate__animated animate__fadeInDown"
              size="md"
              href="/dashboard"
            >
              Dashboard
            </Button>
          </div>
        </div>
      </Col>
    </Container>
  )
}

export default Error;