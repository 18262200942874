import React, { useState } from "react";
// reactstrap components
import { Row, Col, Card, CardHeader, CardBody, Collapse } from "reactstrap";


const FAQ = () => {
  const [faqs, setFAQs] = useState([
    {
      title: 'How do I purchase proxies?',
      body: 'Only customers in our private Discord can purchase proxies. Follow us on social media for a chance to join.',
      isOpen: false,
      id: 1
    }, {
      title: 'When will proxy plans restock?',
      body: 'Restocks occur exclusively in our Discord server at regular intervals. Restock times are announced in advance inside the #restocks channel.',
      isOpen: false,
      id: 2
    }, {
      title: 'What type of authentication is available?',
      body: 'All residential proxies are user:pass authentication. Datacenter proxies may arrive as IP authentication or user:pass authentication.',
      isOpen: false,
      id: 3
    }, {
      title: 'Where are the proxies located?',
      body: 'We have millions of proxies located globally, and you can generate proxies specific to countries, states, and even cities. Additionally, there are pools offered that have been handcrafted to target certain sites, such as Footsites, YeezySupply, and Shoepalace.',
      isOpen: false,
      id: 4
    }, {
      title: 'When will my proxies be delivered?',
      body: 'Proxies are delivered instantly to your dashboard upon purchase. You will be able to generate residential proxies on the dashboard immediately after purchase. Also on the dashboard you will be able to find your datacenter proxy list, and can manage your IP authentication.',
      isOpen: false,
      id: 5
    }, {
      title: 'Where can I get additional support?',
      body: 'Support is available in the Discord server via a ticket system. There are quick guides found under the Guidance section on the dashboard.',
      isOpen: false,
      id: 6
    }
  ]);

  const toggleFAQsOpen = (id) => {
    let faqCopy = [...faqs];
    faqCopy.map(faq => {
      if (faq.id === parseInt(id)) {
        faq.isOpen = !faq.isOpen
      }
      return faq;
    });
    setFAQs([...faqCopy]);
  }

  return (
    <section className="col-12 wow" id="faq">
      <Row>
        <Col xs="12">
          <h1 className="page-title">Frequently Asked Questions</h1>
        </Col>
        {faqs.map(faq => {
          return (
            <Col md="6" className="mt-3" key={faq.id}>
              <Card className="faq-card wow animate__animated animate__fadeInDown" data-wow-duration="2s">
                <CardHeader className={faq.isOpen ? "card-link open" : "card-link"} onClick={() => toggleFAQsOpen(faq.id)}>
                  <span>{faq.title}</span>
                </CardHeader>
                <Collapse isOpen={faq.isOpen}>
                  <CardBody>
                    {faq.body}
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          )
        })}
      </Row>
    </section>
  );
}

export default FAQ;