import { resiPremiumASN } from './ASN';
import { resiPremiumCities } from './Cities';
import { resiPremiumCountries } from './Countries';
var resiPremiumSites = require('./premSite.json');

export {
    resiPremiumASN,
    resiPremiumCities,
    resiPremiumCountries,
    resiPremiumSites
};