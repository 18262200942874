const resiPremiumASN = [
  {
    "ASN": 15802,
    "Carrier": "Du",
    "Country": "ae"
  },
  {
    "ASN": 5384,
    "Carrier": "Etisalat",
    "Country": "ae"
  },
  {
    "ASN": 13335,
    "Carrier": "Etisalat",
    "Country": "ae"
  },
  {
    "ASN": 19037,
    "Carrier": "Claro",
    "Country": "ar"
  },
  {
    "ASN": 11315,
    "Carrier": "Movistar",
    "Country": "ar"
  },
  {
    "ASN": 27871,
    "Carrier": "Nextel",
    "Country": "ar"
  },
  {
    "ASN": 7303,
    "Carrier": "Personal",
    "Country": "ar"
  },
  {
    "ASN": 16305,
    "Carrier": "A1",
    "Country": "at"
  },
  {
    "ASN": 1901,
    "Carrier": "A1",
    "Country": "at"
  },
  {
    "ASN": 8447,
    "Carrier": "A1",
    "Country": "at"
  },
  {
    "ASN": 12635,
    "Carrier": "Drei",
    "Country": "at"
  },
  {
    "ASN": 25255,
    "Carrier": "Drei",
    "Country": "at"
  },
  {
    "ASN": 8437,
    "Carrier": "Tele2",
    "Country": "at"
  },
  {
    "ASN": 8412,
    "Carrier": "T-Mobile",
    "Country": "at"
  },
  {
    "ASN": 55543,
    "Carrier": "Lycamobile",
    "Country": "au"
  },
  {
    "ASN": 4804,
    "Carrier": "Optus",
    "Country": "au"
  },
  {
    "ASN": 4778,
    "Carrier": "Optus",
    "Country": "au"
  },
  {
    "ASN": 1221,
    "Carrier": "Telstra",
    "Country": "au"
  },
  {
    "ASN": 45510,
    "Carrier": "Telstra",
    "Country": "au"
  },
  {
    "ASN": 7545,
    "Carrier": "TPG",
    "Country": "au"
  },
  {
    "ASN": 133612,
    "Carrier": "Vodafone",
    "Country": "au"
  },
  {
    "ASN": 56138,
    "Carrier": "Airtel",
    "Country": "bd"
  },
  {
    "ASN": 38069,
    "Carrier": "Airtel",
    "Country": "bd"
  },
  {
    "ASN": 45245,
    "Carrier": "Banglalink",
    "Country": "bd"
  },
  {
    "ASN": 24389,
    "Carrier": "Grameenphone",
    "Country": "bd"
  },
  {
    "ASN": 24432,
    "Carrier": "Robi",
    "Country": "bd"
  },
  {
    "ASN": 45925,
    "Carrier": "TeleTalk",
    "Country": "bd"
  },
  {
    "ASN": 44944,
    "Carrier": "Base",
    "Country": "be"
  },
  {
    "ASN": 47377,
    "Carrier": "Orange",
    "Country": "be"
  },
  {
    "ASN": 5432,
    "Carrier": "Proximus",
    "Country": "be"
  },
  {
    "ASN": 6848,
    "Carrier": "Telenet",
    "Country": "be"
  },
  {
    "ASN": 12716,
    "Carrier": "A1",
    "Country": "bg"
  },
  {
    "ASN": 43205,
    "Carrier": "Bulsatcom",
    "Country": "bg"
  },
  {
    "ASN": 42735,
    "Carrier": "Max",
    "Country": "bg"
  },
  {
    "ASN": 29244,
    "Carrier": "Telenor",
    "Country": "bg"
  },
  {
    "ASN": 2944,
    "Carrier": "Telenor",
    "Country": "bg"
  },
  {
    "ASN": 8866,
    "Carrier": "Vivacom",
    "Country": "bg"
  },
  {
    "ASN": 51375,
    "Carrier": "VIVA",
    "Country": "bh"
  },
  {
    "ASN": 10101,
    "Carrier": "DST",
    "Country": "bn"
  },
  {
    "ASN": 16735,
    "Carrier": "Algar Telecom",
    "Country": "br"
  },
  {
    "ASN": 53006,
    "Carrier": "Algar Telecom",
    "Country": "br"
  },
  {
    "ASN": 22085,
    "Carrier": "Claro",
    "Country": "br"
  },
  {
    "ASN": 4230,
    "Carrier": "Claro",
    "Country": "br"
  },
  {
    "ASN": 28573,
    "Carrier": "Claro",
    "Country": "br"
  },
  {
    "ASN": 53037,
    "Carrier": "Nextel",
    "Country": "br"
  },
  {
    "ASN": 8167,
    "Carrier": "OI",
    "Country": "br"
  },
  {
    "ASN": 7738,
    "Carrier": "OI",
    "Country": "br"
  },
  {
    "ASN": 22689,
    "Carrier": "Sercomtel",
    "Country": "br"
  },
  {
    "ASN": 26615,
    "Carrier": "TIM",
    "Country": "br"
  },
  {
    "ASN": 27699,
    "Carrier": "Vivo",
    "Country": "br"
  },
  {
    "ASN": 18881,
    "Carrier": "Vivo",
    "Country": "br"
  },
  {
    "ASN": 26599,
    "Carrier": "Vivo",
    "Country": "br"
  },
  {
    "ASN": 37447,
    "Carrier": "Tigo",
    "Country": "cd"
  },
  {
    "ASN": 37453,
    "Carrier": "Vodacom",
    "Country": "cd"
  },
  {
    "ASN": 15796,
    "Carrier": "Salt",
    "Country": "ch"
  },
  {
    "ASN": 6730,
    "Carrier": "Sunrise",
    "Country": "ch"
  },
  {
    "ASN": 203855,
    "Carrier": "Sunrise",
    "Country": "ch"
  },
  {
    "ASN": 57370,
    "Carrier": "Swisscom",
    "Country": "ch"
  },
  {
    "ASN": 3303,
    "Carrier": "Swisscom",
    "Country": "ch"
  },
  {
    "ASN": 8300,
    "Carrier": "Swisscom",
    "Country": "ch"
  },
  {
    "ASN": 36974,
    "Carrier": "MTN",
    "Country": "ci"
  },
  {
    "ASN": 29571,
    "Carrier": "Orange",
    "Country": "ci"
  },
  {
    "ASN": 27995,
    "Carrier": "Claro",
    "Country": "cl"
  },
  {
    "ASN": 27925,
    "Carrier": "Entel",
    "Country": "cl"
  },
  {
    "ASN": 6471,
    "Carrier": "Entel",
    "Country": "cl"
  },
  {
    "ASN": 27651,
    "Carrier": "Entel",
    "Country": "cl"
  },
  {
    "ASN": 7418,
    "Carrier": "Movistar",
    "Country": "cl"
  },
  {
    "ASN": 22047,
    "Carrier": "VTR",
    "Country": "cl"
  },
  {
    "ASN": 52341,
    "Carrier": "WOM",
    "Country": "cl"
  },
  {
    "ASN": 30992,
    "Carrier": "MTN",
    "Country": "cm"
  },
  {
    "ASN": 26611,
    "Carrier": "Claro",
    "Country": "co"
  },
  {
    "ASN": 14080,
    "Carrier": "Claro",
    "Country": "co"
  },
  {
    "ASN": 27831,
    "Carrier": "Tigo",
    "Country": "co"
  },
  {
    "ASN": 11830,
    "Carrier": "ICE",
    "Country": "cr"
  },
  {
    "ASN": 262202,
    "Carrier": "Movistar",
    "Country": "cr"
  },
  {
    "ASN": 15805,
    "Carrier": "MTN",
    "Country": "cy"
  },
  {
    "ASN": 40964,
    "Carrier": "Astelnet",
    "Country": "cz"
  },
  {
    "ASN": 13036,
    "Carrier": "T-Mobile",
    "Country": "cz"
  },
  {
    "ASN": 16019,
    "Carrier": "Vodafone",
    "Country": "cz"
  },
  {
    "ASN": 702,
    "Carrier": "O2",
    "Country": "de"
  },
  {
    "ASN": 39706,
    "Carrier": "O2",
    "Country": "de"
  },
  {
    "ASN": 12638,
    "Carrier": "O2",
    "Country": "de"
  },
  {
    "ASN": 3320,
    "Carrier": "Telekom",
    "Country": "de"
  },
  {
    "ASN": 3209,
    "Carrier": "Vodafone",
    "Country": "de"
  },
  {
    "ASN": 6400,
    "Carrier": "Claro",
    "Country": "do"
  },
  {
    "ASN": 23487,
    "Carrier": "Claro",
    "Country": "ec"
  },
  {
    "ASN": 14420,
    "Carrier": "CNT",
    "Country": "ec"
  },
  {
    "ASN": 28006,
    "Carrier": "CNT",
    "Country": "ec"
  },
  {
    "ASN": 19114,
    "Carrier": "Movistar",
    "Country": "ec"
  },
  {
    "ASN": 36992,
    "Carrier": "Etisalat",
    "Country": "eg"
  },
  {
    "ASN": 37069,
    "Carrier": "Orange",
    "Country": "eg"
  },
  {
    "ASN": 30995,
    "Carrier": "Vodafone",
    "Country": "eg"
  },
  {
    "ASN": 24835,
    "Carrier": "Vodafone",
    "Country": "eg"
  },
  {
    "ASN": 36935,
    "Carrier": "Vodafone",
    "Country": "eg"
  },
  {
    "ASN": 8239,
    "Carrier": "BT",
    "Country": "es"
  },
  {
    "ASN": 8903,
    "Carrier": "BT",
    "Country": "es"
  },
  {
    "ASN": 12541,
    "Carrier": "BT",
    "Country": "es"
  },
  {
    "ASN": 57269,
    "Carrier": "DigiMobil",
    "Country": "es"
  },
  {
    "ASN": 12338,
    "Carrier": "Euskaltel",
    "Country": "es"
  },
  {
    "ASN": 12715,
    "Carrier": "Jazztel",
    "Country": "es"
  },
  {
    "ASN": 3352,
    "Carrier": "Movistar",
    "Country": "es"
  },
  {
    "ASN": 12479,
    "Carrier": "Orange",
    "Country": "es"
  },
  {
    "ASN": 12386,
    "Carrier": "Orange",
    "Country": "es"
  },
  {
    "ASN": 16372,
    "Carrier": "Orange",
    "Country": "es"
  },
  {
    "ASN": 20921,
    "Carrier": "Orange",
    "Country": "es"
  },
  {
    "ASN": 12357,
    "Carrier": "Vodafone",
    "Country": "es"
  },
  {
    "ASN": 12430,
    "Carrier": "Vodafone",
    "Country": "es"
  },
  {
    "ASN": 16299,
    "Carrier": "Yoigo",
    "Country": "es"
  },
  {
    "ASN": 16086,
    "Carrier": "DNA",
    "Country": "fi"
  },
  {
    "ASN": 719,
    "Carrier": "Elisa",
    "Country": "fi"
  },
  {
    "ASN": 1759,
    "Carrier": "Telia",
    "Country": "fi"
  },
  {
    "ASN": 21093,
    "Carrier": "Bouygues",
    "Country": "fr"
  },
  {
    "ASN": 5410,
    "Carrier": "Bouygues",
    "Country": "fr"
  },
  {
    "ASN": 51207,
    "Carrier": "Free",
    "Country": "fr"
  },
  {
    "ASN": 3215,
    "Carrier": "Orange",
    "Country": "fr"
  },
  {
    "ASN": 25186,
    "Carrier": "Orange",
    "Country": "fr"
  },
  {
    "ASN": 28708,
    "Carrier": "Orange",
    "Country": "fr"
  },
  {
    "ASN": 5511,
    "Carrier": "Orange",
    "Country": "fr"
  },
  {
    "ASN": 15557,
    "Carrier": "SFR",
    "Country": "fr"
  },
  {
    "ASN": 12576,
    "Carrier": "EE",
    "Country": "gb"
  },
  {
    "ASN": 31404,
    "Carrier": "Lycamobile",
    "Country": "gb"
  },
  {
    "ASN": 29180,
    "Carrier": "O2",
    "Country": "gb"
  },
  {
    "ASN": 34825,
    "Carrier": "O2",
    "Country": "gb"
  },
  {
    "ASN": 15169,
    "Carrier": "O2",
    "Country": "gb"
  },
  {
    "ASN": 60339,
    "Carrier": "Three",
    "Country": "gb"
  },
  {
    "ASN": 25135,
    "Carrier": "Vodafone",
    "Country": "gb"
  },
  {
    "ASN": 37140,
    "Carrier": "AirtelTigo",
    "Country": "gh"
  },
  {
    "ASN": 37255,
    "Carrier": "MTN",
    "Country": "gh"
  },
  {
    "ASN": 29614,
    "Carrier": "Vodafone",
    "Country": "gh"
  },
  {
    "ASN": 16022,
    "Carrier": "Cosmoline",
    "Country": "gr"
  },
  {
    "ASN": 29247,
    "Carrier": "Cosmote",
    "Country": "gr"
  },
  {
    "ASN": 12361,
    "Carrier": "Vodafone",
    "Country": "gr"
  },
  {
    "ASN": 15617,
    "Carrier": "Wind",
    "Country": "gr"
  },
  {
    "ASN": 25472,
    "Carrier": "Wind",
    "Country": "gr"
  },
  {
    "ASN": 52362,
    "Carrier": "Claro",
    "Country": "gt"
  },
  {
    "ASN": 12127,
    "Carrier": "Movistar",
    "Country": "gt"
  },
  {
    "ASN": 23243,
    "Carrier": "Tigo",
    "Country": "gt"
  },
  {
    "ASN": 14754,
    "Carrier": "Claro",
    "Country": "hn"
  },
  {
    "ASN": 7727,
    "Carrier": "HonduTel",
    "Country": "hn"
  },
  {
    "ASN": 52262,
    "Carrier": "Tigo",
    "Country": "hn"
  },
  {
    "ASN": 5391,
    "Carrier": "T-mobile",
    "Country": "hr"
  },
  {
    "ASN": 31012,
    "Carrier": "Vip",
    "Country": "hr"
  },
  {
    "ASN": 27653,
    "Carrier": "Digicel",
    "Country": "ht"
  },
  {
    "ASN": 52260,
    "Carrier": "Natcom",
    "Country": "ht"
  },
  {
    "ASN": 5483,
    "Carrier": "Magyar Telekom",
    "Country": "hu"
  },
  {
    "ASN": 8448,
    "Carrier": "Telenor",
    "Country": "hu"
  },
  {
    "ASN": 21334,
    "Carrier": "Vodafone",
    "Country": "hu"
  },
  {
    "ASN": 38494,
    "Carrier": "Bakrie Telecom",
    "Country": "id"
  },
  {
    "ASN": 38149,
    "Carrier": "Bakrie Telecom",
    "Country": "id"
  },
  {
    "ASN": 4761,
    "Carrier": "Indosat Ooredoo",
    "Country": "id"
  },
  {
    "ASN": 4832,
    "Carrier": "Internux",
    "Country": "id"
  },
  {
    "ASN": 133798,
    "Carrier": "Smartfren",
    "Country": "id"
  },
  {
    "ASN": 23693,
    "Carrier": "Telkomsel",
    "Country": "id"
  },
  {
    "ASN": 17974,
    "Carrier": "Telkomsel",
    "Country": "id"
  },
  {
    "ASN": 17885,
    "Carrier": "XL",
    "Country": "id"
  },
  {
    "ASN": 24203,
    "Carrier": "XL",
    "Country": "id"
  },
  {
    "ASN": 45727,
    "Carrier": "XL",
    "Country": "id"
  },
  {
    "ASN": 5466,
    "Carrier": "eir Mobile",
    "Country": "ie"
  },
  {
    "ASN": 15751,
    "Carrier": "Meteor",
    "Country": "ie"
  },
  {
    "ASN": 34218,
    "Carrier": "Three",
    "Country": "ie"
  },
  {
    "ASN": 13280,
    "Carrier": "Three",
    "Country": "ie"
  },
  {
    "ASN": 15502,
    "Carrier": "Vodafone",
    "Country": "ie"
  },
  {
    "ASN": 55831,
    "Carrier": "Aircel",
    "Country": "in"
  },
  {
    "ASN": 45514,
    "Carrier": "Airtel",
    "Country": "in"
  },
  {
    "ASN": 24560,
    "Carrier": "Airtel",
    "Country": "in"
  },
  {
    "ASN": 45609,
    "Carrier": "Airtel",
    "Country": "in"
  },
  {
    "ASN": 9498,
    "Carrier": "Airtel",
    "Country": "in"
  },
  {
    "ASN": 9829,
    "Carrier": "BSNL",
    "Country": "in"
  },
  {
    "ASN": 45271,
    "Carrier": "Idea",
    "Country": "in"
  },
  {
    "ASN": 55644,
    "Carrier": "Idea",
    "Country": "in"
  },
  {
    "ASN": 18101,
    "Carrier": "Reliance",
    "Country": "in"
  },
  {
    "ASN": 55836,
    "Carrier": "Reliance",
    "Country": "in"
  },
  {
    "ASN": 4755,
    "Carrier": "Tata",
    "Country": "in"
  },
  {
    "ASN": 10199,
    "Carrier": "Tata",
    "Country": "in"
  },
  {
    "ASN": 45820,
    "Carrier": "Tata",
    "Country": "in"
  },
  {
    "ASN": 55441,
    "Carrier": "Tata",
    "Country": "in"
  },
  {
    "ASN": 3356,
    "Carrier": "Telenor",
    "Country": "in"
  },
  {
    "ASN": 23870,
    "Carrier": "Telenor",
    "Country": "in"
  },
  {
    "ASN": 21837,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 54994,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 1273,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 39832,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 17439,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 61317,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 38266,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 55410,
    "Carrier": "Vodafone",
    "Country": "in"
  },
  {
    "ASN": 1684,
    "Carrier": "Asiacell",
    "Country": "iq"
  },
  {
    "ASN": 51684,
    "Carrier": "Asiacell",
    "Country": "iq"
  },
  {
    "ASN": 59588,
    "Carrier": "Zain",
    "Country": "iq"
  },
  {
    "ASN": 44244,
    "Carrier": "Irancell",
    "Country": "ir"
  },
  {
    "ASN": 197207,
    "Carrier": "MCI",
    "Country": "ir"
  },
  {
    "ASN": 56746,
    "Carrier": "MTCE",
    "Country": "ir"
  },
  {
    "ASN": 57218,
    "Carrier": "Rightel",
    "Country": "ir"
  },
  {
    "ASN": 16232,
    "Carrier": "TIM",
    "Country": "it"
  },
  {
    "ASN": 24608,
    "Carrier": "Tre",
    "Country": "it"
  },
  {
    "ASN": 12663,
    "Carrier": "Vodafone",
    "Country": "it"
  },
  {
    "ASN": 30722,
    "Carrier": "Vodafone",
    "Country": "it"
  },
  {
    "ASN": 1267,
    "Carrier": "Wind",
    "Country": "it"
  },
  {
    "ASN": 33576,
    "Carrier": "Digicel",
    "Country": "jm"
  },
  {
    "ASN": 48832,
    "Carrier": "Zain",
    "Country": "jo"
  },
  {
    "ASN": 9605,
    "Carrier": "NTT Docomo",
    "Country": "jp"
  },
  {
    "ASN": 36926,
    "Carrier": "Airtel",
    "Country": "ke"
  },
  {
    "ASN": 12455,
    "Carrier": "Orange",
    "Country": "ke"
  },
  {
    "ASN": 33771,
    "Carrier": "Safaricom",
    "Country": "ke"
  },
  {
    "ASN": 9158,
    "Carrier": "KT",
    "Country": "kr"
  },
  {
    "ASN": 17853,
    "Carrier": "LG Uplus",
    "Country": "kr"
  },
  {
    "ASN": 3786,
    "Carrier": "LG Uplus",
    "Country": "kr"
  },
  {
    "ASN": 9644,
    "Carrier": "SK Telecom",
    "Country": "kr"
  },
  {
    "ASN": 38666,
    "Carrier": "SK Telecom",
    "Country": "kr"
  },
  {
    "ASN": 29357,
    "Carrier": "Ooredoo",
    "Country": "kw"
  },
  {
    "ASN": 47589,
    "Carrier": "VIVA",
    "Country": "kw"
  },
  {
    "ASN": 42961,
    "Carrier": "Zain",
    "Country": "kw"
  },
  {
    "ASN": 29357,
    "Carrier": "Wataniya",
    "Country": "kw"
  },
  {
    "ASN": 29357,
    "Carrier": "Wataniya",
    "Country": "kw"
  },
  {
    "ASN": 132045,
    "Carrier": "Airtel",
    "Country": "lk"
  },
  {
    "ASN": 45517,
    "Carrier": "Airtel",
    "Country": "lk"
  },
  {
    "ASN": 18001,
    "Carrier": "Dialog",
    "Country": "lk"
  },
  {
    "ASN": 17770,
    "Carrier": "Dialog",
    "Country": "lk"
  },
  {
    "ASN": 17470,
    "Carrier": "Etisalat",
    "Country": "lk"
  },
  {
    "ASN": 132447,
    "Carrier": "Hutch",
    "Country": "lk"
  },
  {
    "ASN": 45356,
    "Carrier": "Mobitel",
    "Country": "lk"
  },
  {
    "ASN": 36884,
    "Carrier": "Inwi",
    "Country": "ma"
  },
  {
    "ASN": 43494,
    "Carrier": "Vip",
    "Country": "mk"
  },
  {
    "ASN": 12810,
    "Carrier": "Vip",
    "Country": "mk"
  },
  {
    "ASN": 132167,
    "Carrier": "Ooredoo",
    "Country": "mm"
  },
  {
    "ASN": 17072,
    "Carrier": "Iusacell",
    "Country": "mx"
  },
  {
    "ASN": 7438,
    "Carrier": "Movistar",
    "Country": "mx"
  },
  {
    "ASN": 28469,
    "Carrier": "Nextel",
    "Country": "mx"
  },
  {
    "ASN": 28403,
    "Carrier": "Telcel",
    "Country": "mx"
  },
  {
    "ASN": 10030,
    "Carrier": "Celcom",
    "Country": "my"
  },
  {
    "ASN": 4788,
    "Carrier": "Celcom",
    "Country": "my"
  },
  {
    "ASN": 4818,
    "Carrier": "Digi",
    "Country": "my"
  },
  {
    "ASN": 9534,
    "Carrier": "Maxis",
    "Country": "my"
  },
  {
    "ASN": 38466,
    "Carrier": "U Mobile",
    "Country": "my"
  },
  {
    "ASN": 45960,
    "Carrier": "Yes",
    "Country": "my"
  },
  {
    "ASN": 3269,
    "Carrier": "Yes",
    "Country": "my"
  },
  {
    "ASN": 21271,
    "Carrier": "Norfolk Telecom",
    "Country": "nf"
  },
  {
    "ASN": 36873,
    "Carrier": "Airtel",
    "Country": "ng"
  },
  {
    "ASN": 37076,
    "Carrier": "Etisalat",
    "Country": "ng"
  },
  {
    "ASN": 37148,
    "Carrier": "Glo",
    "Country": "ng"
  },
  {
    "ASN": 29465,
    "Carrier": "MTN",
    "Country": "ng"
  },
  {
    "ASN": 31615,
    "Carrier": "T-mobile",
    "Country": "nl"
  },
  {
    "ASN": 15480,
    "Carrier": "Vodafone",
    "Country": "nl"
  },
  {
    "ASN": 33915,
    "Carrier": "Vodafone",
    "Country": "nl"
  },
  {
    "ASN": 8529,
    "Carrier": "Omantel",
    "Country": "om"
  },
  {
    "ASN": 28885,
    "Carrier": "Omantel",
    "Country": "om"
  },
  {
    "ASN": 50010,
    "Carrier": "Ooredoo",
    "Country": "om"
  },
  {
    "ASN": 11556,
    "Carrier": "Cable & Wireless PanamÃƒÂ¡",
    "Country": "pa"
  },
  {
    "ASN": 262227,
    "Carrier": "Claro",
    "Country": "pa"
  },
  {
    "ASN": 28005,
    "Carrier": "Digicel",
    "Country": "pa"
  },
  {
    "ASN": 262210,
    "Carrier": "Bitel",
    "Country": "pe"
  },
  {
    "ASN": 12252,
    "Carrier": "Claro",
    "Country": "pe"
  },
  {
    "ASN": 27702,
    "Carrier": "Claro",
    "Country": "pe"
  },
  {
    "ASN": 21575,
    "Carrier": "Entel",
    "Country": "pe"
  },
  {
    "ASN": 264684,
    "Carrier": "Movistar",
    "Country": "pe"
  },
  {
    "ASN": 6147,
    "Carrier": "Movistar",
    "Country": "pe"
  },
  {
    "ASN": 4775,
    "Carrier": "Globe",
    "Country": "ph"
  },
  {
    "ASN": 132199,
    "Carrier": "Globe",
    "Country": "ph"
  },
  {
    "ASN": 38196,
    "Carrier": "Jazz",
    "Country": "pk"
  },
  {
    "ASN": 45669,
    "Carrier": "Jazz",
    "Country": "pk"
  },
  {
    "ASN": 45127,
    "Carrier": "Jazz",
    "Country": "pk"
  },
  {
    "ASN": 24499,
    "Carrier": "Telenor",
    "Country": "pk"
  },
  {
    "ASN": 17557,
    "Carrier": "Ufone",
    "Country": "pk"
  },
  {
    "ASN": 56167,
    "Carrier": "Ufone",
    "Country": "pk"
  },
  {
    "ASN": 59257,
    "Carrier": "Zong",
    "Country": "pk"
  },
  {
    "ASN": 5607,
    "Carrier": "Cyfrowy Polsat",
    "Country": "pl"
  },
  {
    "ASN": 5617,
    "Carrier": "Orange",
    "Country": "pl"
  },
  {
    "ASN": 39603,
    "Carrier": "Play",
    "Country": "pl"
  },
  {
    "ASN": 8374,
    "Carrier": "Plus",
    "Country": "pl"
  },
  {
    "ASN": 21243,
    "Carrier": "Plus",
    "Country": "pl"
  },
  {
    "ASN": 12912,
    "Carrier": "T-Mobile",
    "Country": "pl"
  },
  {
    "ASN": 12975,
    "Carrier": "Jawwal",
    "Country": "ps"
  },
  {
    "ASN": 42863,
    "Carrier": "MEO",
    "Country": "pt"
  },
  {
    "ASN": 2860,
    "Carrier": "NOS",
    "Country": "pt"
  },
  {
    "ASN": 12353,
    "Carrier": "Vodafone",
    "Country": "pt"
  },
  {
    "ASN": 11664,
    "Carrier": "Claro",
    "Country": "py"
  },
  {
    "ASN": 27895,
    "Carrier": "Personal",
    "Country": "py"
  },
  {
    "ASN": 23201,
    "Carrier": "Tigo",
    "Country": "py"
  },
  {
    "ASN": 42298,
    "Carrier": "Ooredoo",
    "Country": "qa"
  },
  {
    "ASN": 8781,
    "Carrier": "Ooredoo",
    "Country": "qa"
  },
  {
    "ASN": 48728,
    "Carrier": "Vodafone",
    "Country": "qa"
  },
  {
    "ASN": 8400,
    "Carrier": "MTS/Telekom Srbija",
    "Country": "rs"
  },
  {
    "ASN": 15958,
    "Carrier": "Telenor",
    "Country": "rs"
  },
  {
    "ASN": 44143,
    "Carrier": "Vip",
    "Country": "rs"
  },
  {
    "ASN": 8402,
    "Carrier": "Beeline",
    "Country": "ru"
  },
  {
    "ASN": 34644,
    "Carrier": "Beeline",
    "Country": "ru"
  },
  {
    "ASN": 16345,
    "Carrier": "Beeline",
    "Country": "ru"
  },
  {
    "ASN": 25159,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 31208,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 31163,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 47395,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 31224,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 31205,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 31133,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 51737,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 31213,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 31195,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 8586,
    "Carrier": "MegaFon",
    "Country": "ru"
  },
  {
    "ASN": 29209,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 28884,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 35473,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 25513,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 29497,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 8359,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 39811,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 13174,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 16256,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 21103,
    "Carrier": "MTS",
    "Country": "ru"
  },
  {
    "ASN": 42437,
    "Carrier": "Tele2",
    "Country": "ru"
  },
  {
    "ASN": 48190,
    "Carrier": "Tele2",
    "Country": "ru"
  },
  {
    "ASN": 48092,
    "Carrier": "Tele2",
    "Country": "ru"
  },
  {
    "ASN": 15378,
    "Carrier": "Tele2",
    "Country": "ru"
  },
  {
    "ASN": 41330,
    "Carrier": "Tele2",
    "Country": "ru"
  },
  {
    "ASN": 12958,
    "Carrier": "Tele2",
    "Country": "ru"
  },
  {
    "ASN": 43766,
    "Carrier": "Zain",
    "Country": "sa"
  },
  {
    "ASN": 36998,
    "Carrier": "Zain",
    "Country": "sd"
  },
  {
    "ASN": 1257,
    "Carrier": "Tele2",
    "Country": "se"
  },
  {
    "ASN": 25169,
    "Carrier": "Telenor",
    "Country": "se"
  },
  {
    "ASN": 3301,
    "Carrier": "Telia",
    "Country": "se"
  },
  {
    "ASN": 44034,
    "Carrier": "Tre",
    "Country": "se"
  },
  {
    "ASN": 4773,
    "Carrier": "M1",
    "Country": "sg"
  },
  {
    "ASN": 17547,
    "Carrier": "M1",
    "Country": "sg"
  },
  {
    "ASN": 45143,
    "Carrier": "SingTel",
    "Country": "sg"
  },
  {
    "ASN": 9874,
    "Carrier": "StarHub",
    "Country": "sg"
  },
  {
    "ASN": 5610,
    "Carrier": "O2",
    "Country": "sk"
  },
  {
    "ASN": 15962,
    "Carrier": "Orange",
    "Country": "sk"
  },
  {
    "ASN": 6855,
    "Carrier": "Slovak Telekom",
    "Country": "sk"
  },
  {
    "ASN": 16160,
    "Carrier": "Viphone",
    "Country": "sk"
  },
  {
    "ASN": 27903,
    "Carrier": "Digicel",
    "Country": "sv"
  },
  {
    "ASN": 27773,
    "Carrier": "Tigo",
    "Country": "sv"
  },
  {
    "ASN": 133848,
    "Carrier": "AIS",
    "Country": "th"
  },
  {
    "ASN": 131445,
    "Carrier": "AIS",
    "Country": "th"
  },
  {
    "ASN": 132032,
    "Carrier": "DTAC",
    "Country": "th"
  },
  {
    "ASN": 24378,
    "Carrier": "DTAC",
    "Country": "th"
  },
  {
    "ASN": 133543,
    "Carrier": "DTAC",
    "Country": "th"
  },
  {
    "ASN": 24491,
    "Carrier": "My",
    "Country": "th"
  },
  {
    "ASN": 131293,
    "Carrier": "TOT",
    "Country": "th"
  },
  {
    "ASN": 9737,
    "Carrier": "TOT",
    "Country": "th"
  },
  {
    "ASN": 23969,
    "Carrier": "TOT",
    "Country": "th"
  },
  {
    "ASN": 17552,
    "Carrier": "True Move H",
    "Country": "th"
  },
  {
    "ASN": 7470,
    "Carrier": "True Move H",
    "Country": "th"
  },
  {
    "ASN": 9287,
    "Carrier": "True Move H",
    "Country": "th"
  },
  {
    "ASN": 37693,
    "Carrier": "Ooredoo",
    "Country": "tn"
  },
  {
    "ASN": 37492,
    "Carrier": "Orange",
    "Country": "tn"
  },
  {
    "ASN": 2609,
    "Carrier": "Tunisie Telecom",
    "Country": "tn"
  },
  {
    "ASN": 9121,
    "Carrier": "Turk Telekom",
    "Country": "tr"
  },
  {
    "ASN": 20978,
    "Carrier": "Turk Telekom",
    "Country": "tr"
  },
  {
    "ASN": 6755,
    "Carrier": "Turk Telekom",
    "Country": "tr"
  },
  {
    "ASN": 47883,
    "Carrier": "Turkcell",
    "Country": "tr"
  },
  {
    "ASN": 16135,
    "Carrier": "Turkcell",
    "Country": "tr"
  },
  {
    "ASN": 15897,
    "Carrier": "Vodafone",
    "Country": "tr"
  },
  {
    "ASN": 62211,
    "Carrier": "Vodafone",
    "Country": "tr"
  },
  {
    "ASN": 31654,
    "Carrier": "Vodafone",
    "Country": "tr"
  },
  {
    "ASN": 8386,
    "Carrier": "Vodafone",
    "Country": "tr"
  },
  {
    "ASN": 15924,
    "Carrier": "Vodafone",
    "Country": "tr"
  },
  {
    "ASN": 37075,
    "Carrier": "Airtel",
    "Country": "ug"
  },
  {
    "ASN": 20294,
    "Carrier": "MTN",
    "Country": "ug"
  },
  {
    "ASN": 7018,
    "Carrier": "AT&T 1",
    "Country": "us"
  },
  {
    "ASN": 20057,
    "Carrier": "AT&T 2",
    "Country": "us"
  },
  {
    "ASN": 22394,
    "Carrier": "CELLCO VZW",
    "Country": "us"
  },
  {
    "ASN": 209,
    "Carrier": "CENTURYLINK",
    "Country": "us"
  },
  {
    "ASN": 11427,
    "Carrier": "CHARTER 1",
    "Country": "us"
  },
  {
    "ASN": 20001,
    "Carrier": "CHARTER 2",
    "Country": "us"
  },
  {
    "ASN": 20115,
    "Carrier": "CHARTER 3",
    "Country": "us"
  },
  {
    "ASN": 7922,
    "Carrier": "COMCAST",
    "Country": "us"
  },
  {
    "ASN": 22773,
    "Carrier": "COX",
    "Country": "us"
  },
  {
    "ASN": 6128,
    "Carrier": "OPTIMUM",
    "Country": "us"
  },
  {
    "ASN": 10507,
    "Carrier": "SPRINT",
    "Country": "us"
  },
  {
    "ASN": 21928,
    "Carrier": "T-MOBILE",
    "Country": "us"
  },
  {
    "ASN": 701,
    "Carrier": "VERIZON",
    "Country": "us"
  },
  {
    "ASN": 12083,
    "Carrier": "WINDSTREAM",
    "Country": "us"
  },
  {
    "ASN": 174,
    "Carrier": "COOKIE GEN ONLY",
    "Country": "us"
  },
  {
    "ASN": 19422,
    "Carrier": "Movistar",
    "Country": "uy"
  },
  {
    "ASN": 45899,
    "Carrier": "MobiFone",
    "Country": "vn"
  },
  {
    "ASN": 6739,
    "Carrier": "MobiFone",
    "Country": "vn"
  },
  {
    "ASN": 131429,
    "Carrier": "MobiFone",
    "Country": "vn"
  },
  {
    "ASN": 45903,
    "Carrier": "MobiFone",
    "Country": "vn"
  },
  {
    "ASN": 37168,
    "Carrier": "Cell C",
    "Country": "za"
  },
  {
    "ASN": 2905,
    "Carrier": "MTN",
    "Country": "za"
  },
  {
    "ASN": 37457,
    "Carrier": "MTN",
    "Country": "za"
  },
  {
    "ASN": 29975,
    "Carrier": "Vodacom",
    "Country": "za"
  },
  {
    "ASN": 36994,
    "Carrier": "Vodacom",
    "Country": "za"
  }
];

export {
  resiPremiumASN
};