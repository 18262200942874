import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap';
import {
  PageLoader,
  showToastAlert
} from '../Services/Utility';


const ResetPassword = () => {
  const { firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const [values, setValues] = useState({
    email: ""
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user) {
          history.push('/');
          return;
        } else {
          setLoading(false);
        }
      });
    })();
    document.body.classList.add('login-page');
  }, [history, firebase.auth]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('login-page');
    }
  }, []);

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    firebase.doPasswordReset(values.email)
      .then(() => {
        showToastAlert('Success', 'Password reset link has been sent to your email address!');
        history.push('/login');
      })
      .catch(error => {
        showToastAlert('Error', error.message);
      });
  }

  return (
    <>
      {loading && <PageLoader />}
      <Container className="login-container wow animate__animated animate__fadeInDown" data-wow-duration="2s">
        <Row className="login-box justify-content-center align-items-center">
          <Col xs="12" className="login-header">
            <Link to='/'>
              <img
                alt="Logo"
                className="login-img"
                src={require("assets/img/logo.png")}
              />
            </Link>
            <h1 className="login-title">Reset Password</h1>
          </Col>

          <Col xs="12" className="login-body">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="email" className="text-secondary">Email</Label>
                <Input type="email" name="email" value={values.email} className="input-theme" placeholder="example@gmail.com" onChange={handleChange} required />
              </FormGroup>
              <Button
                outline
                className="btn-theme btn-login w-100 mt-3"
                color="secondary"
                size="md"
              >
                Send Password Reset Link
              </Button>
            </Form>
            <FormText color="muted text-center mt-3">
              Don’t have an account?
              <Link to="/register" className="ml-1 login-link">
                Register
              </Link>
            </FormText>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ResetPassword;